import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from "@material-ui/core/Drawer";
import { useMediaQuery } from '@material-ui/core';
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        height: (props) => props.height,
        width: (props) => props.width,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            width: "100vw",
        },
    },
    bodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    headerCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ececec",
        padding: "0px 15px",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "18px",
            fontWeight: "600"
        }
    },
    contentCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflow: "hidden",
    }
}));

export default function NormalDrawer({
    openDrawer, setOpenDrawer, content, anchor,
    height, width, title,
}) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles({
        height: height ? height : anchor === "right" || anchor === "left" ? "100vh" : "50vh",
        width: width ? width : anchor === "right" || anchor === "left" ? isSmall ? "95vw" : "35vw" : "100vw"
    });

    return (
        <Drawer
            variant="temporary"
            anchor={anchor}
            open={openDrawer}
            onClose={() => { setOpenDrawer(false) }}
            classes={{
                paper: classes.drawerPaper
            }}
            ModalProps={{
                keepMounted: true
            }}
        >
            <div className={classes.bodyCont} >
                <div className={classes.headerCont} >
                    <h3>{title}</h3>
                    <IconButton onClick={() => { setOpenDrawer(false) }} ><CloseIcon /></IconButton>
                </div>
                <div className={classes.contentCont} >
                    {openDrawer ? content : null}
                </div>
            </div>
        </Drawer>
    );
}