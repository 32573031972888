import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  TextField as MUITextField,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { mainRoles } from "../../helpers/contactHelper";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AllCommonField from "../styled/CommonComponents/AllCommonFields";
import {
  BasicUserData,
  BasicOrgData,
  BasicGroupData,
  prospectFields,
  customerFields,
  employeeFields,
  contractorFields,
  vendorFields,
  investorFields,
  lenderFields,
  tenantFields,
  workerFields,
  ownerFields,
  brokerFields,
} from "../../helpers/roleRelatedFields";
import {
  createPalApiCall,
  updatePalApiCall,
  getAllAdminProjectsAndOrgs,
} from "../styled/CommonComponents/api.call";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import FormBox from "../styled/generic/FormBox";
import SpaceBetween from "../styled/generic/SpaceBetween";
import { Add, CurrencyRupee } from "@mui/icons-material";
import PropertyTypeChooser from "../listing/editlisting/components/PropertyTypeChooser";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";
import CheckGroup from "../styled/generic/CheckGroup";
import CountPicker from "../styled/generic/CountPicker";
import CheckGroupGrid from "../styled/generic/CheckGroupGrid";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from "../styled/generic/Select";
import CheckGroupWithObj from "../styled/generic/CheckGroupWithObj";
import useLocationForm from "../styled/hooks/useLocationForm";
import TextField from "../styled/generic/TextField";
import Button from "../styled/generic/Button";
import ListingPicker from "../styled/generic/ListingPicker";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import HorizBox from "../styled/generic/HorizBox";
import { Delete } from "@material-ui/icons";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const REQUIREMENT_TYPE_OPTIONS = [
  "Rent",
  "Sell",
  "Roommate",
  "Job",
  "Investment",
  "Tender",
];

const FURNISHING_OPTIONS = [
  "Fully Furnished",
  "Semi Furnished",
  "Un Furnished",
];

const FLOOR_OPTIONS = [
  { title: "Ground Floor", value: 0 },
  { title: "1st Floor", value: 1 },
  { title: "2nd Floor", value: 2 },
  { title: "3rd Floor", value: 3 },
  { title: "4th Floor", value: 4 },
  { title: "5th or More", value: -1 },
];

const AMENITY_OPTIONS = [
  "Power Backup",
  "Wifi",
  "Gym",
  "Air conditioner",
  "Swimming Pool",
  "Gas Station",
  "Park",
  "Fire Safety",
  "Elevator",
  "Play Area",
  "Park",
];

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 55px)",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCont: {
    width: "52%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "15px 15px 20px",
    borderRight: "1px solid #ececec",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  directoryMainCont: {
    width: "48%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "15px 15px 0px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  btnCont: {
    width: "100%",
    height: "50px",
    padding: "0px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #ececec",
  },
  roleAndTypeCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  allfieldsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  divAndDeleteCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  profileTypeRoleCont: {
    width: "calc(100% - 45px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageNameCont: {
    width: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameUsernameCont: {
    width: "calc(100% - 35px)",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "300",
    },
  },
  directoryHeadCont: {
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderBottom: "2px solid #ececec",
    "& h3": {
      fontSize: "18px",
      fontWeight: "600",
    },
  },
  allSelectedProfileCont: {
    width: "100%",
    height: "calc(100% - 47px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const CreatePalDrawer = ({
  openPal,
  setOpenPal,
  title,
  financialRelationType,
  mainProfileWitParent,
  profileIdArr,
  selectEntityFunction,
  setNewPalItem,
  orgtype,
  pal, // Used to see if it is edit or create
}) => {
  console.log("Financial Relation:", financialRelationType);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const [listing, setListing] = useState();

  const [type, setType] = useState(orgtype || "Organization");
  const [roleType, setRoleType] = useState(financialRelationType || "Vendor");
  const [stateChange, setStateChange] = useState(false);
  const [basicDataWithField, setBasicDataWithField] = useState({});
  const [roleDataWithField, setRoleDataWithField] = useState({});
  const [selectedProfileForRelation, setSelectedProfileForRelation] = useState([
    {},
  ]);
  const [relationScopTypes, setRelationScopTypes] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [warningBool, setWarningBool] = useState(false);
  const [requirementTitle, setRequirementTitle] = useState(""); // Requirement title
  const [selectedRequirementType, setSelectedRequirementType] = useState(
    REQUIREMENT_TYPE_OPTIONS[0]
  );
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [minArea, setMinArea] = useState();
  const [maxArea, setMaxArea] = useState();
  const [propertyType, setPropertyType] = useState();
  const [propertyAreaUnit, setPropertyAreaUnit] = useState();
  const [furnishings, setFurnishings] = useState([]);
  const [bedroomCount, setBedroomCount] = useState();
  const [bathroomCount, setBathroomCount] = useState();
  const [balconyCount, setBalconyCount] = useState();
  const [floors, setFloors] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [defaultListingId, setDefaultListingId] = useState();
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [clients, setClients] = useState([]);
  const [source, setSource] = useState("Facebook");
  const [selectedListingData, setSelectedListingData] = useState();
  const { locationForm, locations, setLocations } = useLocationForm("small");
  const { requirementId } = useParams();

  console.log("Project", projectOptions);

  useEffect(() => {
    if (profileIdArr && profileIdArr.length > 0) {
      getAllAdminProjectsAndOrgs({ givenProfiles: profileIdArr })
        .then((data) => {
          const organizationArr = data?.organizations || [];
          const projectArr = data?.projects || [];
          setOrganizationOptions(organizationArr);
          setProjectOptions(projectArr);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mainProfileWitParent]);

  const changeSelectedProfileForRelationValue = () => {
    let obj = {
      type:
        mainProfileWitParent?.parentModelName === "User"
          ? "Myself"
          : mainProfileWitParent?.parentModelName,
      profile: mainProfileWitParent,
      role: financialRelationType,
    };
    setSelectedProfileForRelation([obj]);
  };

  useEffect(() => {
    changeSelectedProfileForRelationValue();
  }, [
    mainProfileWitParent?.parentModelName,
    mainProfileWitParent,
    financialRelationType,
  ]);

  const changeRelationScopTypesValue = () => {
    let isMyself = false;
    selectedProfileForRelation.map((c) => {
      if (c.type === "Myself") {
        isMyself = true;
      }
    });

    if (isMyself) {
      setRelationScopTypes(["Project", "Organization"]);
    } else {
      setRelationScopTypes(["Myself", "Project", "Organization"]);
    }
  };

  useEffect(() => {
    changeRelationScopTypesValue();
  }, [selectedProfileForRelation, stateChange]);

  useEffect(() => {
    setStateChange(!stateChange);
    switch (roleType) {
      case "Prospect":
        setRoleDataWithField(prospectFields);
        break;
      case "Owner":
        setRoleDataWithField(ownerFields);
        break;
      case "Customer":
        setRoleDataWithField(customerFields);
        break;
      case "Employee":
        setRoleDataWithField(employeeFields);
        break;
      case "Contractor":
        setRoleDataWithField(contractorFields);
        break;
      case "Vendor":
        setRoleDataWithField(vendorFields);
        break;
      case "Investor":
        setRoleDataWithField(investorFields);
        break;
      case "Lender":
        setRoleDataWithField(lenderFields);
        break;
      case "Tenant":
        setRoleDataWithField(tenantFields);
        break;
      case "Worker":
        setRoleDataWithField(workerFields);
        break;
      case "Broker":
        setRoleDataWithField(brokerFields);
        break;
    }
    setStateChange(!stateChange);
  }, [roleType]);

  useEffect(() => {
    setStateChange(!stateChange);
    switch (type) {
      case "User":
        setBasicDataWithField(BasicUserData);
        break;
      case "Organization":
        setBasicDataWithField(BasicOrgData);
        break;
      case "Group":
        setBasicDataWithField(BasicGroupData);
        break;
    }
    setStateChange(!stateChange);
  }, [type]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setWarningBool(false);
  };

  const createPal = async (e) => {
    e.preventDefault();
    let basicDataObj = {};
    let contactDataObj = {};
    let basicData = {};
    let relationObject = {};
    let isAnyIssue = false;
    let msg = "";

    contactDataObj.source = source;

    Object.keys(basicDataWithField).map((field) => {
      basicDataObj[field] = basicDataWithField[field]?.value;
    });

    Object.keys(roleDataWithField).map((field) => {
      contactDataObj[field] = roleDataWithField[field]?.value;
    });
    //basicDataObj.address = contactDataObj;

    let requirementData = {};

    if (roleType === "Prospect") {
      requirementData = {
        title: requirementTitle,
        requirementType: selectedRequirementType,
        isPrivate,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        areaUnit: propertyAreaUnit,
        propertyType,
        furnishings,
        bedRoomCount: isNaN(bedroomCount) ? null : bedroomCount,
        bathRoomCount: isNaN(bathroomCount) ? null : bathroomCount,
        balconyCount: isNaN(balconyCount) ? null : balconyCount,
        amenities,
        floors: floors.map((item) => item.value),
        locations: locations,
        owner: user.profile,
      };
    }

    if (
      type === "User" &&
      (basicDataObj?.firstName == null ||
        basicDataObj?.firstName == "" ||
        basicDataObj?.firstName.length < 2)
    ) {
      isAnyIssue = true;
      msg = "First name is required ";
    } else if (
      (type === "Organization" || type === "Group") &&
      (basicDataObj?.displayName == null ||
        basicDataObj?.displayName == "" ||
        basicDataObj?.displayName.length < 3)
    ) {
      isAnyIssue = true;
      msg = "Name is required ";
    }

    if (
      selectedProfileForRelation === null ||
      selectedProfileForRelation.length === 0
    ) {
      isAnyIssue = true;
      if (msg.length > 5) {
        msg = msg + "and have to selected minimum one entity for directory";
      } else {
        msg = msg + "Have to selected minimum one entity for directory";
      }
    }

    if (isAnyIssue) {
      setWarningMsg(msg);
      setWarningBool(isAnyIssue);
      return;
    }

    setLoading(true);
    if (type === "User") {
      basicData = {
        email: (basicDataObj?.mailId || "").toLowerCase(),
        displayName: `${basicDataObj?.firstName} ${basicDataObj?.middleName} ${basicDataObj?.lastName}`,
        address: {
          street: basicDataObj?.street,
          city: basicDataObj?.city,
          postalCode: basicDataObj?.postalCode,
          state: basicDataObj?.state,
          country: basicDataObj?.country,
        },
        phone: basicDataObj?.phone,
        type: type,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id,
        costCode: basicDataObj?.costCode,
        hourlyRate: basicDataObj?.hourlyRate,
        pan: basicDataObj?.pan,
        requirementData: requirementData,
        listing: listing,
      };
    } else if (type === "Organization") {
      basicData = {
        type: type,
        address: {
          street: basicDataObj?.street,
          city: basicDataObj?.city,
          postalCode: basicDataObj?.postalCode,
          state: basicDataObj?.state,
          country: basicDataObj?.country,
        },
        displayName: basicDataObj?.displayName,
        email: (basicDataObj?.mailId || "").toLowerCase(),
        phone: basicDataObj?.phone,
        zip: basicDataObj?.postalCode || "",
        website: basicDataObj?.website,
        pan: basicDataObj?.pan,
        taxId: basicDataObj?.taxId,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id,
        costCode: basicDataObj?.costCode,
        hourlyRate: basicDataObj?.hourlyRate,
        requirementData: requirementData,
      };
    } else if (type === "Group") {
      basicData = {
        type: type,
        displayName: basicDataObj?.displayName,
        email: (basicDataObj?.mailId || "").toLowerCase(),
        phone: basicDataObj?.phone,
        costCode: basicDataObj?.costCode,
        hourlyRate: basicDataObj?.hourlyRate,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id,
        requirementData: requirementData,
      };
    }

    let locSelectedProfilesSet = new Set([]);
    selectedProfileForRelation.map((profileType) => {
      locSelectedProfilesSet.add(profileType?.profile?._id);
    });

    const requestObj = {
      contactData: contactDataObj,
      basicData: basicData,
      locSelectedProfiles: [...locSelectedProfilesSet],
      currentEntityProfileId: mainProfileWitParent?._id,
    };

    await createPalApiCall(requestObj).then((data) => {
      if (data) {
        selectEntityFunction(data);
        if (setNewPalItem) {
          setNewPalItem(data);
        }
        setOpenPal(false);
        setType("User");
        setRoleType(roleType);
        setRoleDataWithField(customerFields);
        setBasicDataWithField(BasicUserData);
        setWarningMsg("");
        setWarningBool(false);
        changeSelectedProfileForRelationValue();
        changeRelationScopTypesValue();
      }
      setLoading(false);
      setWarningMsg("All fields are required");
      setWarningBool(true);
    });
  };

  const updatePal = async (e) => {
    e.preventDefault();
    let basicDataObj = {};
    let contactDataObj = {};
    let basicData = {};
    let relationObject = {};
    let isAnyIssue = false;
    let msg = "";

    Object.keys(basicDataWithField).map((field) => {
      basicDataObj[field] = basicDataWithField[field]?.value;
    });
    Object.keys(roleDataWithField).map((field) => {
      contactDataObj[field] = roleDataWithField[field]?.value;
    });

    let requirementData = {};

    if (roleType === "Prospect") {
      requirementData = {
        title: requirementTitle,
        requirementType: selectedRequirementType,
        isPrivate,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        areaUnit: propertyAreaUnit,
        propertyType,
        furnishings,
        bedRoomCount: isNaN(bedroomCount) ? null : bedroomCount,
        bathRoomCount: isNaN(bathroomCount) ? null : bathroomCount,
        balconyCount: isNaN(balconyCount) ? null : balconyCount,
        amenities,
        floors: floors.map((item) => item.value),
        locations: locations,
        owner: user.profile,
      };
    }

    if (
      type === "User" &&
      (basicDataObj?.firstName == null ||
        basicDataObj?.firstName == "" ||
        basicDataObj?.firstName.length < 2)
    ) {
      isAnyIssue = true;
      msg = "First name is required ";
    } else if (
      (type === "Organization" || type === "Group") &&
      (basicDataObj?.displayName == null ||
        basicDataObj?.displayName == "" ||
        basicDataObj?.displayName.length < 3)
    ) {
      isAnyIssue = true;
      msg = "Name is required ";
    }

    if (
      selectedProfileForRelation === null ||
      selectedProfileForRelation.length === 0
    ) {
      isAnyIssue = true;
      if (msg.length > 5) {
        msg = msg + "and have to selected minimum one entity for directory";
      } else {
        msg = msg + "Have to selected minimum one entity for directory";
      }
    }

    if (isAnyIssue) {
      setWarningMsg(msg);
      setWarningBool(isAnyIssue);
      return;
    }

    setLoading(true);
    if (type === "User") {
      basicData = {
        email: (basicDataObj?.mailId || "").toLowerCase(),
        displayName: `${basicDataObj?.firstName} ${basicDataObj?.middleName} ${basicDataObj?.lastName}`,
        address: {
          street: basicDataObj?.street,
          city: basicDataObj?.city,
          postalCode: basicDataObj?.postalCode,
          state: basicDataObj?.state,
          country: basicDataObj?.country,
        },
        phone: basicDataObj?.phone,
        type: type,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id,
        costCode: basicDataObj?.costCode,
        hourlyRate: basicDataObj?.hourlyRate,
        pan: basicDataObj?.pan,
        requirementData: requirementData,
        listing: listing,
        emails: emails,
        phoneNumbers: phoneNumbers,
        clients: clients,
      };
    } else if (type === "Organization") {
      basicData = {
        type: type,
        address: {
          street: basicDataObj?.street,
          city: basicDataObj?.city,
          postalCode: basicDataObj?.postalCode,
          state: basicDataObj?.state,
          country: basicDataObj?.country,
        },
        displayName: basicDataObj?.displayName,
        email: (basicDataObj?.mailId || "").toLowerCase(),
        phone: basicDataObj?.phone,
        zip: basicDataObj?.postalCode || "",
        website: basicDataObj?.website,
        pan: basicDataObj?.pan,
        taxId: basicDataObj?.taxId,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id,
        costCode: basicDataObj?.costCode,
        hourlyRate: basicDataObj?.hourlyRate,
        requirementData: requirementData,
        emails: emails,
        phoneNumbers: phoneNumbers,
        clients: clients,
      };
    } else if (type === "Group") {
      basicData = {
        type: type,
        displayName: basicDataObj?.displayName,
        email: (basicDataObj?.mailId || "").toLowerCase(),
        phone: basicDataObj?.phone,
        costCode: basicDataObj?.costCode,
        hourlyRate: basicDataObj?.hourlyRate,
        parent: mainProfileWitParent?.parent,
        parentModelName: mainProfileWitParent?.parentModelName,
        contactModelName: roleType,
        user: user._id,
        requirementData: requirementData,
        emails: emails,
        phoneNumbers: phoneNumbers,
        clients: clients,
      };
    }

    selectedProfileForRelation.map((profileType) => {
      const curProfileId = profileType?.profile?._id || null;
      if (curProfileId) {
        const rolesArr = relationObject[curProfileId] || [];
        rolesArr.push(profileType?.role);
        const rolesArrSet = new Set([...rolesArr]);
        relationObject[curProfileId] = [...rolesArrSet];
      }
    });

    const requestObj = {
      contactData: contactDataObj,
      basicData: basicData,
      relationObject,
      currentEntityProfileId: mainProfileWitParent?._id,
      finrelId: pal?._id,
    };

    await updatePalApiCall(requestObj)
      .then((data) => {
        selectEntityFunction(data);
        setNewPalItem(data);
        setLoading(false);
        setOpenPal(false);
        setType("User");
        setRoleType(roleType);
        setRoleDataWithField(customerFields);
        setBasicDataWithField(BasicUserData);
        setWarningMsg("");
        setWarningBool(false);
        changeSelectedProfileForRelationValue();
        changeRelationScopTypesValue();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (pal && pal?._id) {
      let listingData = pal?.listing;
      let requirementData = pal?.requirement;

      setDefaultListingId(listingData?._id);
      setRequirementTitle(requirementData?.title);
      setSelectedRequirementType(requirementData?.requirementType);
      setIsPrivate(requirementData?.isPrivate);
      setMinPrice(requirementData?.minPrice);
      setMaxPrice(requirementData?.maxPrice);
      setMinArea(requirementData?.minArea);
      setMaxArea(requirementData?.maxArea);
      setPropertyType(requirementData?.propertyType);
      setPropertyAreaUnit(requirementData?.areaUnit);
      setFurnishings(requirementData?.furnishings || []);
      setBedroomCount(requirementData?.bedRoomCount);
      setBathroomCount(requirementData?.bathRoomCount);
      setBalconyCount(requirementData?.balconyCount);
      setFloors(
        requirementData?.floors?.map((item) => {
          return { value: item };
        }) || []
      );

      setAmenities(
        requirementData?.amenities?.map((item) => {
          return { value: item };
        }) || []
      );

      setLocations(requirementData?.locations || []);

      setEmails(pal?.parent?.emails || []);
      setPhoneNumbers(pal?.parent?.phoneNumbers || []);
      setClients(pal?.parent?.clients || []);

      setRoleType(pal?.firstPartyContactModelName || "Customer");

      setSelectedListingData(pal?.listing);

      // Fill up other fields
      let parent = pal?.parent;
      let parentModelName = pal?.parentModelName;

      let basicData = {};
      let contactData = {};

      let displayName = parent?.displayName;
      let firstName = displayName.split(" ")[0];
      let middleName =
        displayName.split(" ")?.length > 2 ? displayName.split(" ")[1] : "";
      let lastName =
        displayName.split(" ")?.length > 2
          ? displayName.split(" ")[2]
          : displayName.split(" ")[1];

      setBasicDataWithField({
        firstName: {
          type: "textField",
          value: firstName,
          lable: "First Name",
          width: "33.33%",
        },
        middleName: {
          type: "textField",
          value: middleName,
          lable: "Middle Name",
          width: "33.33%",
        },
        lastName: {
          type: "textField",
          value: lastName,
          lable: "Last Name",
          width: "33.33%",
        },
        mailId: {
          type: "textField",
          value: parent?.email,
          lable: "Mail Id",
          width: "50%",
        },
        phone: {
          type: "textField",
          value: parent?.phone,
          lable: "Phone No.",
          width: "50%",
        },
      });
    }
  }, [pal]);

  const addClient = () => {
    setClients([...clients, { displayName: "", email: "", phone: "" }]);
  };

  return (
    <NormalDrawer
      openDrawer={openPal}
      setOpenDrawer={setOpenPal}
      anchor={"right"}
      title={title ? title : "Add New Pal"}
      width={"85vw"}
      content={
        <form
          className={classes.mainCont}
          onSubmit={(e) => {
            if (pal?._id) {
              updatePal(e);
            } else {
              createPal(e);
            }
          }}
        >
          <div className={classes.bodyCont}>
            <div className={classes.formCont}>
              {!pal?._id && (
                <div className={classes.roleAndTypeCont}>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={type}
                    onChange={(evt, val) => setType(val)}
                  >
                    <FormControlLabel
                      value="User"
                      control={<Radio color="primary" />}
                      label="User"
                    />
                    <FormControlLabel
                      value="Organization"
                      control={<Radio color="primary" />}
                      label="Organization"
                    />
                    <FormControlLabel
                      value="Group"
                      control={<Radio color="primary" />}
                      label="Group"
                    />
                  </RadioGroup>

                  <Autocomplete
                    size="small"
                    value={roleType}
                    options={mainRoles.filter(
                      (role) =>
                        role !== "Owner" &&
                        role !== "Admin" &&
                        role !== "Viewer" &&
                        role !== "Other"
                    )}
                    getOptionLabel={(option) => option || ""}
                    getOptionSelected={(option) => {
                      return option === roleType;
                    }}
                    style={{ width: "200px" }}
                    onChange={(event, value) => {
                      setRoleType(value);
                    }}
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        label={"Role"}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
              )}
              <div className={classes.allfieldsCont}>
                {/* basic Data */}

                {Object.keys(basicDataWithField).map((fieldData) => (
                  <AllCommonField
                    type={basicDataWithField[fieldData]?.type}
                    value={basicDataWithField[fieldData]?.value}
                    lable={basicDataWithField[fieldData]?.lable}
                    row={basicDataWithField[fieldData]?.row}
                    width={basicDataWithField[fieldData]?.width}
                    onChange={(field, value) => {
                      setStateChange(!stateChange);
                      const locObj = {
                        ...basicDataWithField,
                      };
                      locObj[field].value = value;
                      setBasicDataWithField(locObj);
                      setStateChange(!stateChange);
                    }}
                    fieldData={fieldData}
                    stateChange={stateChange}
                    setStateChange={setStateChange}
                  />
                ))}

                <Stack
                  sx={{
                    width: "100%",
                  }}
                  direction="column"
                  spacing={4}
                >
                  <FormHeaderWithActions
                    label="Emails"
                    actions={
                      <HorizBox>
                        <Button
                          startIcon={<Add />}
                          variant="outlined"
                          onClick={() => setEmails([...emails, ""])}
                        >
                          Add Email
                        </Button>
                      </HorizBox>
                    }
                  >
                    {emails.map((email, index) => (
                      <HorizBox>
                        <TextField
                          key={index}
                          value={email}
                          onChange={(e) => {
                            const newEmails = [...emails];
                            newEmails[index] = e.target.value;
                            setEmails(newEmails);
                          }}
                          size="small"
                          fullWidth={true}
                          placeholder={`Enter Email ${index + 1}`}
                          sx={{ mb: 1 }}
                        />
                        <IconButton
                          onClick={() => {
                            const newEmails = [...emails];
                            newEmails.splice(index, 1);
                            setEmails(newEmails);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </HorizBox>
                    ))}
                  </FormHeaderWithActions>

                  <FormHeaderWithActions
                    label="Phone Numbers"
                    actions={
                      <HorizBox>
                        <Button
                          startIcon={<Add />}
                          variant="outlined"
                          onClick={() => setPhoneNumbers([...phoneNumbers, ""])}
                        >
                          Add Phone Number
                        </Button>
                      </HorizBox>
                    }
                  >
                    {phoneNumbers.map((phone, index) => (
                      <HorizBox>
                        <TextField
                          key={index}
                          value={phone}
                          onChange={(e) => {
                            const newPhoneNumbers = [...phoneNumbers];
                            newPhoneNumbers[index] = e.target.value;
                            setPhoneNumbers(newPhoneNumbers);
                          }}
                          size="small"
                          fullWidth={true}
                          placeholder={`Enter Phone Number ${index + 1}`}
                          sx={{ mb: 1 }}
                        />
                        <IconButton
                          onClick={() => {
                            const newPhoneNumbers = [...phoneNumbers];
                            newPhoneNumbers.splice(index, 1);
                            setPhoneNumbers(newPhoneNumbers);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </HorizBox>
                    ))}
                  </FormHeaderWithActions>

                  <FormHeaderWithActions
                    label="Clients"
                    actions={
                      <HorizBox>
                        <Button
                          startIcon={<Add />}
                          variant="outlined"
                          onClick={addClient}
                        >
                          Add Client
                        </Button>
                      </HorizBox>
                    }
                  >
                    {clients.map((client, index) => (
                      <HorizBox
                        sx={{
                          alignItems: "start",
                        }}
                      >
                        <Grid sx={{ width: "100%" }} container spacing={1}>
                          <Grid item xs={6} key={index}>
                            <TextField
                              value={client.displayName}
                              onChange={(e) => {
                                const newClients = [...clients];
                                newClients[index].displayName = e.target.value;
                                setClients(newClients);
                              }}
                              size="small"
                              fullWidth={true}
                              placeholder={`Enter Client ${index + 1} Name`}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={client.email}
                              onChange={(e) => {
                                const newClients = [...clients];
                                newClients[index].email = e.target.value;
                                setClients(newClients);
                              }}
                              size="small"
                              fullWidth={true}
                              placeholder={`Enter Client ${index + 1} Email`}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={client.phone}
                              onChange={(e) => {
                                const newClients = [...clients];
                                newClients[index].phone = e.target.value;
                                setClients(newClients);
                              }}
                              size="small"
                              fullWidth={true}
                              placeholder={`Enter Client ${index + 1} Phone`}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Box></Box>
                          </Grid>
                        </Grid>
                        <IconButton
                          onClick={() => {
                            const newClients = [...clients];
                            newClients.splice(index, 1);
                            setClients(newClients);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </HorizBox>
                    ))}
                  </FormHeaderWithActions>
                </Stack>

                {/* role specific data */}
                {roleType === "Prospect" ? (
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ mt: 2 }}>
                      <FormBox label="Make Private">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isPrivate}
                              onChange={(evt, checked) => {
                                setIsPrivate(checked);
                              }}
                            />
                          }
                          label="Make this requirement private"
                        />
                      </FormBox>
                      <FormBox label="Listing">
                        {selectedListingData?._id ? (
                          <HorizBox
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid #D8DEE4",
                              backgroundColor: "#F5F7FA",
                              display: "inline-flex",
                              px: "10px",
                              py: "10px",
                            }}
                          >
                            <Avatar
                              src={selectedListingData?.displayPicture?.url}
                              sx={{
                                width: "30px",
                                height: "30px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                color: "#54555A",
                              }}
                            >
                              {selectedListingData?.title}
                            </span>
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => setSelectedListingData(null)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M13.5 4.5L4.5 13.5"
                                  stroke="#717171"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4.5 4.5L13.5 13.5"
                                  stroke="#717171"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          </HorizBox>
                        ) : (
                          <ListingPicker
                            selectedListing={listing}
                            setSelectedListing={setListing}
                            defaultListingId={defaultListingId}
                          />
                        )}
                      </FormBox>
                      <FormBox label="Requirements">
                        <Typography variant="body1" gutterBottom>
                          Tell Us About Your Requirements
                        </Typography>
                      </FormBox>
                      <SpaceBetween
                        left={
                          <FormBox label="Title">
                            <TextField
                              placeholder="Enter Title"
                              value={requirementTitle}
                              onChange={(e) =>
                                setRequirementTitle(e.target.value)
                              }
                              size="small"
                              fullWidth={true}
                            />
                          </FormBox>
                        }
                        right={
                          <FormBox label="Requirement Type">
                            <Select
                              value={selectedRequirementType}
                              onChange={(evt) =>
                                setSelectedRequirementType(evt.target.value)
                              }
                              size="small"
                              fullWidth={true}
                            >
                              {REQUIREMENT_TYPE_OPTIONS.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                            </Select>
                          </FormBox>
                        }
                      />
                      <FormBox label="Price Range">
                        <SpaceBetween
                          left={
                            <TextField
                              placeholder="Min"
                              type="Number"
                              sx={{ mr: 2 }}
                              value={minPrice}
                              onChange={(e) => setMinPrice(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CurrencyRupee />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                            />
                          }
                          right={
                            <TextField
                              placeholder="Max"
                              type="Number"
                              value={maxPrice}
                              onChange={(e) => setMaxPrice(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CurrencyRupee />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                            />
                          }
                        />
                      </FormBox>

                      <SpaceBetween left={locationForm} />
                      <PropertyTypeChooser
                        propertyType={propertyType}
                        setPropertyType={setPropertyType}
                      />
                      <FormBox label="Area">
                        <SpaceBetween
                          left={
                            <TextField
                              placeholder="Min"
                              type="Number"
                              sx={{ mr: 2 }}
                              value={minArea}
                              onChange={(e) => setMinArea(e.target.value)}
                              size="small"
                              fullWidth={true}
                            />
                          }
                          right={
                            <TextField
                              placeholder="Max"
                              type="Number"
                              sx={{ mr: 2 }}
                              value={maxArea}
                              onChange={(e) => setMaxArea(e.target.value)}
                              size="small"
                              fullWidth={true}
                            />
                          }
                        />
                      </FormBox>
                      <FormBox label="Area Unit">
                        <SpaceBetween
                          left={
                            <UnitAutocomplete
                              value={propertyAreaUnit}
                              onChange={(evt, value) =>
                                setPropertyAreaUnit(value)
                              }
                              size="small"
                            />
                          }
                        />
                      </FormBox>
                      <SpaceBetween
                        left={
                          <CheckGroup
                            label="Furnishing"
                            options={FURNISHING_OPTIONS}
                            selectedOptions={furnishings}
                            setSelectedOptions={setFurnishings}
                          />
                        }
                      />
                      <SpaceBetween
                        left={
                          <CountPicker
                            countFrom={1}
                            countTo={5}
                            label="Bedrooms"
                            count={bedroomCount}
                            setCount={(val) => setBedroomCount(val)}
                            showMoreButton={true}
                          />
                        }
                      />
                      <SpaceBetween
                        left={
                          <CountPicker
                            countFrom={1}
                            countTo={5}
                            label="Bathroom"
                            count={bathroomCount}
                            setCount={(val) => setBathroomCount(val)}
                            showMoreButton={true}
                          />
                        }
                      />
                      <SpaceBetween
                        left={
                          <CountPicker
                            countFrom={1}
                            countTo={5}
                            label="Balcony"
                            count={balconyCount}
                            setCount={(val) => setBalconyCount(val)}
                            showMoreButton={true}
                          />
                        }
                      />
                      <SpaceBetween
                        left={
                          <CheckGroupWithObj
                            label="Floor"
                            options={FLOOR_OPTIONS}
                            selectedOptions={floors}
                            setSelectedOptions={setFloors}
                          />
                        }
                      />
                      <SpaceBetween
                        left={
                          <CheckGroupGrid
                            label="Amenities"
                            options={AMENITY_OPTIONS}
                            selectedOptions={amenities}
                            setSelectedOptions={setAmenities}
                          />
                        }
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    {Object.keys(roleDataWithField).map((fieldData) => (
                      <AllCommonField
                        type={roleDataWithField[fieldData]?.type}
                        value={roleDataWithField[fieldData]?.value}
                        lable={roleDataWithField[fieldData]?.lable}
                        row={roleDataWithField[fieldData]?.row}
                        width={roleDataWithField[fieldData]?.width}
                        options={roleDataWithField[fieldData]?.options}
                        fieldData={fieldData}
                        onChange={(field, value) => {
                          setStateChange(!stateChange);
                          const locObj = {
                            ...roleDataWithField,
                          };
                          locObj[field].value = value;
                          setRoleDataWithField(locObj);
                          setStateChange(!stateChange);
                        }}
                      />
                    ))}
                    <Box sx={{ minWidth: "50%" }}>
                      <FormControl fullWidth>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            marginBottom: "0.3rem",
                          }}
                        >
                          Source
                        </Typography>
                        <Select
                          sx={{ height: "2.5rem" }}
                          value={source}
                          //label="Source"
                          onChange={(e) => setSource(e.target.value)}
                        >
                          <MenuItem value={"Facebook"}>Facebook</MenuItem>
                          <MenuItem value={"Instagram"}>Instagram</MenuItem>
                          <MenuItem value={"Twitter"}>Twitter</MenuItem>
                          <MenuItem value={"Website"}>Website</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
              </div>
            </div>
            {!pal?._id && (
              <div className={classes.directoryMainCont}>
                <div className={classes.directoryHeadCont}>
                  <h3>Add In Directory</h3>

                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setStateChange(!stateChange);
                      const obj = {
                        type: "Project",
                        profile: null,
                        role: financialRelationType,
                      };
                      let arr = [...selectedProfileForRelation];
                      arr.push(obj);
                      setSelectedProfileForRelation(arr);
                      setStateChange(!stateChange);
                    }}
                  >
                    Add New
                  </Button>
                </div>

                <div className={classes.allSelectedProfileCont}>
                  {selectedProfileForRelation &&
                    selectedProfileForRelation.map((profileTypeRole, i) => (
                      <div className={classes.divAndDeleteCont}>
                        <div className={classes.profileTypeRoleCont}>
                          <Autocomplete
                            size="small"
                            value={profileTypeRole?.type}
                            options={relationScopTypes}
                            getOptionLabel={(option) => option || ""}
                            getOptionSelected={(option) => {
                              return option === profileTypeRole?.type;
                            }}
                            style={{ width: "25%" }}
                            onChange={(event, value) => {
                              setStateChange(!stateChange);
                              let obj = {
                                ...profileTypeRole,
                              };
                              if (value === "Myself") {
                                const profileData = {
                                  _id: user?.profile,
                                  parent: user,
                                  parentModelName: "User",
                                };
                                obj.profile = profileData;
                              }
                              obj.type = value;
                              let arr = [...selectedProfileForRelation];
                              arr[i] = obj;
                              setSelectedProfileForRelation(arr);
                              setStateChange(!stateChange);
                            }}
                            renderInput={(params) => (
                              <MUITextField
                                {...params}
                                label={"Type"}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />

                          {profileTypeRole?.type === "Myself" ? (
                            <div></div>
                          ) : (
                            <>
                              {profileTypeRole?.type === "Project" ? (
                                <Autocomplete
                                  size="small"
                                  value={profileTypeRole?.profile}
                                  options={projectOptions}
                                  getOptionLabel={(option) =>
                                    option?.parent?.displayName || ""
                                  }
                                  style={{ width: "60%" }}
                                  onChange={(event, value) => {
                                    setStateChange(!stateChange);
                                    let obj = {
                                      ...profileTypeRole,
                                    };
                                    obj.profile = value;
                                    let arr = [...selectedProfileForRelation];
                                    arr[i] = obj;
                                    setSelectedProfileForRelation(arr);
                                    setStateChange(!stateChange);
                                  }}
                                  renderInput={(params) => (
                                    <MUITextField
                                      {...params}
                                      label={"Project"}
                                      variant="outlined"
                                      size="small"
                                    />
                                  )}
                                />
                              ) : (
                                <Autocomplete
                                  size="small"
                                  value={profileTypeRole?.profile}
                                  options={organizationOptions}
                                  getOptionLabel={(option) =>
                                    option?.parent?.displayName || ""
                                  }
                                  style={{ width: "60%" }}
                                  onChange={(event, value) => {
                                    setStateChange(!stateChange);
                                    let obj = {
                                      ...profileTypeRole,
                                    };
                                    obj.profile = value;
                                    let arr = [...selectedProfileForRelation];
                                    arr[i] = obj;
                                    setSelectedProfileForRelation(arr);
                                    setStateChange(!stateChange);
                                  }}
                                  renderInput={(params) => (
                                    <MUITextField
                                      {...params}
                                      label={"Organization"}
                                      variant="outlined"
                                      size="small"
                                    />
                                  )}
                                />
                              )}
                            </>
                          )}
                          {/* <Autocomplete
                            size="small"
                            value={profileTypeRole?.role}
                            options={mainRoles.filter(
                              (role) =>
                                role !== "Owner" &&
                                role !== "Admin" &&
                                role !== "Viewer" &&
                                role !== "Other"
                            )}
                            getOptionLabel={(option) => option || ""}
                            getOptionSelected={(option) => {
                              return option === roleType;
                            }}
                            style={{ width: "25%" }}
                            onChange={(event, value) => {
                              setStateChange(!stateChange);
                              let arr = [...selectedProfileForRelation];
                              arr[i].role = value;
                              setSelectedProfileForRelation(arr);
                              setStateChange(!stateChange);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Role"}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          /> */}
                        </div>
                        <IconButton
                          onClick={() => {
                            let arr = [...selectedProfileForRelation];
                            arr.splice(i, 1);
                            setSelectedProfileForRelation(arr);
                          }}
                        >
                          <DeleteIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div style={{ width: "100%", height: "5px" }}>
            {loading && <LinearProgress />}
          </div>
          <div className={classes.btnCont}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              type="submit"
            >
              {pal?._id ? "Update & Close" : "Save & Close"}
            </Button>
          </div>
          <Snackbar
            open={warningBool}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose} severity="error">
              {warningMsg}
            </Alert>
          </Snackbar>
        </form>
      }
    />
  );
};

export default CreatePalDrawer;
