import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, InputAdornment, Avatar, IconButton } from '@material-ui/core';
import _ from 'lodash';
import AddIcon from "@material-ui/icons/Add";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import RoleAddDrawer from './Role.Add.Drawer';
import { getAllAccessRolesByTeam, getParticipantsCount, getParticipants, getParticipantProfileIds, invitationSendAgain } from './api.call';
import Chip from '@material-ui/core/Chip';
import ParticipantAddDrawer from './Participant.Add.Drawer';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from "react-use";
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import EmailIcon from '@material-ui/icons/Email';
import Skeleton from '@material-ui/lab/Skeleton';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { TbDotsCircleHorizontal } from "react-icons/tb";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import moment from 'moment';
import AccessControlStoreHook from './AccessControlStoreHook';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { BsSendFill } from "react-icons/bs";
import CircularProgress from '@material-ui/core/CircularProgress';
import { TOTAL_ACL_PERMISION } from "../../helpers/constants";

const useStyles = makeStyles((theme) => ({
    headerSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    mainCont: {
        width: "100%",
        padding: "20px 0px 50px"
    },
    roleCont: {
        width: "100%",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "white",
        boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)"
    },
    participantCont: {
        width: "100%",
        borderRadius: "5px",
        backgroundColor: "white",
        marginTop: "15px",
        boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)"
    },
    allRolesCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        "& div": {
            padding: "2px 7px",
            marginBottom: "7px",
            marginRight: "7px",
            border: "1px solid #d5d5d5",
            borderRadius: "5px",
            cursor: "pointer",
            backgroundColor: "#f0f0f0",
            "&:hover": {
                backgroundColor: "#e5e5e5"
            }
        }
    },
    emptyCont: {
        width: "100%",
        padding: "20px 0px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            width: "170px",
            height: "auto"
        },
        "& p": {
            color: "gray",
            fontSize: "12px",
            marginTop: "10px"
        },
        "& a": {
            cursor: "pointer",
            color: theme.palette.primary.main
        }
    },
    detailsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    nameCont: {
        width: "100%",
        marginLeft: "5px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "13px",
            fontWeight: "400"
        }
    },
    participantTableCont: {
        width: "100%",
    },
    tableContainer: {
        width: "100%",
        height: "calc(100vh - 120px)",
        "& tr": {
            height: "55px",
            outline: "1px solid #d2d2d2",
        },
        "&.MuiTableCell-root": {
            outline: "1px solid #ececec",
            border: "none",
        },
        "& table": {
            // outline: "1px solid #d2d2d2",
            border: "none",
            tableLayout: "auto",
            borderCollapse: "collapse",
        },
        "& th": {
            // outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "10px"
        },
        "& td": {
            // outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px 10px",
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
    skeletonSty: {
        width: "90px",
        height: "30px",
        marginBottom: "5px",
        marginRight: "5px",
        borderRadius: "5px"
    },
    roleSkeletonCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    participantSkeletonSty: {
        width: "100%",
        height: "45px",
        marginBottom: "10px"
    },
    copyAccess: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& p": {
            marginRight: "10px"
        },
        "& .MuiIconButton-root": {
            padding: "2px"
        },
        "& .MuiSvgIcon-root": {
            fontSize: "20px"
        }
    },
    permisionCountCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    permisionCount: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%);"
    }
}));

const AccessRoles = ({
    team
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { checkAccess } = AccessControlStoreHook();

    const { user } = useSelector((state) => state.auth);

    const [addRoleDrawer, setAddRoleDrawer] = useState(false)
    const [openParticipantDrawer, setOpenParticipantDrawer] = useState(false)
    const [accessRoles, setAccessRoles] = useState([])
    const [accessRolesLoader, setAccessRolesLoader] = useState(true)
    const [editRoleObj, setEditRoleObj] = useState(null)
    const [roleDrawerView, setRoleDrawerView] = useState("Add")
    const [nameSearch, setNameSearch] = useState("")
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(25)
    const [changeState, setChangeState] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [loader, setLoader] = useState(true)
    const [allFilteredParticipants, setAllFilteredParticipants] = useState([])
    const [participantEditObj, setParticipantEditObj] = useState(null)
    const [exisitingProfileIds, setExisitingProfileIds] = useState([])
    const [copySuccess, setCopySuccess] = useState(false);

    const getParticipantsCountApiCall = async () => {
        getParticipantsCount({ teamId: team?._id })
            .then((data) => {
                let locTotalPage = Math.ceil(data / pageLimit);
                setTotalPages(locTotalPage)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getExisitingParticipantProfileIds = async () => {
        getParticipantProfileIds({ teamId: team?._id })
            .then((data) => {
                setExisitingProfileIds(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getParticipantsCountApiCall()
        getExisitingParticipantProfileIds()
    }, [team?._id])

    const getAllParticipantsApiCall = async () => {
        setLoader(true)
        await getParticipants({
            teamId: team?._id,
            nameSearch: nameSearch,
            limit: pageLimit,
            curPage: curPage - 1
        })
            .then((data) => {
                const resultArr = data.map((participant) => {
                    let count = 0;
                    participant.permissions.map((aclP) => {
                        count = count + 1 + aclP?.fields?.length || 0
                    })
                    return {
                        ...participant,
                        numberOfPermissions: count
                    }
                })
                setAllFilteredParticipants(resultArr)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useDebounce(() => {
        getAllParticipantsApiCall()
    }, 1000, [curPage, nameSearch, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [nameSearch])

    const afterParticipantsAdd = (allNewParticipants, isEdit) => {
        if (isEdit) {
            let updatedParticipants = allFilteredParticipants.map((participant) => {
                if (participant?._id == allNewParticipants?._id) {
                    let count = 0;
                    allNewParticipants.permissions.map((aclP) => {
                        count = count + 1 + aclP?.fields?.length || 0
                    })
                    return {
                        ...allNewParticipants,
                        numberOfPermissions: count
                    }
                } else {
                    return participant;
                }
            })
            setAllFilteredParticipants(updatedParticipants)
        } else {
            setCurPage(1)
            setChangeState(!changeState)
        }
    }

    //permission roles
    useEffect(() => {
        setAccessRolesLoader(true)
        getAllAccessRolesByTeam({ team: team?._id })
            .then((data) => {
                setAccessRoles(data)
                setAccessRolesLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [team])

    const addNewFunction = (newItem, isEdit) => {
        if (isEdit) {
            let arr = [];
            accessRoles.map((access) => {
                if (access?._id == newItem?._id) {
                    arr.push(newItem)
                } else {
                    arr.push(access)
                }
            })
            setAccessRoles(arr)
        } else {
            setAccessRoles([...accessRoles, newItem])
        }
    }

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
        dispatch({
            type: "AddApiAlert",
            payload: {
                success: true,
                message: "Access Code copy successfully",
            },
        });
    };

    const resendInvitation = async (curData) => {
        await invitationSendAgain({
            participantId: curData?._id,
            teamId: team?._id,
            teamProfileId: team?.parent?.profile?._id,
            teamParentModel: team?.parentModelName,
            participantProfile: curData?.profile?._id,
            role: curData?.mainRole,
            userProfile: user?.profile,
            reciverFirstName: curData?.profile?.parent?.displayName.includes(" ") ? curData.profile.parent.displayName.split(" ")[0] : curData?.profile?.parent?.displayName,
            senderName: user?.displayName,
            teamName: team?.parent?.displayName,
            reciverMail: curData?.profile?.parent?.email,
            reciverFullName: curData?.profile?.parent?.displayName,
        })
            .then((data) => {
                dispatch({
                    type: "AccessRole",
                    payload: {
                        success: true,
                        message: "Invitation sent successfully",
                    },
                });
                if (data?.isNew) {
                    afterParticipantsAdd([], false)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }




    return (<div className={classes.mainCont} >
        <div className={classes.roleCont} >
            <div className={classes.headerSty} >
                <h3>Roles Management</h3>
                {!checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Create-Role") ? (<div></div>) : (<>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setRoleDrawerView("Add")
                            setEditRoleObj(null)
                            setAddRoleDrawer(true)
                        }}
                    >
                        Add Role
                    </Button>
                    <RoleAddDrawer
                        addRoleDrawer={addRoleDrawer}
                        setAddRoleDrawer={setAddRoleDrawer}
                        team={team}
                        editRoleObj={editRoleObj}
                        addNewFunction={addNewFunction}
                        roleDrawerView={roleDrawerView}
                        setRoleDrawerView={setRoleDrawerView}
                    />
                </>)}
            </div>
            {accessRolesLoader ? (<div className={classes.roleSkeletonCont} >
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
            </div>) : (<>
                {accessRoles && accessRoles.length > 0 ? (
                    <div className={classes.allRolesCont} >
                        {accessRoles && accessRoles.map((roleAccess) => (
                            <div
                                onClick={() => {
                                    if (checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Role")) {
                                        setRoleDrawerView("View")
                                        setEditRoleObj(roleAccess)
                                        setAddRoleDrawer(true)
                                    }
                                }}
                            >
                                {roleAccess?.role}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={classes.emptyCont} >
                        <p>No role exists in the current directory. <a
                            onClick={() => {
                                setRoleDrawerView("Add")
                                setEditRoleObj(null)
                                setAddRoleDrawer(true)
                            }}
                        >Click here</a> to create a new role</p>
                    </div>
                )}
            </>)}
        </div>
        <div className={classes.participantCont} >
            <div className={classes.headerSty} style={{ padding: "10px" }} >
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder={"Enter name/username"}
                    autoComplete='off'
                    value={nameSearch}
                    style={{ width: "350px" }}
                    onChange={(e) => {
                        setNameSearch(e.target.value)
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />

                {!checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Create-Participant") ? (<div></div>) : (<>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setParticipantEditObj(null)
                            setOpenParticipantDrawer(true)
                        }}
                    >
                        Add Participant
                    </Button>
                    <ParticipantAddDrawer
                        openParticipantDrawer={openParticipantDrawer}
                        setOpenParticipantDrawer={setOpenParticipantDrawer}
                        accessRoles={accessRoles}
                        team={team}
                        exisitingProfileIds={exisitingProfileIds}
                        afterParticipantsAdd={afterParticipantsAdd}
                        participantEditObj={participantEditObj}
                    />
                </>)}
            </div>
            {loader ? (<>
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
                <Skeleton variant="rect" className={classes.participantSkeletonSty} />
            </>) : (<>{allFilteredParticipants && allFilteredParticipants.length > 0 ? (
                <div className={classes.participantTableCont} >
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="sticky table" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={{ minWidth: "160px" }} >
                                        Name
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "70px" }} >
                                        Designation
                                    </TableCell>
                                    {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Participant_Access") ? (
                                        <TableCell align="center" style={{ minWidth: "160px" }} >
                                            Access Role(s)
                                        </TableCell>
                                    ) : null}
                                    {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Participant_Permissions") ? (
                                        <TableCell align="center" style={{ minWidth: "30px" }} >
                                            Permissions
                                        </TableCell>
                                    ) : null}
                                    {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Access_Code") ? (
                                        <TableCell align="center" style={{ minWidth: "120px" }} >
                                            Access Code
                                        </TableCell>
                                    ) : null}
                                    <TableCell align="center" style={{ minWidth: "80px" }} >
                                        Invitation
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "100px" }} >
                                        Join Date
                                    </TableCell>
                                    {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Update-Participant") ||
                                        checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Update-Resend_Invite") ||
                                        checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Delete-Participant") ? (
                                        <TableCell align="center" style={{ width: "320 px" }} >
                                            Actions
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allFilteredParticipants && allFilteredParticipants.length > 0 && allFilteredParticipants.slice((curPage - 1) * pageLimit, (curPage - 1) * pageLimit + pageLimit).map((data) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="left" >
                                                <div className={classes.detailsCont} >
                                                    {data?.profile ? (<>
                                                        <Avatar
                                                            src={data?.profile?.parent?.displayPicture?.thumbUrl ? data?.profile?.parent?.displayPicture?.thumbUrl : data?.profile?.parent?.displayPicture?.url}
                                                            alt={data?.profile?.parent?.displayName}
                                                            style={{ width: "30px", height: "30px" }}
                                                        />
                                                        <div className={classes.nameCont} >
                                                            <h3>{data?.profile?.parent?.displayName}</h3>
                                                            <p>
                                                                {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Participant_Mail") ? data?.profile?.parent?.email : `xxxxxxxxxxx@xxxxx.com`}
                                                            </p>
                                                        </div>
                                                    </>) : (<>
                                                        {data?.outSiderIdentifier.includes("@") ? (<>
                                                            <Avatar style={{ width: "30px", height: "30px" }}>
                                                                <EmailIcon style={{ fontSize: "23px" }} />
                                                            </Avatar>
                                                            <div className={classes.nameCont} >
                                                                <h3>{data?.outSiderIdentifier.split("@")[0]}</h3>
                                                                <p>{data?.outSiderIdentifier}</p>
                                                            </div>
                                                        </>) : (<>
                                                            <Avatar style={{ width: "30px", height: "30px" }}>
                                                                <PhoneAndroidIcon style={{ fontSize: "23px" }} />
                                                            </Avatar>
                                                            <div className={classes.nameCont} >
                                                                <h3>{data?.outSiderIdentifier}</h3>
                                                                <p>{data?.outSiderIdentifier && data.outSiderIdentifier.substring(data?.outSiderIdentifier.length - 10)}</p>
                                                            </div>
                                                        </>)}
                                                    </>)}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.mainRole}
                                            </TableCell>
                                            {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Participant_Access") ? (
                                                <TableCell align="center" >
                                                    {data?.rolePermissions && (<>{data.rolePermissions.map((role, i) => (
                                                        <Chip
                                                            key={i}
                                                            size="small"
                                                            label={role?.role}
                                                            style={{ marginBottom: "5px", marginRight: "5px" }}
                                                        />
                                                    ))}</>)}
                                                </TableCell>
                                            ) : null}
                                            {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Participant_Permissions") ? (
                                                <TableCell align="center" >
                                                    <div className={classes.permisionCountCont} >
                                                        <CircularProgress variant="determinate" value={((data?.numberOfPermissions || 0) * 100) / TOTAL_ACL_PERMISION} />
                                                        <div className={classes.permisionCount} >{data?.numberOfPermissions}</div>
                                                    </div>
                                                </TableCell>
                                            ) : null}
                                            {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Read-Access_Code") ? (
                                                <TableCell align="center" >
                                                    {data?.accessCode && (
                                                        <div className={classes.copyAccess} >
                                                            <p>{data?.accessCode}</p>
                                                            <IconButton onClick={() => { copyToClipboard(data?.accessCode) }} >
                                                                <FileCopyIcon />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            ) : null}
                                            <TableCell align="center" >
                                                <div style={{ display: "flex", alignItems: "center" }} >
                                                    {data?.isAccepted ? (<><IoMdCheckmarkCircleOutline style={{ color: "green", marginRight: "5px", fontSize: "20px" }} /> Accepted</>) : (<><TbDotsCircleHorizontal style={{ color: "gray", marginRight: "5px", fontSize: "20px" }} />Pending</>)}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.joinDate ? moment(data?.joinDate).format('MMM Do YYYY, hh:mm a') : ""}
                                            </TableCell>
                                            {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Update-Participant") ||
                                                checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Update-Resend_Invite") ||
                                                checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Delete-Participant") ? (
                                                <TableCell align="center" >
                                                    <div style={{ padding: "3px 0px" }} >
                                                        {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Delete-Participant") ? (
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                color="secondary"
                                                                style={{ marginRight: "5px" }}
                                                                startIcon={<DeleteIcon />}
                                                                disabled
                                                            >
                                                                Remove
                                                            </Button>
                                                        ) : null}
                                                        {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Update-Participant") ? (
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                color="primary"
                                                                startIcon={<CreateIcon />}
                                                                style={{ marginRight: "5px" }}
                                                                onClick={() => {
                                                                    setParticipantEditObj(data)
                                                                    setOpenParticipantDrawer(true)
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        ) : null}
                                                        {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Update-Resend_Invite") ? (
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                color="primary"
                                                                startIcon={<BsSendFill />}
                                                                onClick={() => {
                                                                    resendInvitation(data)
                                                                }}
                                                            >
                                                                Resend Invitation
                                                            </Button>
                                                        ) : null}
                                                    </div>
                                                </TableCell>
                                            ) : null}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.paginationCont} >
                        <Pagination
                            count={totalPages}
                            page={curPage}
                            color="primary"
                            siblingCount={0}
                            onChange={(event, value) => { setCurPage(value) }}
                        />
                    </div>
                </div>
            ) : (
                <div className={classes.emptyCont} >
                    <p>No participant exists in the current directory. <a
                        onClick={() => {

                        }}
                    >Click here</a> to add new participant</p>
                </div>
            )}</>)}
        </div>
    </div>);
};

export default AccessRoles;