import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomSelectAutocomplete from "../CommonComponents/CustomSelectAutocomplete";

const OrgPicker = ({
  selectedOrg, setSelectedOrg, defaultOrganizationId,
  defaultOrganizationProfileId, fullWidth = false, disabled = false,
}) => {
  const { teamDictionary, teamIds } = useSelector((state) => state.team);

  const [orgs, setOrgs] = useState([]);

  const setUpOrgs = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model == "Organization") {
        setOrgs((prev) => [...prev, team?.parent]);
      }
    }
  }

  useEffect(() => {
    setUpOrgs();
  }, []);

  const handleDefaultSelection = () => {
    if (orgs?.length > 0) {
      if (defaultOrganizationId) {
        let index = orgs.findIndex((item) => item?._id == defaultOrganizationId);
        if (index != -1) {
          setSelectedOrg(orgs[index]);
        }
      } else if (defaultOrganizationProfileId) {
        let index = orgs.findIndex((item) => item?.profile?._id == defaultOrganizationProfileId);
        if (index != -1) {
          setSelectedOrg(orgs[index]);
        }
      } else {
        setSelectedOrg(undefined);
      }
    } else {
      setSelectedOrg(undefined);
    }
  };

  useEffect(() => {
    handleDefaultSelection();
  }, [orgs, defaultOrganizationId, defaultOrganizationProfileId]);

  return (
    <Box>
      <CustomSelectAutocomplete
        value={selectedOrg}
        onChange={(value) => {
          setSelectedOrg(value)
        }}
        isMulti={false}
        options={orgs}
        disabled={disabled}
        fullWidth={fullWidth}
        style={{ width: "100%" }}
        placeholder={"Select Organization"}
        getLabel={(s) => s?.displayName}
        getIdentity={(s) => s?._id}
        getImage={(s) => selectedOrg?.displayPicture?.url}
        getDisplayName={(s) => s?.displayName}
        getUsername={(s) => s?.username}
      />
    </Box>
  );
};

export default OrgPicker;