import React, { useEffect, useState } from "react";
import Select from "./Select";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import HorizBox from "./HorizBox";
import Api from "../../../helpers/Api";

const ListingPicker = ({
  size = "medium",
  selectedListing,
  setSelectedListing,
  defaultListingId,
  project = "",
  setProject = () => {},
  setOrganization = () => {},
}) => {
  console.log("library", selectedListing);
  console.log(project);
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const MODES = ["User", "Project", "Organization"];
  const [mode, setMode] = useState("User");
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(project);
  const [listings, setListings] = useState([]);

  const setUpOrgs = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Organization") {
        setOrgs((prev) => [...prev, team?.parent]);
      }
    }
  };

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        setProjects((prev) => [...prev, team?.parent]);
      }
    }
  };

  useEffect(() => {
    setUpOrgs();
    setUpProjects();
  }, []);

  const getListingsByMode = async () => {
    try {
      let _id =
        mode === "Organization"
          ? selectedOrg
          : mode === "Project"
          ? selectedProject
          : user._id;

      if (!_id) return console.log("No _id found");

      const { data } = await Api.post("/listing/getListingsByMode", {
        modeId: _id,
        mode: mode,
      });
      setListings(data);
    } catch (err) {
      console.error(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getListingsByMode();
  }, [mode, selectedOrg, selectedProject]);

  useEffect(() => {
    setSelectedListing();
  }, [mode]);

  useEffect(() => {
    if (defaultListingId) {
      console.log("Setting default listing id", defaultListingId);
      setSelectedListing(defaultListingId);
    }
  }, [defaultListingId]);

  return (
    <HorizBox>
      <FormControl>
        <InputLabel id="mode">Mode</InputLabel>
        <Select
          value={mode}
          onChange={(e) => setMode(e.target.value)}
          label="Mode"
          sx={{ minWidth: "120px" }}
          size={size}
        >
          <MenuItem key={1} value={"select"}>
            Select
          </MenuItem>
          {MODES.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {mode === "Project" && (
        <FormControl>
          <InputLabel id="Project">Project</InputLabel>
          <Select
            label="Project"
            value={selectedProject}
            onChange={(e) => {
              setProject(projects.find((item) => item._id === e.target.value));
              setSelectedProject(e.target.value);
            }}
            sx={{ minWidth: "120px" }}
            size={size}
          >
            {projects.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {mode === "Organization" && (
        <FormControl>
          <InputLabel id="Organization">Organization</InputLabel>
          <Select
            label="Organization"
            value={selectedOrg}
            onChange={(e) => {
              setSelectedOrg(e.target.value);
              setOrganization(orgs.find((item) => item._id === e.target.value));
            }}
          >
            {orgs.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl>
        <InputLabel id="Listing">Listing</InputLabel>
        <Select
          label="Listing"
          value={selectedListing}
          onChange={(e) => setSelectedListing(e.target.value)}
          sx={{ minWidth: "120px" }}
        >
          <MenuItem value="">Select</MenuItem>
          {listings?.map((item) => (
            <MenuItem key={item?._id} value={item?._id}>
              {item?.title || "Untitled"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </HorizBox>
  );
};

export default ListingPicker;
