import React from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import ProjectContacts from "../projects/project.contacts";

const ProjectHomeContacts = ({ team }) => {
  return (
    <StandardAppContainerRounded>
      {/* <TableContainer
        columns={["CONTACT NAME", "ROLE", "PHONE", "EMAIL", "ACTION"]}
        data={listings}
        loading={loading}
        tableStyle={tableStyle}
        enableSelectAll={enableCheck}
      ></TableContainer> */}
      <ProjectContacts
        profileId={team?.parent?.profile?._id}
        userRole="Viewer"
        wallet={team?.parent?.profile?.parent?.wallet}
        parent={team?.parent?.profile?.parent?._id}
        parentModelName={team?.parent?.profile?.parentModelName}
      />
    </StandardAppContainerRounded>
  );
};

export default ProjectHomeContacts;
