import { Box } from "@mui/material";

import React from "react";

const SpaceBetween = ({
  left,
  right,
  onClick,
  disableResponsive,
  leftStyleProps,
  rightStyleProps,
  ...props
}) => {
  return (
    <Box
      display="flex"
      alignItems={disableResponsive ? "center" : { xs: "start", md: "center" }}
      justifyContent={
        disableResponsive ? "center" : { xs: "flex-start", md: "center" }
      }
      flexDirection={disableResponsive ? "row" : { xs: "column", md: "row" }}
      width="100%"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      {...props}
    >
      <Box
        flex={disableResponsive ? "0.5" : { xs: "1", md: "0.5" }}
        sx={{ mr: disableResponsive ? 4 : { xs: 0, md: 4 }, ...leftStyleProps }}
        width="100%"
      >
        {left}
      </Box>
      <Box
        flex={disableResponsive ? "0.5" : { xs: "1", md: "0.5" }}
        sx={{
          mr: disableResponsive ? 4 : { xs: 0, md: 4 },
          ...rightStyleProps,
        }}
        width="100%"
      >
        {right}
      </Box>
    </Box>
  );
};

export default SpaceBetween;
