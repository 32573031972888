import React, { useEffect, useState } from "react";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import FormBox from "../../../styled/generic/FormBox";
import HorizBox from "../../../styled/generic/HorizBox";
import TextField from "../../../styled/generic/TextField";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../helpers/Api";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import { DeleteOutline } from "@mui/icons-material";
import PopupMenu from "../../../styled/generic/PopupMenu";
import { SketchPicker } from "react-color";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";

const LabelsDrawer = ({
  openLabelsDrawer,
  setOpenLabelsDrawer,
  targetId,
  defaultLibraryId,
  onLabelCreated,
  onLabelUpdated,
  labelToUpdate,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [newLabelName, setNewLabelName] = useState("");
  const [color, setColor] = useState("#FF0000");
  const [labels, setLabels] = useState([]);
  const [description, setDescription] = useState("");

  const createLabel = async () => {
    try {
      const { data } = await Api.post("/label/create", {
        targetId: targetId,
        defaultLibraryId: defaultLibraryId,
        title: newLabelName,
        description: description,
        creatorProfileId: user?.profile,
        color: color,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Label created successfully",
        },
      });
      setLabels([...labels, data]);
      onLabelCreated(data);
      setNewLabelName("");
      setDescription("");
      setOpenLabelsDrawer(false);
    } catch (e) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Label creation failed",
        },
      });
    }
  };

  const updateLabel = async () => {
    try {
      const { data } = await Api.post("/label/update", {
        _id: labelToUpdate._id,
        updateBody: {
          title: newLabelName,
          description: description,
          color: color,
        },
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Label updated successfully",
          },
        });
        setLabels(labels.map((t) => (t._id === labelToUpdate._id ? data : t)));
        setOpenLabelsDrawer(false);
        onLabelUpdated(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Label update failed",
          },
        });
      }
    } catch (e) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Label update failed",
        },
      });
    }
  };

  useEffect(() => {
    setNewLabelName(labelToUpdate?.title || "");
    setDescription(labelToUpdate?.description || "");
    setColor(labelToUpdate?.color || "#FF0000");
  }, [labelToUpdate]);

  return (
    <DrawerContainer
      title="Labels"
      open={openLabelsDrawer}
      setOpen={setOpenLabelsDrawer}
      sx={{ zIndex: 10000, position: "relative" }}
    >
      <FormBox label="Label Name">
        <TextField
          placeholder="Enter"
          value={newLabelName}
          onChange={(e) => setNewLabelName(e.target.value)}
          fullWidth={true}
        />
      </FormBox>

      <FormBox label="Description">
        <TextField
          placeholder="Enter"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth={true}
          rows={4}
          multiline
        />
      </FormBox>

      <FormBox label="Color">
        <Box
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: color,
          }}
        />

        <Box sx={{ mt: 2 }}>
          <SketchPicker color={color} onChange={(val) => setColor(val?.hex)} />
        </Box>
      </FormBox>
      <DuoButtonGroup
        primaryButtonText={labelToUpdate ? "Update Label" : "Create Label"}
        primaryButtonListener={() => {
          labelToUpdate ? updateLabel() : createLabel();
        }}
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => {
          setOpenLabelsDrawer(false);
        }}
      />
    </DrawerContainer>
  );
};

export default LabelsDrawer;
