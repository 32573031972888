import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import Api from "../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { getPopulatedTeamDataByIdForProject } from "./api.call";
import FormBox from "../styled/generic/FormBox";
import { Box, Grid, Typography } from "@mui/material";
import HorizBox from "../styled/generic/HorizBox";
import { FolderOutlined } from "@material-ui/icons";
import TableContainer from "../styled/generic/TableContainer";
import dayjs from "dayjs";

const ProjectUnit = () => {
  const { teamId } = useParams();
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [projectData, setProjectData] = useState(null);
  const [team, setTeam] = useState(null);
  const [projectBlocks, setProjectBlocks] = useState([]);
  const [rentalUnits, setRentalUnits] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [loadingUnits, setLoadingUnits] = useState(false);

  const getProjectData = async () => {
    if (teamDictionary[teamId]) {
      const teamData = teamDictionary[teamId];
      setTeam(teamData);
      setProjectData(teamData?.parent);
    } else {
      await getPopulatedTeamDataByIdForProject({ teamId: teamId })
        .then((updatedTeam) => {
          setTeam(updatedTeam);
          setProjectData(updatedTeam?.parent);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProjectBlocks = async () => {
    try {
      const { data } = await Api.post("/public/project/block/get", {
        projectId: projectData?._id,
      });

      if (data) {
        setProjectBlocks(data);
        if (data.length > 0) {
          setSelectedBlock(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    if (projectData) {
      getProjectBlocks();
    }
  }, [projectData]);

  const getRentalUnitsByBlock = async (blockId) => {
    try {
      setLoadingUnits(true);
      const { data } = await Api.post("/project/unit/getUnitsByBlock", {
        blockId: blockId,
      });

      if (data) {
        console.log("units: ", data);
        setRentalUnits(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUnits(false);
    }
  };

  useEffect(() => {
    if (selectedBlock) {
      getRentalUnitsByBlock(selectedBlock._id);
    }
  }, [selectedBlock]);

  return (
    <StandardContainer showAppBar={true} appBarTitle="Units">
      <StandardAppContainerRounded>
        <FormBox label="Blocks">
          <Grid container spacing={2}>
            {projectBlocks?.map((block) => (
              <Grid item xs={3} key={block._id}>
                <Box
                  sx={{
                    boxShadow:
                      selectedBlock?._id === block?._id
                        ? "0px 0px 6px 0px rgba(0,0,255,0.1)"
                        : "0px 0px 4px 0px rgba(0,0,0,0.05)",
                    borderRadius: "10px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedBlock(block)}
                >
                  <Typography variant="h6" gutterBottom>
                    {block?.name || "Untitled"}
                  </Typography>
                  <HorizBox>
                    <FolderOutlined />
                    <Typography variant="body1" gutterBottom>
                      {block?.unitCount || 0} Units
                    </Typography>
                  </HorizBox>
                </Box>
              </Grid>
            ))}
          </Grid>
        </FormBox>
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        <FormBox label="Units">
          <TableContainer
            columns={["UNIT NAME", "CREATED DATE"]}
            data={rentalUnits}
            loading={loadingUnits}
          >
            {rentalUnits?.map((unit) => (
              <tr key={unit._id}>
                <td>{unit?.name || "Untitled"}</td>
                <td>{dayjs(unit?.createdAt).format("DD-MM-YYYYY")}</td>
              </tr>
            ))}
          </TableContainer>
        </FormBox>
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default ProjectUnit;
