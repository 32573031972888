import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import AllFileViewers from "./AllFileViewer";
import SmartCommentList from "../../comment/smart.comment.list";
import { BsFillTagsFill } from "react-icons/bs";
import { FaMapMarkedAlt } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import { format } from "timeago.js";
import IconButton from "@material-ui/core/IconButton";
import { BiLike } from "react-icons/bi";
import Api from "../../../helpers/Api";
import ShareIcon from "@material-ui/icons/Share";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { FaRegCommentDots } from "react-icons/fa";
import NormalDialog from "./NormalDialog";
import SocialShareBtn from "./Social.Share.Btn";
import configObject from "../../../config";
import html2plaintext from "html2plaintext";

const useStyles = makeStyles((theme) => ({
  paperCont: {
    padding: "20px 35px",
    [theme.breakpoints.down("sm")]: {
      padding: "1px 10px 10px",
    },
  },
  titleText: {
    fontWeight: "510",
    fontSize: "30px",
    margin: "25px 0px 15px",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
    },
  },
  bodyCont: {
    opacity: "0.8",
    "& ul": {
      marginLeft: "45px",
    },
  },
  sectionTitle: {
    fontSize: "18px",
    marginTop: "25px",
  },
  userHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px 20px",
  },
  editorStyle: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "16px",
      color: "black",
      fontWeight: "510",
    },
    "& p": {
      fontSize: "14px",
      color: "#8f919d",
      fontWeight: "400",
    },
  },
  iconTagCont: {
    display: "flex",
    alignItems: "center",
  },
  tagContStyle: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& div": {
      backgroundColor: "#FFE7E7",
      color: "black",
      padding: "3px 7px",
      marginRight: "10px",
      marginBottom: "10px",
      borderRadius: "3px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 25px)",
      "& div": {
        fontSize: "12px",
      },
    },
  },
  locationTagContStyle: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& div": {
      color: "black",
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 25px)",
      "& div": {
        fontSize: "12px",
      },
    },
  },
  iconSty: {
    fontSize: "20px",
    marginRight: "15px",
    marginTop: "-8px",
    color: "#686868",
  },
  actionCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
    borderTop: "1px solid #F4F4F4",
    marginTop: "25px",
  },
  leftActionCont: {
    display: "flex",
    alignItems: "center",
  },
  itemCont: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
    borderRight: "1px solid #F4F4F4",
    "& p": {
      fontSize: "15px",
      fontWeight: "450",
    },
  },
}));

export default function DocFullView({ doc }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const profile = user?.profile;

  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [likeLoading, setLikeLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");

  useEffect(() => {
    setIsSaved(doc?.isSaved || false);
    setIsLiked(doc?.isLiked || false);
    setLikeCount(doc?.likeCount || 0);
    setCommentCount(doc?.commentCount || 0);
    setShareLink(`${configObject?.BASE_URL}explore/doc/${doc?._id}`);
  }, [doc]);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const commentAdded = () => {
    setCommentCount((prev) => prev + 1);
  };

  // like or remove like post (done)
  async function createLike() {
    if (!likeLoading) {
      setLikeLoading(true);
      let isLikedLocal;

      if (isLiked) {
        isLikedLocal = true;
        setLikeCount((prev) => prev - 1);
        setIsLiked(false);
      } else {
        isLikedLocal = false;
        setLikeCount((prev) => prev + 1);
        setIsLiked(true);
      }

      const like = await Api.post("like/like", {
        parent: doc?._id,
        parentModelName: "Doc",
        user: user?._id,
        profile: profile,
        isLikedLocal,
        userName: user?.displayName,
      });

      if (like.data) {
        console.log("successfull");
      } else {
        console.log("fail");
      }
      setLikeLoading(false);
    }
  }

  // save post (done)
  async function createSave() {
    setIsSaved((prev) => !prev);
    const obj = {
      parent: doc?._id,
      parentModelName: "Doc",
      user: user?._id,
      profile: profile,
    };
    const save = await Api.post("save/create", obj);

    if (save.save) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }

  return (
    <Paper className={classes.paperCont}>
      <div className={classes.userHeader}>
        <div className={classes.editorStyle}>
          <Avatar
            src={
              doc?.user?.displayPicture?.thumbUrl ||
              doc?.user?.displayPicture?.url
            }
            style={{ width: "40px", height: "40px" }}
          />
          <div style={{ marginLeft: "15px" }}>
            <h3>{doc?.user?.displayName}</h3>
            <p>Last Updated {format(doc?.updatedAt)}</p>
          </div>
        </div>
        <div>
          {doc?.docType === "ESIGN_ENVELOPE" && (
            <p>{html2plaintext(doc?.status)}</p>
          )}
        </div>
      </div>

      {doc && doc?.tagStrs && doc.tagStrs.length > 0 && (
        <div className={classes.iconTagCont}>
          <BsFillTagsFill className={classes.iconSty} />
          <div className={classes.tagContStyle}>
            {doc.tagStrs.map((tag, i) => (
              <div key={i} >#{tag}</div>
            ))}
          </div>
        </div>
      )}

      {((doc?.nationwide && doc.nationwide.length > 0) ||
        (doc?.stateTags && doc.stateTags.length > 0)) && (
        <div className={classes.iconTagCont} style={{ marginTop: "10px" }}>
          <FaMapMarkedAlt
            className={classes.iconSty}
            style={{ fontSize: "25px" }}
          />
          <div className={classes.locationTagContStyle}>
            {doc.nationwide.map((tag, i) => (
              <div key={i} >
                {tag}
                {i !== doc.nationwide.length - 1 && (
                  <BsDot style={{ fontSize: "19px", marginBottom: "-3px" }} />
                )}
              </div>
            ))}
            {doc.stateTags.map((tag, i) => (
              <div key={i} >
                {tag.split("_")[0]}
                {i !== doc.stateTags.length - 1 && (
                  <BsDot style={{ fontSize: "19px", marginBottom: "-3px" }} />
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {doc?.title && doc?.title.length > 0 && (
        <h3 className={classes.titleText}>
          {doc?.title ? doc?.title : "Untitled"}
        </h3>
      )}

      {doc?.description && doc?.description.length > 0 && (
        <div className={classes.bodyCont}>
          {doc?.description && (
            <>
              {ReactHtmlParser(
                doc?.description?.length > 0 ? doc?.description : ""
              )}
            </>
          )}
        </div>
      )}

      {doc?.files && doc?.files.length > 0 && (
        <>
          <h3 className={classes.sectionTitle}>File(s)</h3>
          {doc?.files && <AllFileViewers files={doc?.files} />}
        </>
      )}

      {user?._id && (
        <div className={classes.actionCont}>
          <div className={classes.leftActionCont}>
            <div className={classes.itemCont}>
              <IconButton onClick={createLike}>
                <BiLike
                  style={
                    isLiked
                      ? { color: theme.palette.primary.main, fontSize: "25px" }
                      : { fontSize: "25px" }
                  }
                />
              </IconButton>
              <IconButton
                style={{ marginRight: "10px" }}
                onClick={() => {
                  if (likeCount) {
                    setOpen(true);
                  }
                }}
                disabled={likeCount ? false : true}
              >
                <p style={{ cursor: "pointer" }}>{likeCount}</p>
              </IconButton>
            </div>
            <div className={classes.itemCont}>
              <IconButton
                onClick={() => {
                  setOpenComment(!openComment);
                }}
              >
                <FaRegCommentDots
                  style={
                    openComment
                      ? { color: theme.palette.primary.main, fontSize: "22px" }
                      : { fontSize: "22px" }
                  }
                />
              </IconButton>
              <IconButton disabled style={{ marginRight: "10px" }}>
                <p>{commentCount}</p>
              </IconButton>
            </div>
            <div className={classes.itemCont}>
              <IconButton
                onClick={() => {
                  createSave();
                }}
                style={{ marginRight: "10px" }}
              >
                <BookmarkIcon
                  style={
                    isSaved
                      ? { color: theme.palette.primary.main, fontSize: "24px" }
                      : { fontSize: "24px" }
                  }
                />
              </IconButton>
            </div>
          </div>
          <div
            className={classes.itemCont}
            style={{
              marginRight: "0px",
              borderRight: "none",
              paddingLeft: "8px",
              borderLeft: "1px solid #F4F4F4",
            }}
          >
            <IconButton
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <ShareIcon style={{ fontSize: "21px" }} />
            </IconButton>
          </div>
        </div>
      )}

      {openComment && (
        <SmartCommentList
          parent={doc?._id}
          parentModelName="Doc"
          limit={-1}
          commentAdded={commentAdded}
          sentProfile={user}
          guestView={false}
          styleBody={{ marginTop: "0" }}
        />
      )}

      <NormalDialog
        openDialog={isOpen}
        handleCloseShare={handleCloseDialog}
        pageTitle={"Share post on Social"}
        content={
          <div style={{ padding: "15px 40px" }}>
            <SocialShareBtn shareLink={shareLink} />
          </div>
        }
      />
    </Paper>
  );
}
