import React, { useState, useEffect } from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import SpaceBetween from "../styled/generic/SpaceBetween";
import FormBox from "../styled/generic/FormBox";
import { Box, Button, Typography } from "@material-ui/core";
import { Grid, IconButton } from "@mui/material";
import WorkspaceApp from "../styled/generic/WorkspaceApp";
import { Add, Sort } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";
import { setAuthCookieData } from "../../helpers/cookie.helper";
import { setPersistedState } from "../../helpers/statePersistence";
import { useSelector, useDispatch } from "react-redux";


const ProjectHomeWorkspace = ({ team, workspaceApps }) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const entireState = useSelector((state) => state);

  const handleAppClick =  (code) => {
    const token = localStorage.getItem("token");
    if (entireState && typeof entireState === 'object') {
      setAuthCookieData({
        user,token, entireState
      });
    } else {
      console.warn("Skipped state persistence due to invalid state");
    }

    let projectData = team?.parent;
    let queryId = projectData._id;
    let queryType = "project";
    let profileId = projectData?.profile?._id;
    let walletId = team?.wallet;
    let teamId = team?._id;
    let org = null;
    let projectId = projectData?._id;
    let url = ''
    let segments = ''
    let redirectedFromProject = null;
    let projectPath = ''
    switch (code) {
      case "ticketing":
        url = location.pathname;
        segments = url.split("/");
        redirectedFromProject = null;
        if (segments[1] === "project") {
          redirectedFromProject = segments[2];
        }
        projectPath = "https://teams.reallist.ai/issues/profile/" + profileId + redirectedFromProject ? `?redirectedFromProject=${redirectedFromProject}` : ""
        window.open(projectPath,
          "_self"
        );
        break;

      case "booking":
          window.open(`https://bookings.reallist.ai/booking/${profileId}`,
            "_self"
          );
          break;

      case "teams":

          url = location.pathname;
          segments = url.split("/");
          redirectedFromProject = null;
          if (segments[1] === "project") {
            redirectedFromProject = segments[2];
          }
          projectPath = "https://teams.reallist.ai/issues/profile/" + profileId + redirectedFromProject ? `?redirectedFromProject=${redirectedFromProject}` : ""
          window.open(projectPath,
          "_self"
        );
          break;
      case "planning":
        window.open(`https://planning.reallist.ai/planning/${profileId}`,
          "_self"
        );
        break;
      case "bugs":
        window.open(`https://bugs.reallist.ai/bugs/${profileId}`,
          "_self"
        );
        break;
      case "accounts":
        window.open(`https://accounts.reallist.ai/finance/${walletId}/bank-accounts`,
          "_self"
        );
        break;
      case "documents":
        window.open("https://docs.reallist.ai/docs/" + profileId,
          "_self"
        );
        break;
      case "events":
        // Code for Events //TODO
        break;

      case "timesheet":
        if (projectId && org?._id) {
          window.open(
            `https://timesheets.reallist.ai/organization/workschedule/${org?.team}?projectId=${projectId}`
          ,
          "_self"
        );
        } else {
          window.open(`https://timesheets.reallist.ai/organization/workschedule/${teamId}`,
          "_self"
        );
        }
        break;
      case "investment":
        // history.push("/investment/analysis/new");
        break;
      case "portfoliomanagement":
        // Code for Portfolio Management //TODO
        break;
      case "propertymanagement":
        // history.push("/propertymanagement/" + teamId);
        break;
      case "productservices":
        history.push(`/offerings-categories/management/${profileId}`);
        break;
      case "procurement":
        window.open(`https://operations.reallist.ai/procurements/management/${profileId}`,
        "_self"
        );
        break;
      case "estimation":
        window.open(`https://estimation.reallist.ai/budget-analysis/budget-costs/${profileId}`,
          "_self"
        );
        break;
      case "inventory":
        if (queryType === "project") {
          window.open(`https://inventory.reallist.ai/inventory?projectId=${queryId}`,
          "_self"
        );
        } else if (queryType === "organization") {
          window.open(`https://inventory.reallist.ai/inventory?organizationId=${queryId}`,
          "_self"
        );
        } else {
          window.open(`https://inventory.reallist.ai/inventory`,
          "_self"
        );
        }
        break;
      case "sitereport":
        window.open(`https://sitereport.reallist.ai/site-report/${profileId}`,
          "_self"
        );
        break;
      case "assets":
        window.open(`https://assets.reallist.ai/assets/home/${profileId}`,
          "_self"
        );
        break;

      case "approval":
        window.open(`https://approval.reallist.ai/approval/${profileId}`,
          "_self"
        );
        break;
        
      case "meeting":
        // history.push(`/meeting/${profileId}`);
        break;
      default:
        alert("No action found for this type of app");
        break;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <StandardAppContainerRounded>
          <SpaceBetween
            rightStyleProps={{ alignSelf: "flex-start" }}
            left={
              <FormBox label="Apps">
                {/* <Typography variant="subtitle1">
                  Configure your apps here
                </Typography> */}
              </FormBox>
            }
            right={
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                {/* <Button
                  startIcon={<Add />}
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => setShowWorkspaceConfigDialog(true)}
                >
                  Apps
                </Button> */}
              </Box>
            }
          />
          <Box>
            <Grid container spacing={2}>
              {workspaceApps?.map((item) => (
                <Grid item xs={3}>
                  <WorkspaceApp
                    isSelectable={false}
                    onClick={() => handleAppClick(item.code)}
                    appData={item}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </StandardAppContainerRounded>
      </Grid>
      <Grid item xs={3}>
        <StandardAppContainerRounded>
          <SpaceBetween
            left={<Typography variant="h6">Activities</Typography>}
            right={
              <Box display="flex" justifyContent="flex-end">
                <IconButton>
                  <Sort />
                </IconButton>
              </Box>
            }
          />
        </StandardAppContainerRounded>
      </Grid>
    </Grid>
  );
};

export default ProjectHomeWorkspace;
