import React, { useState, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { makeStyles } from "@material-ui/core/styles";
import { components } from "react-select";
import { Avatar, Button } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import { mainRoles } from "../../../helpers/contactHelper";
import Checkbox from "@material-ui/core/Checkbox";
import { ClickAwayListener } from "@material-ui/core";
import CreatePalDrawer from "../../AddMember/CreatePalDrawer";
import { getEntityByFilter, getProfileForEntitySearch } from "./api.call";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    position: "relative",
  },
  optionsMainCont: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
    },
    "& h5": {
      fontSize: "13px",
      fontWeight: "500",
      marginBottom: "-1",
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400",
    },
  },
  showFilter: {
    width: "100%",
    position: "absolute",
    zIndex: "999999",
    padding: "10px",
    top: "40px",
    right: "0px",
    opacity: "1",
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    justifyContent: "space-evenly",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    transition: "all .3s ease-in-out",
  },
  hideFilter: {
    width: "100%",
    position: "absolute",
    zIndex: "999999",
    padding: "10px",
    top: "40px",
    right: "0px",
    opacity: "0",
    backgroundColor: "white",
    display: "none",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    transition: "all .3s ease-in-out",
  },
  filterSideBar: {
    width: "105px",
    borderRight: "1px solid #d2d1d1",
  },
  filterTypesCont: {
    width: "calc(100% - 105px)",
    display: "flex",
    flexWrap: "wrap",
  },
  singleCheck: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      width: "19px",
      height: "19px",
    },
    "& .MuiIconButton-root": {
      padding: "0px",
    },
    "& p": {
      marginLeft: "5px",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  palBtnCont: {
    "& .MuiButton-root": {
      padding: "5px 10px",
      borderRadius: "5px",
    },
  },
  inputBoxCont: {
    position: "relative",
    "&:hover $refreshBtnNoFilter, &:hover $refreshBtnWithFilter": {
      opacity: 1,
    },
  },
  refreshBtnNoFilter: {
    position: "absolute",
    top: "8px",
    opacity: 0,
    right: "5px",
    fontSize: "22px",
    cursor: "pointer",
    zIndex: "9999999",
  },
  refreshBtnWithFilter: {
    position: "absolute",
    top: "8px",
    opacity: 0,
    right: "42px",
    fontSize: "22px",
    cursor: "pointer",
    zIndex: "9999999",
  },
}));

const SingleOption = ({ image, name, username }) => {
  const classes = useStyles();
  return (
    <div className={classes.optionsMainCont}>
      <Avatar
        src={image}
        alt={name}
        style={{ width: "25px", height: "25px" }}
      />
      <div style={{ paddingLeft: "10px" }}>
        <h5>{name}</h5>
        <p>{username}</p>
      </div>
    </div>
  );
};

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <SingleOption
        image={
          props?.data?.data?.parent?.displayPicture?.thumbUrl
            ? props?.data?.data?.parent?.displayPicture?.thumbUrl
            : props?.data?.data?.parent?.displayPicture?.url
        }
        name={props?.data?.data?.parent?.displayName}
        username={props?.data?.data?.parent?.username}
      />
    </components.Option>
  );
};

const defaultGivenOptions = [
  {
    option: "Contact",
    types: mainRoles,
  },
  {
    option: "Network",
    types: ["User", "Organization", "Project"],
  },
];

const PaginatedEntityDropdown = ({
  value,
  onChange,
  isMulti,
  curEntityId,
  entity,
  givenProfileIdArr = [],
  financialRelationType,
  orgtype,
  palCreate = true,
  disabled = false,
  givenFilterOption = defaultGivenOptions,
  noFilter = false,
}) => {
  const classes = useStyles();

  const dataLimit = 15;
  const [showFilter, setShowFilter] = useState(false);
  const [openPal, setOpenPal] = useState(false);
  const [profileIdArr, setProfileIdArr] = useState([]);
  const [mainProfile, setMainProfile] = useState(null);
  const [mainProfileWitParent, setMainProfileWithParent] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const [key, setKey] = useState(0);
  const [curPage, setCurPage] = useState(0);
  const [filterOption, setFilterOption] = useState([]);

  //to configure filters
  useEffect(() => {
    console.log(givenFilterOption);
    const finalOptions = givenFilterOption.map((fo, i) => {
      if (i === 0) {
        const finalTypes = fo?.types
          ? fo.types.map((lt, index) => {
              if (
                (fo?.option === "Network" && index === 0) ||
                fo?.option !== "Network"
              ) {
                return {
                  label: lt,
                  selected: true,
                };
              } else {
                return {
                  label: lt,
                  selected: false,
                };
              }
            })
          : [];
        return {
          _id: i,
          label: fo?.option,
          selected: true,
          isOpen: true,
          types: finalTypes,
        };
      } else {
        const finalTypes = fo?.types
          ? fo.types.map((lt) => {
              return {
                label: lt,
                selected: false,
              };
            })
          : [];
        return {
          _id: i,
          label: fo?.option,
          selected: false,
          isOpen: false,
          types: finalTypes,
        };
      }
    });

    console.log(finalOptions);

    setFilterOption(finalOptions);
  }, [givenFilterOption]);

  //to configure profiles to get contact from there
  useEffect(() => {
    if (curEntityId && entity) {
      getProfileForEntitySearch({
        entityId: curEntityId,
        type: entity,
      })
        .then((data) => {
          const profiles = data?.profiles || [];
          const currentProfile = data?.currentProfile;
          const currentProfileWithParent = data?.currentProfileWithParent;

          let locProfileIdArr = new Set([...givenProfileIdArr, ...profiles]);
          setMainProfile(currentProfile);
          setProfileIdArr([...locProfileIdArr]);
          setMainProfileWithParent(currentProfileWithParent);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [curEntityId, entity]);

  //load data
  const loadOptions = async (search, loadedOptions, { page }) => {
    const selectedOption = filterOption.filter((s) => s?.selected)[0];
    let currentEntity = "";
    let roleTypes = [];
    if (selectedOption?.label === "Network") {
      currentEntity =
        selectedOption.types.filter((s) => s?.selected).length > 0
          ? selectedOption.types.filter((s) => s?.selected)[0]?.label
          : "";
    } else {
      currentEntity = "Contact";
      selectedOption.types.map((s) => {
        if (s?.selected) {
          roleTypes.push(s?.label);
        }
      });
    }

    const resOptions = await getEntityByFilter({
      currentEntity: currentEntity,
      currentTypes: roleTypes,
      searchString: search,
      profileIds: profileIdArr,
      page: curPage === 0 ? 0 : search === searchStr ? page : 0,
      limit: dataLimit,
    });

    setSearchStr(search);
    setCurPage(page + 1);
    const options = [];
    resOptions.map((s) => {
      if (s?._id && s?.parent?.displayName) {
        options.push({
          value: s?._id,
          label: s?.parent?.displayName,
          data: s,
        });
      }
    });

    return {
      options: options,
      hasMore: resOptions.length === dataLimit ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  //to reload options on filter change
  useEffect(() => {
    setCurPage(0);
    setKey((prevKey) => prevKey + 1);
  }, [filterOption]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowFilter(false);
      }}
    >
      <div className={classes.mainCont}>
        <div
          style={
            palCreate && !disabled
              ? { width: "calc(100% - 80px)" }
              : { width: "100%" }
          }
          className={classes.inputBoxCont}
        >
          <AsyncPaginate
            key={key}
            value={value}
            loadOptions={loadOptions}
            onChange={onChange}
            isMulti={isMulti}
            isDisabled={disabled}
            style={{ width: "100%" }}
            placeholder={"Select..."}
            additional={{
              page: 0,
            }}
            getOptionLabel={(option) => (
              <SingleOption
                image={
                  option?.data?.parent?.displayPicture?.thumbUrl
                    ? option?.data?.parent?.displayPicture?.thumbUrl
                    : option?.data?.parent?.displayPicture?.url
                }
                name={option?.data?.parent?.displayName}
                username={option?.data?.parent?.username}
              />
            )}
            components={{
              Option: CustomOption,
              DropdownIndicator: noFilter
                ? null
                : () => {
                    if (showFilter) {
                      return (
                        <CloseIcon
                          onClick={() => {
                            setShowFilter(false);
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "35px",
                            padding: "0px 8px",
                          }}
                        />
                      );
                    } else {
                      return (
                        <TuneIcon
                          onClick={() => {
                            setShowFilter(true);
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "35px",
                            padding: "0px 8px",
                          }}
                        />
                      );
                    }
                  },
            }}
          />
          <RefreshIcon
            className={
              noFilter
                ? classes.refreshBtnNoFilter
                : classes.refreshBtnWithFilter
            }
            onClick={() => {
              setCurPage(0);
              setKey((prevKey) => prevKey + 1);
            }}
          />
        </div>
        {palCreate && !disabled && (
          <div className={classes.palBtnCont}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                setShowFilter(false);
                setOpenPal(true);
              }}
            >
              New
            </Button>
            <CreatePalDrawer
              openPal={openPal}
              setOpenPal={setOpenPal}
              selectEntityFunction={(newOne) => {
                const restructureObj = {
                  value: newOne?._id,
                  label: newOne?.parent?.displayName,
                  data: newOne,
                };
                if (isMulti) {
                  if (value && value.length > 0) {
                    onChange([...value, restructureObj]);
                  } else {
                    onChange([restructureObj]);
                  }
                } else {
                  onChange(restructureObj);
                }
              }}
              financialRelationType={financialRelationType}
              mainProfileWitParent={mainProfileWitParent}
              profileIdArr={profileIdArr}
              orgtype={orgtype}
            />
          </div>
        )}
        <div className={showFilter ? classes.showFilter : classes.hideFilter}>
          <div className={classes.filterSideBar}>
            {filterOption &&
              filterOption.map((locOption, inx) => (
                <div
                  className={classes.singleCheck}
                  style={{
                    width: "100%",
                    borderBottom: "1px solid #d2d1d1",
                  }}
                  onClick={() => {
                    let arr = filterOption.map((s, jk) => {
                      if (jk === inx) {
                        return {
                          ...s,
                          isOpen: true,
                        };
                      } else {
                        return {
                          ...s,
                          isOpen: false,
                        };
                      }
                    });
                    setFilterOption(arr);
                  }}
                >
                  <Checkbox
                    checked={locOption?.selected}
                    onChange={(e, checked) => {
                      const arr = filterOption.map((s, i) => {
                        if (i === inx) {
                          let typesArr = s?.types
                            ? s.types.map((tp, t) => {
                                if (
                                  (s?.label === "Network" && t === 0) ||
                                  s?.label !== "Network"
                                ) {
                                  return {
                                    ...tp,
                                    selected: true,
                                  };
                                } else {
                                  return {
                                    ...tp,
                                    selected: false,
                                  };
                                }
                              })
                            : [];
                          return {
                            ...s,
                            selected: true,
                            isOpen: true,
                            types: typesArr,
                          };
                        } else {
                          let typesArr = s?.types
                            ? s.types.map((tp) => {
                                return {
                                  ...tp,
                                  selected: false,
                                };
                              })
                            : [];
                          return {
                            ...s,
                            selected: false,
                            isOpen: false,
                            types: typesArr,
                          };
                        }
                      });
                      setFilterOption(arr);
                    }}
                    color="primary"
                  />
                  <p>{locOption?.label}</p>
                </div>
              ))}
          </div>
          <div className={classes.filterTypesCont}>
            {filterOption.filter((s) => s?.isOpen).length > 0 &&
              filterOption
                .filter((s) => s?.isOpen)[0]
                ?.types.map((type, ik) => (
                  <div className={classes.singleCheck} style={{ width: "50%" }}>
                    <Checkbox
                      checked={type?.selected}
                      onChange={(e, checked) => {
                        let curTypes = [];
                        if (
                          filterOption.filter((s) => s?.isOpen)[0]?.label ===
                          "Network"
                        ) {
                          curTypes = filterOption
                            .filter((s) => s?.isOpen)[0]
                            ?.types.map((s, jj) => {
                              if (jj === ik) {
                                return {
                                  ...s,
                                  selected: true,
                                };
                              } else {
                                return {
                                  ...s,
                                  selected: false,
                                };
                              }
                            });
                        } else {
                          curTypes = filterOption
                            .filter((s) => s?.isOpen)[0]
                            ?.types.map((s, jj) => {
                              if (jj === ik) {
                                return {
                                  ...s,
                                  selected: !type?.selected,
                                };
                              } else {
                                return s;
                              }
                            });
                        }
                        let arr = [...filterOption];
                        arr[
                          filterOption.filter((s) => s?.isOpen)[0]?._id
                        ].types = curTypes;
                        setFilterOption(arr);
                      }}
                      color="primary"
                    />
                    <p>{type?.label}</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default PaginatedEntityDropdown;
