import React, { useEffect, useState } from "react";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import Select from "../../../styled/generic/Select";
import { ControlPointOutlined, Delete } from "@material-ui/icons";
import Api from "../../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";

const CreateProjectBlockDrawer = ({
  open,
  setOpen,
  projectId,
  onCreate,
  setProjectBlocks,
  setRentalUnitCount,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [blockName, setBlockName] = useState("");
  const [floorNumber, setFloorNumber] = useState(0);
  const [description, setDescription] = useState("");
  const unitTypes = ["1BHK", "2BHK", "3BHK", "4BHK", "5BHK", "6BHK", "7BHK"];
  const [floorData, setFloorData] = useState([]);
  const createFloorsAndUnits = () => {
    if (floorNumber <= 0) {
      return;
    }
    let newAry = [];
    for (let i = 0; i <= floorNumber; i++) {
      newAry.push({
        floor: i,
        units: [{ unitType: "2BHK", unitValue: 0 }],
      });
    }
    setFloorData(newAry);
  };

  useEffect(() => {
    createFloorsAndUnits();
  }, [floorNumber]);

  const createProjectBlockAndUnits = async () => {
    try {
      if (floorNumber <= 0) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please add atleast one floor",
          },
        });
        return;
      }
      // console.log({
      //   projectId: projectId,
      //   userId: user?._id,
      //   name: blockName,
      //   description: description,
      //   rentalUnits: floorData,
      //   isRental: true,
      // });
      // return;

      const { projectBlocks, rentalUnits } = await Api.post(
        "/public/project/block/create",
        {
          projectId: projectId,
          userId: user?._id,
          name: blockName,
          description: description,
          rentalUnits: floorData,
          isRental: true,
        }
      );
      //console.log("daadaaaaaa", projectBlocks, rentalUnits?.length);
      if (projectBlocks && rentalUnits) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Project Block Created Successfully",
          },
        });
        setProjectBlocks(projectBlocks);
        setRentalUnitCount(rentalUnits?.length);
        setOpen(false);
        // if (onCreate) {
        //   onCreate(data);
        // }
      }
    } catch (error) {
      console.log("jjjjjjjjjjj", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to Create Project Block",
        },
      });
    }
  };

  return (
    <DrawerContainer title="Add Block" open={open} setOpen={setOpen}>
      <Box>
        <FormBox label="Block Name">
          <TextField
            value={blockName}
            onChange={(evt) => setBlockName(evt.target.value)}
            fullWidth
            placeholder="Name"
          />
        </FormBox>
        <FormBox label="Total Floor">
          <TextField
            value={floorNumber}
            onChange={(evt) => setFloorNumber(evt.target.value)}
            fullWidth
            type="number"
          />
        </FormBox>
        <FormBox label="Description">
          <TextField
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
            fullWidth
            multiline
            rows={4}
            placeholder="Enter Description"
            inputProps={{ maxLength: 200 }}
            helperText={`${description.length}/200`}
          />
        </FormBox>
        <Box sx={{ mb: 3 }}>
          {floorData?.map((item, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flex="0.1" display="flex" alignSelf="flex-start">
                <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Floor {item?.floor}:
                </span>
              </Box>

              <Box flex="0.9" alignSelf="flex-start">
                {item?.units?.map((unit, i) => (
                  <Box display="flex" sx={{ px: 2, mb: 2 }}>
                    <Select
                      fullWidth
                      value={unit?.unitType}
                      onChange={(e) => {
                        let newAry = [...floorData];
                        let newUnits = [...newAry[index].units];
                        newUnits[i].unitType = e.target.value;
                        newAry[index].units = newUnits;
                        setFloorData(newAry);
                      }}
                      sx={{ mr: 2 }}
                    >
                      {unitTypes.map((unitType, i) => (
                        <MenuItem key={i} value={unitType}>
                          {unitType}
                        </MenuItem>
                      ))}
                    </Select>

                    <TextField
                      fullWidth
                      placeholder="Enter Units"
                      type="number"
                      value={unit?.unitValue}
                      onChange={(e) => {
                        let newAry = [...floorData];
                        let newUnits = [...newAry[index].units];
                        newUnits[i].unitValue = e.target.value;
                        newAry[index].units = newUnits;
                        setFloorData(newAry);
                      }}
                      sx={{ mr: 2 }}
                    />

                    <IconButton
                      color="primary"
                      onClick={() => {
                        let newAry = [...floorData];
                        let newUnits = [...newAry[index].units];
                        newUnits.push({ unitType: "2BHK", unitValue: 0 });
                        newAry[index].units = newUnits;
                        setFloorData(newAry);
                      }}
                      sx={{ mr: 2 }}
                    >
                      <ControlPointOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        let newAry = [...floorData];
                        let newUnits = [...newAry[index].units];
                        if (newUnits?.length <= 1) {
                          return;
                        }
                        newUnits.splice(i, 1);
                        newAry[index].units = newUnits;
                        setFloorData(newAry);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            sx={{ width: 120 }}
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2, width: 120 }}
            onClick={() => createProjectBlockAndUnits()}
          >
            Add
          </Button>
        </Box>
      </Box>
    </DrawerContainer>
  );
};

export default CreateProjectBlockDrawer;
