import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { Avatar, IconButton, Button } from "@material-ui/core"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer"; 
import { useGetAccessOptionWithStucther } from './AccessControl.Hook';
import LinearProgress from "@material-ui/core/LinearProgress";
import { addParticipants, updateParticipant } from "./api.call";
import { createAndUpdateFinalcialRelation } from "../styled/CommonComponents/api.call";
import ParticipantAddBody from "./Participant.Add.Body";
import PaginatedEntityDropdown from "../styled/CommonComponents/PaginatedEntityDropdown";

const useStyles = makeStyles((theme) => ({
    mainBodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        padding: "0px 10px 20px",
        overflowY: "auto",
    },
    usersCont: {
        width: "100%",
        height: "100px",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "10px",
        border: `2px dashed ${theme.palette.primary.main}`,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        "&:hover": {
            backgroundColor: "#ececec",
        }
    },
    bottomCont: {
        width: "100%",
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 10px"
    },
    lableSty: {
        fontSize: "16px",
        fontWeight: "500",
        marginBottom: "5px",
        marginTop: "15px",
    },
    mainTreeCont: {
        width: "100%",
        maxHeight: "calc(100% - 150px)",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: "#f9f9f9",
        border: "1px solid #dfdfdf",
        padding: "10px 15px",
        borderRadius: "5px"
    },
    selectedParticipantsCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#f9f9f9",
        padding: "5px 5px 0px",
        border: "1px solid #dfdfdf",
        "& .MuiButton-root": {
            height: "38px",
            borderRadius: "5px",
            marginBottom: "5px"
        }
    },
    selectedUserOrgSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        borderRadius: "5px",
        border: "1px solid #dcdcdc",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    }
}));

const ParticipantAddDrawer = ({
    openParticipantDrawer, setOpenParticipantDrawer, accessRoles,
    team, afterParticipantsAdd, participantEditObj, exisitingProfileIds
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const { accessOptions, setAccessOptions, idObjectMap, setIdObjectMap } = useGetAccessOptionWithStucther(team?.parent?.profile?._id);

    const [selectedRoles, setSelectedRoles] = useState([])
    const [selectedAcccessIds, setSelectedAcccessIds] = useState([])
    const [oldSelectedAcccessIds, setOldSelectedAcccessIds] = useState([])
    const [selectedParticipants, setSelectedParticipants] = useState([])
    const [loader, setLoader] = useState(false)
    const [financialRelationType, setFinancialRelationType] = useState("")
    const [openParticipantSelectDrawer, setOpenParticipantSelectDrawer] = useState(false)

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    const setValues = async (givenValue) => {
        setSelectedRoles(givenValue?.rolePermissions || [])
        setFinancialRelationType(givenValue?.mainRole)
        await timeout(2000);
        let locPermissions = givenValue?.permissions || [];
        let locSelectedOptionIds = [];
        locPermissions.map((item) => {
            locSelectedOptionIds.push(item.identifier)
            if (item?.fields && item.fields.length > 0) {
                let arr = []
                item.fields.map((fItem) => {
                    arr.push(`${item.identifier}-${fItem}`);
                })
                locSelectedOptionIds = [...locSelectedOptionIds, ...arr]
            }
        })
        let newSet = new Set([...locSelectedOptionIds])
        setOldSelectedAcccessIds([...newSet])
        setSelectedAcccessIds([...newSet])
    }

    useEffect(() => {
        if (openParticipantDrawer) {
            if (participantEditObj?._id) {
                setValues(participantEditObj)
            } else {
                setSelectedRoles([])
                setSelectedAcccessIds([])
                setOldSelectedAcccessIds([])
                setSelectedParticipants([])
                setLoader(false)
                setFinancialRelationType("")
                setOpenParticipantSelectDrawer(false)
            }
        }
    }, [participantEditObj, openParticipantDrawer])

    const onParticipantAddCall = async () => {
        if (participantEditObj?._id || (selectedParticipants && selectedParticipants.length > 0 && selectedAcccessIds && selectedAcccessIds.length > 0)) {
            if (participantEditObj?._id) {
                setLoader(true)
                let newAccessArr = []
                const oldPermissions = participantEditObj?.permissions || [];
                //newly added identifier
                let toBeAdded = selectedAcccessIds.filter(id => !oldSelectedAcccessIds.includes(id))
                //removed identifier
                let toBeDeleted = oldSelectedAcccessIds.filter(id => !selectedAcccessIds.includes(id))

                let exisitingIds = []
                let exisitingIdentifier = []
                let toBeDeletedIds = []
                let existingIdentifierIdMap = {}
                let updatedObjectArr = []
                oldPermissions.map((permission) => {
                    if (!toBeDeleted.includes(permission?.identifier)) {
                        exisitingIds.push(permission?._id)
                        exisitingIdentifier.push(permission?.identifier)
                        existingIdentifierIdMap[permission?.identifier] = permission?._id;
                    } else {
                        toBeDeletedIds.push(permission?._id)
                    }
                })

                exisitingIdentifier.map((_id) => {
                    const curObj = idObjectMap[_id]
                    if (curObj?.action && !curObj?.field) {
                        let children = curObj?.children;
                        let fArr = []
                        children.map((it) => {
                            if (selectedAcccessIds.includes(it?._id)) {
                                fArr.push(it?.title)
                            }
                        })

                        updatedObjectArr.push({
                            updateOne: {
                                filter: { _id: existingIdentifierIdMap[curObj?._id] },
                                update: {
                                    $set: {
                                        fields: fArr
                                    }
                                }
                            }
                        })
                    }
                })

                toBeAdded.map((_id) => {
                    if (!idObjectMap[_id]?.field) {
                        const curObj = idObjectMap[_id]
                        let newObj = {
                            module: curObj?.module,
                            team: team?._id,
                            createdBy: user?.profile,
                            createdAt: new Date()
                        }
                        if (curObj?.resource) {
                            newObj.resource = curObj?.resource
                        }
                        if (curObj?.action) {
                            newObj.action = curObj?.action
                        }
                        if (curObj?._id) {
                            newObj.identifier = curObj?._id
                        }
                        if (curObj?.action && !curObj?.field) {
                            let children = curObj?.children;
                            let fArr = []
                            children.map((it) => {
                                if (selectedAcccessIds.includes(it?._id)) {
                                    fArr.push(it?.title)
                                }
                            })
                            newObj.fields = fArr
                        }
                        if (!curObj?.field) {
                            newAccessArr.push(newObj)
                        }
                    }
                })

                let pRoleIds = [];
                selectedRoles.map((selectPRole) => {
                    pRoleIds.push(selectPRole?._id)
                })

                const reqObj = {
                    newAccess: newAccessArr,
                    toBeDeletedIds,
                    _id: participantEditObj?._id,
                    updatedObjectArr: updatedObjectArr,
                    exisitingIds,
                    mainRole: financialRelationType,
                    rolePermissions: pRoleIds,
                    updateBy: user?.profile
                }
                const relObj = {
                    mainProfile: team?.parent?.profile?._id,
                    relationProfileIds: [participantEditObj?.profile?._id],
                    notOnPlatformProfileIds: [],
                    type: financialRelationType ? financialRelationType : "Customer",
                    parent: team?.parent?._id,
                    parentModelName: team?.parentModelName,
                    addedBy: user?.profile,
                    user: user?._id,
                };


                await createAndUpdateFinalcialRelation(relObj)
                    .then(async (resData) => {
                        await updateParticipant(reqObj)
                            .then((data) => {
                                setLoader(false)
                                setOpenParticipantDrawer(false)
                                if (afterParticipantsAdd) {
                                    afterParticipantsAdd(data?.updatedParticipant, true)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                                setLoader(false)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoader(false)
                    })
            } else {
                setLoader(true)
                let newAccessArr = []
                selectedAcccessIds.map((_id) => {
                    if (idObjectMap[_id] != null && idObjectMap[_id] != undefined) {
                        const curObj = idObjectMap[_id]
                        let newObj = {
                            module: curObj?.module,
                            team: team?._id,
                            createdBy: user?.profile,
                            createdAt: new Date()
                        }
                        if (curObj?.resource) {
                            newObj.resource = curObj?.resource
                        }
                        if (curObj?.action) {
                            newObj.action = curObj?.action
                        }
                        if (curObj?._id) {
                            newObj.identifier = curObj?._id
                        }
                        if (curObj?.action && !curObj?.field) {
                            let children = curObj?.children;
                            let fArr = []
                            children.map((it) => {
                                if (selectedAcccessIds.includes(it?._id)) {
                                    fArr.push(it?.title)
                                }
                            })
                            newObj.fields = fArr
                        }
                        if (!curObj?.field) {
                            newAccessArr.push(newObj)
                        }
                    }
                })

                let participantProfiles = [];
                let participantProfileIds = [];
                selectedParticipants.map((p) => {
                    const profile = p?.data
                    if (profile?.parentModelName !== "OutSider") {
                        participantProfileIds.push(profile?._id)
                        participantProfiles.push({
                            _id: profile?._id,
                            parentModelName: profile?.parentModelName,
                            reciverFirstName: profile?.parent?.displayName ? profile.parent.displayName.split(" ")[0] : "",
                            senderName: user?.displayName,
                            teamName: team?.parent?.displayName,
                            reciverMail: profile?.parent?.email,
                            reciverFullName: profile?.parent?.displayName,
                            notifyThrough: "email"
                        })
                    } else {
                        participantProfiles.push({
                            _id: profile?._id,
                            parentModelName: profile?.parentModelName,
                            reciverFirstName: "",
                            senderName: user?.displayName,
                            teamName: team?.parent?.displayName,
                            reciverMail: profile?.parent?.email,
                            reciverFullName: "",
                            notifyThrough: profile?.parent?.through
                        })
                    }
                })

                const roleAccessIds = selectedRoles.map((roleAccess) => roleAccess?._id)
                const reqObj = {
                    participantProfiles,
                    teamId: team?._id,
                    mainRole: financialRelationType,
                    roleAccessIds,
                    addedBy: user?.profile,
                    newAccess: newAccessArr,
                    teamParentModel: team?.parentModelName,
                    teamProfileId: team?.parent?.profile?._id,
                    needToSendInvitation: true
                }
                const relObj = {
                    mainProfile: team?.parent?.profile?._id,
                    relationProfileIds: participantProfileIds,
                    notOnPlatformProfileIds: [],
                    type: financialRelationType ? financialRelationType : "Customer",
                    parent: team?.parent?._id,
                    parentModelName: team?.parentModelName,
                    addedBy: user?.profile,
                    user: user?._id,
                };

                await createAndUpdateFinalcialRelation(relObj)
                    .then(async (resData) => {
                        await addParticipants(reqObj)
                            .then((data) => {
                                setLoader(false)
                                setOpenParticipantDrawer(false)
                                if (afterParticipantsAdd) {
                                    afterParticipantsAdd([], false)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                                setLoader(false)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    return (
        <NormalDrawer
            openDrawer={openParticipantDrawer}
            setOpenDrawer={setOpenParticipantDrawer}
            anchor={"right"}
            width={"40vw"}
            title={participantEditObj && participantEditObj?._id ? "Edit Participant" : "Add New Participant"}
            content={<div className={classes.mainBodyCont} >
                <div className={classes.bodyCont} >
                    {participantEditObj && participantEditObj?._id ? (<>
                        <p className={classes.lableSty} >Participant</p>
                        <div className={classes.selectedUserOrgSty} >
                            <Avatar
                                src={participantEditObj?.profile?.parent?.displayPicture?.thumbUrl ? participantEditObj?.profile?.parent?.displayPicture?.thumbUrl : participantEditObj?.profile?.parent?.displayPicture?.url}
                                alt={participantEditObj?.profile?.parent?.displayName}
                            />
                            <div style={{ marginLeft: "5px" }} >
                                <h3>{participantEditObj?.profile?.parent?.displayName}</h3>
                                <p>{participantEditObj?.profile?.parent?.username}</p>
                            </div>
                        </div>
                    </>) : (<>
                        <p className={classes.lableSty} >Participant(s)</p>
                        <PaginatedEntityDropdown
                            value={selectedParticipants}
                            onChange={(value) => {
                                setSelectedParticipants(value);
                            }}
                            isMulti={true}
                            entity={team?.parentModelName}
                            curEntityId={team?.parent?._id}
                            palCreate={false}
                            noFilter={true}
                            givenFilterOption={[
                                {
                                    option: "Network",
                                    types: ["User"]
                                }
                            ]}
                        />
                    </>)}
                    <ParticipantAddBody
                        accessRoles={accessRoles}
                        financialRelationType={financialRelationType}
                        setFinancialRelationType={setFinancialRelationType}
                        selectedAcccessIds={selectedAcccessIds}
                        setSelectedAcccessIds={setSelectedAcccessIds}
                        selectedRoles={selectedRoles}
                        setSelectedRoles={setSelectedRoles}
                        accessOptions={accessOptions}
                        isFullScreen={false}
                    />
                </div>
                <div style={{ width: "100%", height: "5px" }} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bottomCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={onParticipantAddCall}
                        disabled={participantEditObj?._id ? false : selectedParticipants && selectedParticipants.length > 0 && selectedAcccessIds && selectedAcccessIds.length > 0 ? false : true}
                    >
                        Save
                    </Button>
                </div>
            </div>}
        />
    );
};

export default ParticipantAddDrawer;