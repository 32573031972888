import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";

import MentionOutput from "../styled/mention.output";
import { Box } from "@material-ui/core";
import { Typography } from "@mui/material";

export default function ActivityCard(props) {
  const { actId, type, size } = props;

  const activityReducer = useSelector((state) => state.activity);
  const { activityDictionary } = activityReducer;

  const notification = activityDictionary[actId];
  const activity = notification?.activity;
  const history = useHistory();
  const user = activity?.profile?.parent;

  const { displayName = "Test", displayPicture } = user || {};

  const handleNotificationClick = (notification) => {
    let type = notification?.activity?.parentModelName;
    let id = notification?.activity?.parent?._id;
    let dataId = notification?.activity?.data?._id;
    switch (type) {
      case "WBS": {
        history.push(`/planning/task/${id}`);
        break;
      }
      case "Bug": {
        history.push(`/bugs/bug/${id}`);
        break;
      }
      case "Issue": {
        history.push(`/issue/view/${id}`);
        break;
      }
      case "TimeSheetItem": {
        history.push(`/my-work/${dataId}`);
        break;
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        p: 1,
        borderRadius: "0.5rem",
        cursor: "pointer",
        "&: hover": {
          backgroundColor: "#F9FAFC",
        },
        position: "relative",
      }}
      onClick={() => handleNotificationClick(notification)}
    >
      {notification?.seen == false && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#2D76E0",
          }}
        ></Box>
      )}
      <Box
        sx={{
          alignSelf: "flex-start",
        }}
      >
        <Avatar
          alt={displayName}
          src={displayPicture?.thumbUrl}
          sx={{
            height: "0.8rem",
            width: "0.8rem",
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          marginLeft: "0.8rem",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: "bold",
          }}
        >
          {activity?.title}
        </Typography>
        {activity?.body && (
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "light",
            }}
            color="text.secondary"
          >
            {activity?.body}
          </Typography>
        )}
        <Typography variant="subtitle2" color="primary">
          {moment(activity?.createdAt).fromNow()}
        </Typography>
      </Box>
    </Box>
  );
}

// import React from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
// import Paper from "@material-ui/core/Paper";
// import Avatar from "@material-ui/core/Avatar";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
// import EditIcon from "@material-ui/icons/Edit";
// import moment from "moment";

// import MentionOutput from "../styled/mention.output";

// /**
//  * Type - Me,
//  *
//  */

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flex: 1,
//     width: (props) => {
//       if (props.size == "xs") {
//         return "14rem";
//       } else if (props.size == "sm") {
//         return "15.5rem";
//       } else {
//         return "18rem";
//       }
//     },
//     display: "flex",
//     flexDirection: "row",
//     padding: "0.5rem",
//   },
//   row: {
//     flex: 1,
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "row",
//     alignItems: "row",
//   },

//   dP: {
//     height: "1.5rem",
//     width: "1.5rem",
//     marginRight: "0.5rem",
//   },
//   notifyText:{
//     marginLeft:'2rem',
//     fontWeight:'600',
//     fontSize:'0.8rem',
//     color:'#2e73f8'
//   }
// }));

// export default function ActivityCard(props) {
//   const { actId, type, size } = props;

//   const activityReducer = useSelector((state) => state.activity);
//   const { activityDictionary } = activityReducer;

//   const notification = activityDictionary[actId];
//   const activity = notification?.activity
//   const history = useHistory();
//   const classes = useStyles({ size });
//   const user = activity?.profile?.parent;

//   const { displayName = "Test", displayPicture } = user || {};

//   return (
//     <Paper className={classes.root} variant="outlined" square>
//       <div className={classes.textBox}>
//         <div className={classes.row}>
//           <Avatar
//             alt={displayName}
//             src={displayPicture?.thumbUrl}
//             className={classes.dP}
//           />
//           <div className={classes.row}>
//             <Typography>{activity?.title}</Typography>
//           </div>
//         </div>

//         <Typography className={classes.notifyText}>
//            {moment(activity?.createdAt).fromNow()}
//         </Typography>
//       </div>
//     </Paper>
//   );
// }
