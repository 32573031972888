const { default: Api } = require('../../helpers/Api');

export const createLibrary = async (obj) => {
    try {
        const resData = await Api.post("create/library", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getPublicLibrary = async (obj) => {
    try {
        const resData = await Api.post("library/getPublicLibrary", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const cloneLibrary = async (obj) => {
    try {
        const resData = await Api.post("library/cloneLibraryInExisting", obj);
        const resultData = resData?.success;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getLibraryCategoriesBasicData = async (obj) => {
    try {
        const resData = await Api.post("get/libraries-categories", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createCostCodeCategory = async (obj) => {
    try {
        const resData = await Api.post("create/cost/code/category", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createOffering = async (obj) => {
    try {
        const resData = await Api.post("/create/product/service", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getOfferingsByCategories = async (obj) => {
    try {
        const resData = await Api.post("/get/offerings/by-category", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getOfferingsByLibrary = async (obj) => {
    try {
        const resData = await Api.post("/get/offerings/by-library", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getOfferingsByLibraryPagination = async (obj) => {
    try {
        const resData = await Api.post("/get/offerings/pagination/with-filter", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getOfferingsByLibraryAndSearchStr = async (obj) => {
    try {
        const resData = await Api.post("/get/library/offering/by/libraryId-searchStr", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getWalletAndChartAccountsByProfile = async (obj) => {
    try {
        const resData = await Api.post("/get/wallet/chart-account/by-profile", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getCategoriesByLibrary = async (obj) => {
    try {
        const resData = await Api.post("/get/categories/by-library", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateLibraryById = async (obj) => {
    try {
        const resData = await Api.post("/update/library-by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteOfferingAndRelation = async (obj) => {
    try {
        const resData = await Api.post("/delete/offering/relation/by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateOfferingAndRelation = async (obj) => {
    try {
        const resData = await Api.post("/update/offering/relation/by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteCostCodeCategory = async (obj) => {
    try {
        const resData = await Api.post("/delete/cost-code-category/by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteLibrarayById = async (obj) => {
    try {
        const resData = await Api.post("/delete/library/by-id", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateCostCodeCategory = async (obj) => {
    try {
        const resData = await Api.post("/update/cost-code/category/by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getUserOrganizationProject = async (obj) => {
    try {
        const resData = await Api.post("/get/users/organizations/projects/by-user", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getLibrariesByProfileId = async (obj) => {
    try {
        const resData = await Api.post("/get/libraries/by-profile", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const importLibrariesByProfile = async (obj) => {
    try {
        const resData = await Api.post("/import/libraries/by-profile", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getLibraryAndCategoryByLibraryId = async (obj) => {
    try {
        const resData = await Api.post("/get/library/offering/by/libraryId", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getdiscountAndTaxByLibraryId = async (obj) => {
    try {
        const resData = await Api.post("/get/discountortaxandrelation/by-library", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteDiscountOrTaxAndRelation = async (obj) => {
    try {
        const resData = await Api.post("delete/discountortaxandrelation", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createTermsAndCondition = async (obj) => {
    try {
        const resData = await Api.post("create/termsandconditions", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};


export const getTermsAndConditions = async (obj) => {
    try {
        const resData = await Api.post("get/termsandconditions/pagination", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};


export const updateTermsAndCondition = async (obj) => {
    try {
        const resData = await Api.post("update/termsandconditions/by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getApprovalTemplatesPagination = async (obj) => {
    try {
        const resData = await Api.post("approval/templates/get/pagination", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteTemplateDeeply = async (obj) => {
    try {
        const resData = await Api.post("approval/template/deep-delete", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};