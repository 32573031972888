import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  TextField,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  Tooltip,
  Menu,
  MenuItem,
  Box,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch, useSelector } from "react-redux";
import { IoIosMore, IoMdTrash } from "react-icons/io";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Api from "../../../helpers/Api";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import VerifyEmailDialog from "../Dialogs/VerifyEmailDialog";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e4e7e7",
    padding: "0px 25px",
    "& h3": {
      fontSize: "17px",
      fontWeight: "450",
      color: "#170f49",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 10px",
      "& h3": {
        width: "150px",
      },
    },
  },
  body: {
    width: "100%",
    height: "calc(100% - 65px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  editCont: {
    width: "100%",
    padding: "30px 45px 80px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      padding: "15px 10px 30px",
      flexDirection: "column",
    },
  },
  inputConts: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  roleAndCategoryCont: {
    width: "35%",
    paddingTop: "185px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingTop: "15px",
    },
  },
  profilePicCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "15px",
    },
  },
  profilePic: {
    width: "150px",
    height: "150px",
  },
  actionBtn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
  },
  coverPicCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
  },
  editCoverButton: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: "white",
    "&:hover": {
      backgroundColor: "#076cbc",
    },
  },
  coverPic: {
    width: "250px",
    height: "150px",
  },
  picturesCont: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "35px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  editProfileButton: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: "white",
    "&:hover": {
      backgroundColor: "#076cbc",
    },
  },
  profileUploadButton: {
    width: "150px",
    "& p": {
      textTransform: "capital",
    },
  },
  fullNameCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  nameInput: {
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  bioSty: {
    width: "100%",
    marginBottom: "20px",
  },
  addressCont: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  labelStyle: {
    fontSize: "14px",
    fontWeight: "500",
    padding: "5px 0px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

const columns = [
  { id: "email", label: "Email", minWidth: 270 },
  {
    id: "isVerified",
    label: "Verification Status",
    minWidth: 120,
    valueGetter: (value, row) => `${value ? "verified" : "unverified"}`,
  },
];

export default function BusinessEmail({ setOpenEditView }) {
  const regex = /@([a-zA-Z0-9.-]+)\./;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [personalEmail, setPersonalEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [businessEmail, setBusinessEmail] = useState("");
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState({});
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmail(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setPersonalEmail(user?.email || "");
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeEmail = (event) => {
    setBusinessEmail(event.target.value);
    if (!regex.test(businessEmail)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    setAnchorEl(null);
    const data = await Api.delete(
      `user/deletebusinessemail/${selectedEmail._id}`
    );
    if (data) {
      setEmails((prev) =>
        prev.filter((email) => email._id !== selectedEmail._id)
      );
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Email deleted successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error deleting email",
        },
      });
    }
    setLoading(false);
  };

  const handleDefault = async () => {
    setLoading(true);
    setAnchorEl(null);
    const data = await Api.patch(
      `user/changedefaultemail/${selectedEmail._id}`,
      {
        userId: user._id,
      }
    );
    if (data) {
      dispatch({
        type: "SET_AUTH_USER",
        user: data?.user,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Default Business Email updated successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while changing default business email",
        },
      });
    }
    setLoading(false);
  };

  const handleSubmitEmail = async () => {
    if (!regex.test(businessEmail)) {
      setError(true);
      return;
    }
    setLoading(true);
    const data = await Api.post("user/addbusinessemail", {
      email: businessEmail,
      userId: user._id,
    });
    if (data) {
      setEmails((prev) => [...prev, data.businessEmail]);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Email added successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while adding email",
        },
      });
    }

    setBusinessEmail("");
    setLoading(false);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      const data = await Api.get(`user/getemails/${user._id}`);
      if (data) {
        setEmails(data.emails);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Error while fetching emailsy",
          },
        });
      }
    };
    fetchEmails();
  }, [user._id, isOpen]);
  return (
    <>
      <div className={classes.header}>
        <h3>{isSmall ? "Business Email" : "Edit Business Email"}</h3>
        <div>
          <IconButton
            style={{ marginLeft: "10px" }}
            onClick={() => setOpenEditView(false)}
            size={isSmall ? "small" : "medium"}
          >
            <ClearIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ height: "5px" }}>{loading && <LinearProgress />}</div>
      <div className={classes.body}>
        <div className={classes.editCont}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <div className={classes.nameInput}>
              <p className={classes.labelStyle}>Personal Email Id</p>
              <TextField
                id="outlined-basic"
                placeholder="Enter mail id"
                variant="outlined"
                size="small"
                value={personalEmail}
                style={{ width: "100%" }}
                disabled
              />
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <div className={classes.nameInput}>
                <p className={classes.labelStyle}>Business Email Id</p>
                <TextField
                  id="email-input"
                  placeholder="Enter business mail id"
                  variant="outlined"
                  size="small"
                  value={businessEmail}
                  onChange={handleChangeEmail}
                  style={{ width: "100%" }}
                  error={error}
                  helperText={error ? "Incorrect email." : ""}
                />
              </div>
              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                sx={{
                  height: "fit-content",
                  width: "fit-content",
                }}
                size={isSmall ? "small" : "medium"}
                onClick={handleSubmitEmail}
              >
                Add Email
              </LoadingButton>
            </Box>
            {emails.length > 0 && (
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {emails
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id === "isVerified" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {value ? (
                                        <>
                                          <FaCheckCircle
                                            style={{
                                              color: "green",
                                              marginRight: "8px",
                                            }}
                                          />
                                          Verified
                                        </>
                                      ) : (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "2px",
                                            }}
                                          >
                                            <FaTimesCircle
                                              style={{
                                                color: "red",
                                                marginRight: "8px",
                                              }}
                                            />
                                            Unverified
                                          </Box>
                                          <Button
                                            variant="text"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                              setSelectedEmail(row);
                                              setIsOpen(true);
                                            }}
                                          >
                                            Verify
                                          </Button>
                                        </Box>
                                      )}
                                    </div>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                            <TableCell align="right">
                              {user.defaultBusinessEmail === row.email && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FaCheckCircle
                                    style={{
                                      color: "green",
                                      marginRight: "8px",
                                    }}
                                  />
                                  Default business email
                                </Box>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={(event) => handleClick(event, row)}
                                  size="small"
                                  sx={{ ml: 2, color: "primary" }}
                                  aria-controls={
                                    open ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <IoIosMore style={{ color: "blue" }} />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem
                                  onClick={handleDefault}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "15px",
                                  }}
                                >
                                  <IoMdTrash
                                    style={{
                                      marginRight: "8px",
                                      fontSize: "15px",
                                    }}
                                  />
                                  <span>Make email default</span>
                                </MenuItem>
                                <MenuItem
                                  onClick={handleDelete}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "15px",
                                  }}
                                >
                                  <IoMdTrash
                                    style={{
                                      marginRight: "8px",
                                      fontSize: "15px",
                                    }}
                                  />
                                  <span>Delete Email</span>
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={emails.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </Box>
        </div>
      </div>
      <VerifyEmailDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        businessEmail={selectedEmail}
      />
    </>
  );
}
