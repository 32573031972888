import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, Button, IconButton, useMediaQuery } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@material-ui/core/TextField';
import GooglePlaceAutocomplete from '../../styled/CommonComponents/Google.Place.Auto';
import CategoryAutocomplete from '../../styled/CommonComponents/CategoryAutocomplate';
import AddLocationTag from '../../styled/CommonComponents/Add.Location.Tag';
import CustomFileUploadButton from '../../file/Uploader/CustomFileUploadButton';
import { updateUserInfo } from '../api';
import profilePicDefault  from "../../../Assets/Profile_pic_default.svg"
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as yup from "yup";
import { getUserCallForUsernameCheck, updateProfileData } from '../../preLogin/api.call';
import { useDebounce } from "react-use";

const usernameSchema = yup.object().shape({
    username: yup.string().max(100, 'username should have less then 100 characters.').min(5, 'username should be 5 characters minimum.').required("username should be required")
});

const useStyles = makeStyles((theme) => ({
    header: {
        height: "60px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e4e7e7",
        padding: "0px 25px",
        "& h3": {
            fontSize: "17px",
            fontWeight: "450",
            color: "#170f49"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px",
            "& h3": {
                width: "150px"
            },
        }
    },
    body: {
        width: "100%",
        height: "calc(100% - 65px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
    },
    editCont: {
        width: "100%",
        padding: "30px 45px 80px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            padding: "15px 10px 30px",
            flexDirection: "column",
        }
    },
    inputConts: {
        width: "60%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        }
    },
    roleAndCategoryCont: {
        width: "35%",
        paddingTop: "185px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            paddingTop: "15px",
        }
    },
    profilePicCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "15px"
        }
    },
    profilePic: {
        width: "150px",
        height: "150px",
    },
    actionBtn: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "25px"
    },
    coverPicCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
    },
    editCoverButton: {
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        bottom: "0px",
        right: "0px",
        color: "white",
        "&:hover": {
            backgroundColor: "#076cbc"
        }
    },
    coverPic: {
        width: "250px",
        height: "150px",
    },
    picturesCont: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "35px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
        }
    },
    editProfileButton: {
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        bottom: "0px",
        right: "0px",
        color: "white",
        "&:hover": {
            backgroundColor: "#076cbc"
        }
    },
    profileUploadButton: {
        width: "150px",
        "& p": {
            textTransform: "capital"
        }
    },
    fullNameCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        }
    },
    nameInput: {
        width: "47%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    bioSty: {
        width: "100%",
        marginBottom: "20px"
    },
    addressCont: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    labelStyle: {
        fontSize: "14px",
        fontWeight: "500",
        padding: "5px 0px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "12px",
        }
    },

}));


export default function BasicInfoEdit({ setOpenEditView }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth);
    const { createdFileIds } = useSelector((state) => state.file);

    const [fullAddressLine, setFullAddressLine] = useState("")
    const [streetAddress, setStreetAddress] = useState("")
    const [zip, setZip] = useState("")
    const [city, setCity] = useState("")
    const [region, setRegion] = useState("")
    const [regionCode, setRegionCode] = useState("")
    const [country, setCountry] = useState("India(IN)")
    const [countryCode, setCountryCode] = useState("in")
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [marketList, setMarketList] = useState([]);
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [username, setUsername] = useState("")
    const [bio, setBio] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [about, setAbout] = useState("")
    const [profilePic, setProfilePic] = useState(null)
    const [coverPic, setCoverPic] = useState(null)
    const [loading, setLoading] = useState(false)
    const [usernameCheckLoading, setUsernameCheckLoading] = useState(false)
    const [usernameValidationObj, setUsernameValidationObj] = useState(null)
    const [isValidUsername, setIsValidUsername] = useState(true);

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        if (user?.displayPicture?.url) {
            setProfilePic(user?.displayPicture)
        } else {
            setProfilePic(null)
        }

        if (user?.cover?.url) {
            setCoverPic(user?.cover)
        } else {
            setCoverPic(null)
        }

        setAbout(user?.about)
        setBio(user?.description)

        let fullName = user?.displayName || ""
        let firstName = user?.firstName
        let lastName = user?.lastName
        let strArr = fullName.split(" ")
        let strFirstName = strArr[0]
        let strLastName = strArr[1] || "";
        for (let i = 2; i < strArr.length; i++) {
            strLastName = strLastName + " " + strArr[i]
        }
        strLastName = strLastName.replace(/\s+/g, ' ').trim()
        setFirstName((firstName?.length>2)?firstName:strFirstName)
        setLastName((firstName?.length>2)?lastName: strLastName)
        setUsername(user?.username || "")

        let entireList = user?.marketsList || [];
        let newList = [];

        entireList.map((ele) => {
            let address_line1 = ele?.address_line1;
            let address_line2 = ele?.address_line2;
            let name = ele?.name;
            let city = ele?.city;
            let country = ele?.country;
            let country_code = ele?.country_code;
            let lat = ele?.loc?.coordinates?.[1];
            let lon = ele?.loc?.coordinates?.[0];
            let state = ele?.state;
            let state_code = ele?.state_code;
            let postcode = ele?.postcode;
            let key = `${lat}$${lon}`;
            newList.push({
                key: key,
                label: name,
                address: {
                    address_line1,
                    address_line2,
                    name,
                    city,
                    country,
                    country_code,
                    lat,
                    lon,
                    state,
                    state_code,
                    postcode,
                },
            });
        });
        setMarketList(newList);


        let skillStrs = user?.skills || []
        let skillIds = user?.skillTags || []
        let objArr = []

        if (skillStrs && skillIds && skillIds.length !== 0 && skillIds.length === skillStrs.length) {
            skillStrs.map((str, i) => {
                objArr.push({
                    name: str,
                    _id: skillIds[i]
                })
            })
            setSelectedCategories(objArr)
        }
        let countryText = user?.address?.country
        let lowerCode = "us"
        if (countryText && countryText.includes("(")) {
            let len = countryText.length;
            let code = countryText.substring(len - 3, len - 1)
            if (code) {
                lowerCode = code.toLowerCase()
            }
        }
        setCountry(user?.address?.country || "India(IN)")
        setCountryCode(lowerCode || "in")
        setRegion(user?.address?.region || "")
        setRegionCode(user?.address?.regionCode || "")
        setZip(user?.address?.zip || "")
        setCity(user?.address?.city || "")
        setFullAddressLine(user?.address?.line1 || "")
        setStreetAddress(user?.address?.streetAddress || "")
        setLatitude(user?.latitude || "")
        setLongitude(user?.longitude || "")
        setEmail(user?.email || "")
        setPhone(user?.phone || "")
    }, [user])

    const setUploadedProfilePicture = async () => {
        setLoading(true)
        const userObj = { displayPicture: createdFileIds[0] }
        await updateUserInfo({
            userId: user?._id,
            userInfo: userObj,
        })
            .then((data) => {
                setProfilePic(data?.displayPicture)

                const obj = {
                    displayPicture: data?.displayPicture,
                }

                dispatch({
                    type: "AddAuth",
                    payload: {
                        user: {
                            ...user,
                            ...obj
                        },
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
        dispatch({ type: "FileUploadReset" });
    }

    const setUploadedCoverPicture = async () => {
        setLoading(true)
        const userObj = { cover: createdFileIds[0] }
        await updateUserInfo({
            userId: user?._id,
            userInfo: userObj,
        })
            .then((data) => {
                setCoverPic(data?.cover)

                const obj = {
                    cover: data?.cover,
                }

                dispatch({
                    type: "AddAuth",
                    payload: {
                        user: {
                            ...user,
                            ...obj,
                        },
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
        dispatch({ type: "FileUploadReset" });
    }

    const updateProfileSection = async () => {
        setLoading(true)
        let strArr = new Set();
        let strArrId = new Set();
        let entireList = [];
        let marketsTags = [];

        marketList.map((ele) => {
            let address_line1 = ele?.address?.address_line1;
            let address_line2 = ele?.address?.address_line2;
            let name = ele?.label;
            let city = ele?.address?.city;
            let country = ele?.address?.country;
            let country_code = ele?.address?.country_code;
            let lat = ele?.address?.lat;
            let lon = ele?.address?.lon;
            let state = ele?.address?.state;
            let state_code = ele?.address?.state_code;
            let postcode = ele?.address?.postcode;
            entireList.push({
                address_line1,
                address_line2,
                name,
                city,
                country,
                country_code,
                loc: {
                    type: "Point",
                    coordinates: [lon, lat],
                },
                state,
                state_code,
                postcode,
            });

            if (ele?.address?.state) {
                let str = `${ele?.address?.state}_${ele?.address?.country}`
                marketsTags.push(str)
            } else {
                let str = `${ele?.address?.country}_${ele?.address?.country}`
                marketsTags.push(str)
            }
        });

        selectedCategories.map((str) => {
            strArr.add(str?.name);
            strArrId.add(str?._id)
        })

        let fullName = firstName
        if (firstName && lastName) {
            fullName = firstName + " " + lastName
        } else if (firstName) {
            fullName = firstName
        } else if (lastName) {
            fullName = lastName
        }

        const userObj = {
            location: {
                type: "Point",
                coordinates: [latitude || 0, longitude || 0],
                cityName: city || "",
                countryName: country || "",
                stateName: region || "",
                zipCode: zip || 0,
            },
            latitude: latitude || "",
            longitude: longitude || "",
            zip: zip || "",
            description: bio || "",
            about: about || "",
            email: email || "",
            phone: phone || "",
            locationCountry: country || "",
            locationCity: city || "",
            displayName: fullName,
            firstName,
            lastName,
            skills: [...strArr],
            skillTags: [...strArrId],
            marketsList: entireList,
            markets: [...marketsTags],
            address: {
                line1: fullAddressLine || "",
                postal_code: zip || "",
                city: city || "",
                state: region || "",
                country: country || "",
                streetAddress: streetAddress || "",
                zip: zip || "",
                region: region || "",
                regionCode: regionCode || "",
            },
        };


        await updateUserInfo({
            userId: user?._id,
            userInfo: userObj,
        })
            .then((data) => {

                dispatch({
                    type: "AddAuth",
                    payload: {
                        user: {
                            ...user,
                            ...userObj,
                        },
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        setUsernameValidationObj(null)
    }, [username])

    useDebounce(
        async () => {
            await usernameSchema.validate({ username: username }, { abortEarly: false })
                .then(async (data) => {
                    await handleUsernameOnChange()
                })
                .catch((err) => {
                    let errorArr = err?.inner
                    let obj = {}
                    errorArr.map((err) => {
                        obj[err?.path] = err?.message
                    })
                    setUsernameValidationObj(obj)
                })
        },
        1500,
        [username]
    );

    const updateUsernameApiHit = async () => {
        const userObj = { username: username }
        await updateUserInfo({
            userId: user?._id,
            userInfo: userObj,
        })
            .then(async (data) => {
                console.log(data);
                dispatch({
                    type: "AddAuth",
                    payload: {
                        user: {
                            ...user,
                            ...userObj
                        },
                    },
                });

                await updateProfileData({
                    _id: user?.profile,
                    username: username
                })
                    .then((data1) => {
                        console.log("data1")
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleUsernameOnChange = async () => {
        setUsernameCheckLoading(true)
        await getUserCallForUsernameCheck({ username: username })
            .then(async (data) => {
                if (data && data.length === 0) {
                    setIsValidUsername(true);
                    await updateUsernameApiHit();
                  }
                  else if (data && data.length === 1 && user?.username === username) {
                    setIsValidUsername(true);
                    await updateUsernameApiHit();
                  } else {
                    setIsValidUsername(false);
                  }
            })
            .catch((err) => {
                console.log(err)
            })
        setUsernameCheckLoading(false)
    }

    return (
        <>
            <div className={classes.header} >
                <h3>{isSmall ? "Basic Information" : "Edit Basic Information"}</h3>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        // startIcon={<EditIcon />}
                        onClick={updateProfileSection}
                        size={isSmall ? "small" : "medium"}
                    >
                        Save
                    </Button>
                    <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => { setOpenEditView(false) }}
                        size={isSmall ? "small" : "medium"}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            <div style={{ height: "5px" }} >
                {loading && (<LinearProgress />)}
            </div>
            <div className={classes.body} >
                <div className={classes.editCont} >
                    <div className={classes.inputConts} >
                        <div className={classes.picturesCont} >
                            {profilePic?.url ? (<div className={classes.profilePicCont} >
                                <Avatar src={profilePic?.url} className={classes.profilePic} />
                                <CustomFileUploadButton
                                    showComponent={
                                        <IconButton className={classes.editProfileButton} >
                                            <EditIcon />
                                        </IconButton>
                                    }
                                    parentType={"User"}
                                    parentId={user?._id}
                                    fileNum={1}
                                    givenFileTypes={["image"]}
                                    givenMaxSize={26214400}
                                    closeFunCall={setUploadedProfilePicture}
                                />
                            </div>) : (<div className={classes.profilePicCont} >
                                <Avatar src={profilePicDefault} className={classes.profilePic} />
                                <CustomFileUploadButton
                                    showComponent={
                                        <IconButton className={classes.editProfileButton} >
                                            <EditIcon />
                                        </IconButton>
                                    }
                                    parentType={"User"}
                                    parentId={user?._id}
                                    fileNum={1}
                                    givenFileTypes={["image"]}
                                    givenMaxSize={26214400}
                                    closeFunCall={setUploadedProfilePicture}
                                />
                            </div>)}
                            {coverPic?.url ? (<div className={classes.coverPicCont} >
                                <Avatar variant="square" src={coverPic?.url} className={classes.coverPic} />
                                <CustomFileUploadButton
                                    showComponent={
                                        <IconButton className={classes.editCoverButton} >
                                            <EditIcon />
                                        </IconButton>
                                    }
                                    parentType={"User"}
                                    givenFileTypes={["image"]}
                                    parentId={user?._id}
                                    fileNum={1}
                                    givenMaxSize={26214400}
                                    closeFunCall={setUploadedCoverPicture}
                                />
                            </div>) : (<div className={classes.coverPicCont} >
                                <PhotoSizeSelectActualIcon className={classes.coverPic} />
                                <CustomFileUploadButton
                                    showComponent={
                                        <IconButton className={classes.editCoverButton} >
                                            <EditIcon />
                                        </IconButton>
                                    }
                                    parentType={"User"}
                                    givenFileTypes={["image"]}
                                    parentId={user?._id}
                                    fileNum={1}
                                    givenMaxSize={26214400}
                                    closeFunCall={setUploadedCoverPicture}
                                />
                            </div>)}
                        </div>

                        <div className={classes.fullNameCont} >
                            <div className={classes.nameInput} >
                                <p className={classes.labelStyle} >First Name</p>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Enter first name"
                                    variant="outlined"
                                    size="small"
                                    value={firstName}
                                    onChange={(e) => { setFirstName(e.target.value); }}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className={classes.nameInput} >
                                <p className={classes.labelStyle} >Last Name</p>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Enter last name"
                                    size="small"
                                    value={lastName}
                                    onChange={(e) => { setLastName(e.target.value); }}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>


                        <p className={classes.labelStyle} >{isValidUsername ? "Username" : <span style={{ color: "red" }} >Username already exists</span>}</p>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter username"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {usernameCheckLoading && (
                                            <CircularProgress
                                                size={27}
                                                thickness={3}
                                            />
                                        )}
                                    </InputAdornment>),
                            }}
                            value={username}
                            onChange={(e) => { setUsername(e.target.value); }}
                            className={classes.bioSty}
                            style={{ marginBottom: "0px" }}
                        />
                        <div style={{ height: "10px", width: "100%", marginBottom: "15px" }} >
                            {usernameValidationObj?.username && (<p className={classes.warningSty} >{usernameValidationObj?.username}</p>)}
                        </div>

                        <div className={classes.fullNameCont} >
                            <div className={classes.nameInput} >
                                <p className={classes.labelStyle} >Mail Id</p>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Enter mail id"
                                    variant="outlined"
                                    size="small"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); }}
                                    style={{ width: "100%" }}
                                    disabled
                                />
                            </div>
                            <div className={classes.nameInput} >
                                <p className={classes.labelStyle} >Phone Number</p>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Enter phone number"
                                    size="small"
                                    value={phone}
                                    onChange={(e) => { setPhone(e.target.value); }}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>

                        <p className={classes.labelStyle} >Bio</p>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Add bio"
                            size="small"
                            value={bio}
                            onChange={(e) => { setBio(e.target.value); }}
                            className={classes.bioSty}
                        />

                        <p className={classes.labelStyle} >About</p>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={5}
                            variant="outlined"
                            placeholder="Tell something about you"
                            size="small"
                            value={about}
                            onChange={(e) => { setAbout(e.target.value); }}
                            className={classes.bioSty}
                        />

                        <div className={classes.bioSty} >
                            <GooglePlaceAutocomplete
                                inputContStyle={classes.addressCont}
                                autoWidth={"100%"}
                                textWidth={"100%"}
                                isGetLogLat={true}
                                fullAddressLine={fullAddressLine}
                                setFullAddressLine={setFullAddressLine}
                                streetAddress={streetAddress}
                                setStreetAddress={setStreetAddress}
                                zip={zip}
                                setZip={setZip}
                                city={city}
                                setCity={setCity}
                                region={region}
                                setRegion={setRegion}
                                regionCode={regionCode}
                                setRegionCode={setRegionCode}
                                country={country}
                                setCountry={setCountry}
                                countryCode={countryCode}
                                setCountryCode={setCountryCode}
                                latitude={latitude}
                                setLatitude={setLatitude}
                                longitude={longitude}
                                setLongitude={setLongitude}
                                isShowCountry={true}
                                isShowCityStreet={true}
                                isStateZip={true}
                                noAddressShow={true}
                                noMap={true}
                            />
                        </div>
                    </div>
                    <div className={classes.roleAndCategoryCont} >
                        <p className={classes.labelStyle} >Add Roles</p>
                        <div className={classes.bioSty} >
                            <CategoryAutocomplete
                                categories={selectedCategories}
                                setCategories={setSelectedCategories}
                                placeholder={"Search Roles"}
                            />
                        </div>

                        <p className={classes.labelStyle} >Add Market(s)</p>
                        <div className={classes.bioSty} >
                            <AddLocationTag
                                marketList={marketList}
                                setMarketList={setMarketList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}