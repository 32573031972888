const { default: Api } = require("../../helpers/Api");

export const addPhotosToProject = async (obj) => {
    try {
        const propertyType = await Api.post("public/project/addFiles", obj);
        const data = propertyType?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getFullProjectData = async (obj) => {
    try {
        const propertyType = await Api.post("project/get/data/for/dashboard", obj);
        const data = propertyType?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getParticipantsPermissionsForTeam = async (obj) => {
    try {
        const resData = await Api.post("get/team/participants-permissions", obj);
        const result = resData?.data;
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getPopulatedTeamDataByIdForOrg = async (obj) => {
    try {
        const resData = await Api.post("get/team/by-id/for-org", obj);
        const result = resData?.data;
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateProjectDataWithPopulated = async (obj) => {
    try {
      const res = await Api.post("/project/update/withpopulate", obj);
      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

export const getPopulatedTeamDataByIdForProject = async (obj) => {
    try {
        const resData = await Api.post("get/team/by-id/for-project", obj);
        const result = resData?.data;
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateAccessRole = async (obj) => {
    try {
        const resData = await Api.post("accessrole/update", obj);
        const result = resData?.data;
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getTeamInvites = async (obj) => {
    try {
        const resData = await Api.post("invite/getTeamInvites", obj);
        const result = resData?.invites;
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createProjectBlock = async (obj) => {
    try {
        const resData = await Api.post("public/project/block/create", obj);
        const result = resData || {};
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const inviteAccept = async (obj) => {
    try {
        const resData = await Api.post("invite/accept", obj);
        const result = resData || null;
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};