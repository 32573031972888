import React, { useEffect, useState } from "react";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { Box, Slider } from "@mui/material";

import DocumentUploader from "../../../styled/generic/DocumentUploader";
import DocViewer from "../../../styled/generic/DocViewer";
import Api from "../../../../helpers/Api";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import { getProgressColor } from "../../../../helpers/colorHelper";

import dayjs from "dayjs";
import { Typography } from "@material-ui/core";
import Dialog from "../../../styled/generic/Dialog";

const TaskProgressUpdateDrawer = ({
  open,
  setOpen,
  setTasks,
  setTask,
  taskToUpdate,
}) => {
  const history = useHistory();
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const [workDoneSoFar, setWorkDoneSoFar] = useState(0);
  const [workDoneToday, setWorkDoneToday] = useState(0);
  const [comment, setComment] = useState("");
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [workLeft, setWorkLeft] = useState(0);
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [taskProccessValues, setTaskProccessValues] = useState([0, 0]);
  const [removeFile, setRemoveFile] = useState({
    taskId: taskToUpdate?._id,
    removeFilesIds: [],
  });
  const [selectedProgressHistory, setSelectedProgressHistory] = useState();
  const [showProgressHistoryDialog, setShowProgressHistoryDialog] =
    useState(false);
  const [selectedProgressHistoryFiles, setSelectedProgressHistoryFiles] =
    useState([]);

  const handleUpdateTask = (task) => {
    setWorkDoneSoFar(task?.progress || 0);
    let _workLeft = 100 - (task?.progress || 0);
    if (_workLeft < 0) _workLeft = 0;
    if (_workLeft > 100) _workLeft = 100;
    setWorkLeft(_workLeft);
    setComment(task?.comment || "");
  };

  const handleTaskUpdate = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    setTaskProccessValues([taskToUpdate?.progress, newValue[1]]);
    setWorkDoneSoFar(newValue[1]);
    setWorkDoneToday(newValue[1] - taskToUpdate?.progress);
    setWorkLeft(100 - newValue[1]);
  };

  useEffect(() => {
    setTaskProccessValues([taskToUpdate?.progress, taskToUpdate?.progress]);
    if (taskToUpdate?._id) {
      handleUpdateTask(taskToUpdate);
    }
  }, [taskToUpdate]);

  const trackProgress = async () => {
    try {
      setLoadingPrimary(true);
      const { data } = await Api.post("/wbs/track-progress", {
        wbsId: taskToUpdate?._id,
        progress: workDoneSoFar,
        files: filesArr,
        removeFile: removeFile,
        previousProgress: workDoneToday,
        comment: comment,
      });
      setWorkDoneToday(0);

      if (data) {
        if (setTasks) {
          setTasks((prev) => {
            let _prev = [...prev];
            let index = _prev.findIndex((m) => m._id === taskToUpdate?._id);
            _prev[index].progress = workDoneSoFar;
            _prev[index].comment = comment;
            _prev[index].files = filesArr;
            return _prev;
          });
        }
        if (setTask) {
          setTask(data?.wbs);
        }
        setLoadingPrimary(false);
        setOpen(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task updated successfully",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while updating progress",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while updating progress",
        },
      });
    }
  };

  useEffect(() => {
    let _docs = selectedProgressHistory?.docs || [];
    let _files = [];
    for (let i = 0; i < _docs.length; i++) {
      let _doc = _docs[i];
      let _docFiles = _doc?.files || [];
      for (let j = 0; j < _docFiles.length; j++) {
        let _docFile = _docFiles[j];
        if (!_docFile) {
          continue;
        }
        _files.push(_docFile);
      }
    }

    setSelectedProgressHistoryFiles(_files);
  }, [selectedProgressHistory]);

  return (
    <DrawerContainer open={open} setOpen={setOpen} title="Update Task">
      <SpaceBetween
        left={
          <FormBox label="Work Done Today">
            <TextField
              placeholder="Enter"
              type="number"
              value={workDoneToday}
              readOnly={true}
              fullWidth={true}
            />
          </FormBox>
        }
        right={
          <FormBox label="Work Left">
            <TextField
              placeholder="Enter"
              type="number"
              value={workLeft}
              readOnly={true}
              fullWidth={true}
            />
          </FormBox>
        }
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {/* <Slider
          value={
            // workDoneSoFar > taskToUpdate?.progress
            //   ? workDoneSoFar
            //   : taskToUpdate?.progress
            taskToUpdate?.progress
          }
          // onChange={(e, value) => {
          //   if (value < Number(taskToUpdate?.progress)) {
          //     return;
          //   } else {
          //     setWorkDoneSoFar(value);
          //     setWorkDoneToday(value - taskToUpdate?.progress);
          //     setWorkLeft(100 - value);
          //   }
          // }}
          min={taskToUpdate?.progress - 10}
          max={taskToUpdate?.progress}
          valueLabelDisplay="auto"
        /> */}
        {/* workDoneSoFar > taskToUpdate?.progress
              ? workDoneSoFar
              : taskToUpdate?.progress, */}

        {/* <Slider
          value={[
            taskToUpdate?.progress,
            workDoneSoFar + 1,
            // workDoneSoFar,
            // workDoneToday > taskToUpdate?.progress
            //   ? workDoneToday
            //   : taskToUpdate?.progress,
          ]}
          onChange={(e, value) => {
            if (value < Number(taskToUpdate?.progress)) {
              return;
            } else {
              setWorkDoneSoFar(value);
              setWorkDoneToday(value - taskToUpdate?.progress);
              setWorkLeft(100 - value);
            }
          }}
          valueLabelDisplay="auto"
          min={0}
          max={100}
        /> */}
        <Slider
          sx={{
            height: "0.6em",
            color: "success.main",
            "& .MuiSlider-thumb": {
              width: "1.3rem",
              height: "1.3rem",
            },
          }}
          value={taskProccessValues}
          onChange={handleTaskUpdate}
          valueLabelDisplay="auto"
          min={0}
          max={100}
          disableSwap
        />
      </Box>
      <FormBox label="Comments" sx={{ mt: 2 }}>
        <TextField
          placeholder="Enter"
          type="text"
          fullWidth={true}
          multiline={true}
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </FormBox>

      {/* <FormBox label="Attachments">
        <DocumentUploader
          documents={filesArr}
          onUpload={() => {
            setFileIds(createdFileIds);
            let data = [];
            for (let i = 0; i < createdFileIds?.length; i++) {
              data.push(fileDictionary[createdFileIds[i]]);
            }
            setFilesArr(data);
          }}
          onDelete={(dataId) => {
            setFileIds(fileIds.filter((m) => m !== dataId));
            setFilesArr(files.filter((m) => m._id !== dataId));
          }}
          parentId={taskToUpdate?._id}
          parentType="WBS"
        />
        <Box>
          {filesArr.length > 0 ? (
            <Box>
              <DocViewer
                data={filesArr}
                isDeletable={true}
                disableEditMetaData={true}
                disableShowMetaData={true}
                onMetadataChange={() => {}}
                deleteFunction={(delId) => {}}
              />
            </Box>
          ) : (
            <Box>No documents found</Box>
          )}
        </Box>
      </FormBox> */}
      <FormBox label="Attachments">
        <DocumentUploader
          documents={filesArr}
          onUpload={() => {
            setFileIds(createdFileIds);

            let data = [];
            for (let i = 0; i < createdFileIds?.length; i++) {
              data.push(fileDictionary[createdFileIds[i]]);
            }
            let setValues = new Set([...filesArr, ...data]);
            setFilesArr(Array.from(setValues));
          }}
          onDelete={(dataId) => {
            setRemoveFile({
              taskId: taskToUpdate?._id,
              removeFilesIds: [...removeFile.removeFilesIds, dataId],
            });
            setFileIds(fileIds.filter((m) => m !== dataId));
            setFilesArr(filesArr.filter((m) => m._id !== dataId));
          }}
        />

        <Box>
          {filesArr?.length > 0 ? (
            <Box>
              <DocViewer
                data={filesArr}
                isDeletable={true}
                disableEditMetaData={true}
                disableShowMetaData={true}
                onMetadataChange={() => {}}
                deleteFunction={(delId) => {
                  setRemoveFile({
                    taskId: taskToUpdate?._id,
                    removeFilesIds: [...removeFile.removeFilesIds, delId],
                  });
                  setFileIds(fileIds.filter((m) => m !== delId));
                  setFilesArr(filesArr.filter((m) => m._id !== delId));
                }}
              />
            </Box>
          ) : (
            <Box>No documents found</Box>
          )}
        </Box>
      </FormBox>
      <DuoButtonGroup
        primaryButtonText="Update"
        primaryButtonListener={() => trackProgress()}
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => setOpen(false)}
        secondaryButtonColor="error"
        hideSecondary={!taskToUpdate?._id}
        loadingPrimary={loadingPrimary}
        loadingSecondary={loadingSecondary}
      />
      <Box>
        <StandardAppContainerRounded>
          <Box>
            {taskToUpdate?.progressHistory?.length > 0 ? (
              <Box>
                {taskToUpdate?.progressHistory
                  ?.filter((item) => item !== null)
                  .map((progress, index) => (
                    <Box
                      mt={2}
                      sx={{
                        backgroundColor: "#F3F3F3",
                        borderRadius: "10px",
                        cursor: "pointer",
                        p: 2,
                      }}
                      onClick={() => {
                        setSelectedProgressHistory(progress);
                        setShowProgressHistoryDialog(true);
                      }}
                    >
                      <BoxSpaceBetween>
                        <Box>
                          <Typography variant="subtitle1">
                            PROGRESS UPDATE
                          </Typography>
                          <Typography variant="subtitle2">
                            {dayjs(progress?.createdAt).format(
                              "DD MMM YYYY, hh:mm a"
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            backgroundColor: getProgressColor(
                              progress?.progress
                            )?.backgroundColor,
                            color: getProgressColor(progress?.progress)?.color,
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            p: 1,
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            Work Done
                          </span>
                          <span>{progress?.progress}/100</span>
                        </Box>
                      </BoxSpaceBetween>
                    </Box>
                  ))}
              </Box>
            ) : (
              <Box sx={{ p: 1 }}>No progress history found</Box>
            )}
          </Box>
        </StandardAppContainerRounded>

        <Dialog
          open={showProgressHistoryDialog}
          setOpen={setShowProgressHistoryDialog}
          title="Progress Update"
        >
          <Box sx={{ p: 3 }}>
            <FormBox label="Task">
              <Typography variant="subtitle1">
                {taskToUpdate?.taskName || "Untitled"}
              </Typography>
            </FormBox>
            <FormBox label="Comments">
              <Typography variant="body1">
                {selectedProgressHistory?.comments || "No comments"}
              </Typography>
            </FormBox>
            <FormBox label="Work Done">
              <Typography variant="body1">
                {selectedProgressHistory?.progress || 0}%
              </Typography>
            </FormBox>
            <FormBox label="Work Left">
              <Typography variant="body1">
                {100 - (selectedProgressHistory?.progress || 0)}%
              </Typography>
            </FormBox>
            <FormBox label="Date">
              <Typography variant="body1">
                {dayjs(selectedProgressHistory?.createdAt).format(
                  "DD MMM YYYY, hh:mm a"
                )}
              </Typography>
            </FormBox>
            <FormBox label="Documents">
              <DocViewer
                closeIcon={false}
                data={selectedProgressHistoryFiles}
                isDeletable={false}
                disableEditMetaData={true}
                disableShowMetaData={true}
                onMetadataChange={() => {}}
                deleteFunction={() => {}}
              />
            </FormBox>
          </Box>
        </Dialog>
      </Box>
    </DrawerContainer>
  );
};

export default TaskProgressUpdateDrawer;
