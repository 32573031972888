import React, { useEffect, useState } from "react";
import Dialog from "../../../styled/generic/Dialog";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import DatePicker from "../../../styled/generic/DatePicker";
import dayjs from "dayjs";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useSelector } from "react-redux";
import HorizBox from "../../../styled/generic/HorizBox";
import Select from "../../../styled/generic/Select";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import Api from "../../../../helpers/Api";
import { useDispatch } from "react-redux";
import DocViewer from "../../../styled/generic/DocViewer";
import LabelsDrawer from "./LabelsDrawer";
import UserPickerDropdown from "../../../styled/generic/UserPickerDropdown";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import CategoryCreateDrawer from "../../../OfferingsAndProducts/Category.Crearte.Drawer";
import Button from "../../../styled/generic/Button";
import useShareAndAssign from "../../../ResourceAccessControl/shareAndAssign";
import { updateWallet } from "../../../finance2o/accounts/paymentMethods/dwolla/api";
import { styled } from "@mui/material/styles";
import CustomAutocomplete from "../../../styled/CommonComponents/CustomAutocomplete";
import CategoryAutocomplete from "../../../OfferingsAndProducts/CategoryAutocomplete";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import { getProgressColor } from "../../../../helpers/colorHelper";
import ProjectPicker from "../../../styled/generic/ProjectPicker";
import OrgPicker from "../../../styled/generic/OrgPicker";
import _ from "lodash";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
} from "@material-ui/core";
import useBlockUnitViewer from "./BlockUnitViewer";
import NormalDrawer from "../../../styled/CommonComponents/NormalDrawer";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const assignBtn = (
  <Button variant="outlined" startIcon={<Add />}>
    Click here to assign
  </Button>
);

const useStyles = makeStyles((theme) => ({
  drawerBody: {
    width: "100%",
    padding: "20px",
    height: "calc(100% - 80px)",
    overflowY: "auto",
  },
  drawerBottomBtn: {
    width: "100%",
    height: "80px",
    paddingBottom: "20px",
    padding: "0px 20px"
  }
}));

const AddTaskDialog = ({
  defaultOrg,
  defaultProject,
  assigneesByCreater,
  showAddTaskDialog,
  setShowAddTaskDialog,
  targetId,
  onTaskAdd,
  onTaskUpdate,
  onTaskDelete,
  profileId,
  library,
  editBlocks,
  editUnits,
  project,
  organization,
  parentTaskId,
  taskToUpdate,
}) => {
  const classes = useStyles();
  const files = useSelector((state) => state.file);

  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(3, "day"));
  const [comment, setComment] = useState("");
  const [selectedProgressHistory, setSelectedProgressHistory] = useState();
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [labels, setLabels] = useState([]);

  const [openLabelsDrawer, setOpenLabelsDrawer] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [removeBlockIds, setRemoveBlockIds] = useState([]);
  const [removeUnitIds, setRemoveUnitIds] = useState([]);
  const [showProgressHistoryDialog, setShowProgressHistoryDialog] = useState(false);
  const [selectedProgressHistoryFiles, setSelectedProgressHistoryFiles] = useState([]);
  const [selectUnits, setSelectUnits] = useState([]);
  const [category, setCategory] = useState(null);
  const [createCategoryDrawer, setCreateCategoryDrawer] = useState(false);
  const [addBlockIds, setAddBlockIds] = useState([]);
  const [showBlock, setShowBlock] = useState(true);
  const [removeFile, setRemoveFile] = useState({
    taskId: taskToUpdate?._id,
    removeFilesIds: [],
  });

  const projectProfileId = project?.profile;
  const orgProfileId = organization?.profile;
  const userProfileId = user?.profile;

  useEffect(() => {
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: taskToUpdate ? taskToUpdate._id : null,
    resourceName: taskToUpdate ? "WBS" : null,
    customShareBtn: null,
    customAssignBtn: assignBtn,
  });

  const { blockUnitView, selectedUnitIds, selectedBlockIds } =
    useBlockUnitViewer({
      projectId: selectedProject?._id || taskToUpdate?.project,
      initialBlockIds: taskToUpdate?.projectBlocks?.map(({ _id }) => _id) || [],
      initialUnitIds: taskToUpdate?.units?.map(({ _id }) => _id) || [],
    });

  const getAllUnitIds = () => {
    let allIds = [];

    for (let unitId in selectUnits) {
      allIds.push(...selectUnits[`${unitId}`].map(({ _id }) => _id));
    }
    return allIds;
  };

  const createTask = async () => {
    // console.log("sharedProfilesRole: ", sharedProfilesRole);
    // console.log("assignedProfiles: ", assignedProfiles);

    try {
      setLoadingPrimary(true);
      const { data } = await Api.post("/wbs/create-custom", {
        targetId: targetId,
        taskName: taskName,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        comment: comment,
        files: filesArr,
        labels: labels?.map((l) => l?._id) || [],
        category: category?._id,
        creatorProfileId: user?.profile,
        creatorId: user?._id,
        parentTaskId: parentTaskId,
        blockIds: selectedBlockIds || [],
        unitIds: selectedUnitIds || [],
        organization: selectedOrganization?._id,
        project: selectedProject?._id || defaultProject?._id,
        shared:
          sharedProfilesRole && sharedProfilesRole?.length > 0
            ? sharedProfilesRole.map((s) => s?._id)
            : [],
        assigned:
          assignedProfiles && assignedProfiles?.length > 0
            ? assignedProfiles.map((s) => s?._id)
            : [],
        accessRoles: sharedProfilesRole,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task created successfully",
          },
        });
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
        onTaskAdd(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while creating task",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  const updateTask = async () => {
    try {
      setLoadingPrimary(true);

      const { data } = await Api.post("/wbs/update-custom", {
        _id: taskToUpdate?._id,
        updateBody: {
          taskName: taskName,
          removeFile: removeFile,
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          comment: comment,
          files: filesArr,
          labels: labels?.map((l) => l?._id) || [],
          category: category?._id,
          assignees: assignees,
          creator: user?.profile,
          blockIds: selectedBlockIds || [],
          unitIds: selectedUnitIds || [],
          removeBlockIds: removeBlockIds,
          addBlockIds: addBlockIds,
          shared:
            sharedProfilesRole && sharedProfilesRole.length > 0
              ? sharedProfilesRole.map((s) => s?._id)
              : [],
          assigned: [
            ...assigneesByCreater?.map(({ _id }) => _id),
            ...(assignedProfiles?.length > 0
              ? assignedProfiles?.map((s) => s?._id)
              : []),
          ],
          organization: selectedOrganization?._id,
          project: selectedProject?._id,
          accessRoles: sharedProfilesRole,
        },
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task updated successfully",
          },
        });
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
        onTaskUpdate(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while creating task",
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  const deleteTask = async () => {
    try {
      setLoadingSecondary(true);
      const { data } = await Api.post("/wbs/delete", {
        taskIds: [taskToUpdate?._id],
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Task deleted successfully",
        },
      });

      setShowAddTaskDialog(false);
      onTaskDelete(taskToUpdate);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingSecondary(false);
    }
  };

  useEffect(() => {
    //console.log("taskToUpdate: ", taskToUpdate?.units);
    if (taskToUpdate?._id) {
      let groupByBlocks = _.groupBy(taskToUpdate?.units, "projectBlock");
      // setBlocks(taskToUpdate?.projectBlocks);
      setSelectUnits(groupByBlocks);

      setTaskName(taskToUpdate?.taskName);
      setStartDate(dayjs(taskToUpdate?.startDate));
      setEndDate(dayjs(taskToUpdate?.endDate));
      setComment(taskToUpdate?.comment);
      setLabels(taskToUpdate?.labels);
      setCategory(taskToUpdate?.category);
      setAssignees(taskToUpdate?.assignees || []);

      let _docs = taskToUpdate?.docs || [];
      let _files = [];
      for (let i = 0; i < _docs?.length; i++) {
        let _doc = _docs[i];
        let _docFiles = _doc?.files || [];
        for (let j = 0; j < _docFiles?.length; j++) {
          let _docFile = _docFiles[j];
          _files.push(_docFile);
        }
      }

      setFilesArr(_files);
      setFileIds(_files.map((f) => f?._id) || []);

      if (taskToUpdate?.projectBlocks?.length && taskToUpdate?.units?.length) {
        let groupByBlocks = _.groupBy(taskToUpdate?.units, "projectBlock");

        setSelectUnits(groupByBlocks);
      }
    }
  }, [taskToUpdate]);

  console.log(taskToUpdate)

  return (
    <NormalDrawer
      openDrawer={showAddTaskDialog}
      setOpenDrawer={setShowAddTaskDialog}
      anchor={"right"}
      width={"40vw"}
      title={taskToUpdate ? "Update Task" : "Create Task"}
      content={<>
        <div className={classes.drawerBody} >
          <FormBox label="Task Name">
            <TextField
              placeholder="Enter"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              fullWidth={true}
            />
          </FormBox>
          <SpaceBetween
            left={
              <FormBox label="Start Date">
                <DatePicker
                  fullWidth={true}
                  value={startDate}
                  onChange={setStartDate}
                />
              </FormBox>
            }
            right={
              <FormBox label="End Date">
                <DatePicker
                  fullWidth={true}
                  value={endDate}
                  onChange={setEndDate}
                />
              </FormBox>
            }
          />
          <SpaceBetween
            left={
              <FormBox label="Organization">
                <OrgPicker
                  selectedOrg={selectedOrganization}
                  setSelectedOrg={setSelectedOrganization}
                  defaultOrganizationId={taskToUpdate?._id ? taskToUpdate?.organization : organization?._id}
                  hideLabel={true}
                  fullWidth={true}
                />
              </FormBox>
            }
            right={
              <FormBox label="Project">
                <ProjectPicker
                  defaultProjectId={taskToUpdate?._id ? taskToUpdate?.project : project?._id}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  fullWidth={true}
                />
              </FormBox>
            }
          />
          {blockUnitView}
          <FormBox label="Comment">
            <TextField
              placeholder="Enter"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              fullWidth={true}
              rows={4}
              multiline={true}
            />
          </FormBox>
          <FormBox label="Categories">
            <CategoryAutocomplete
              libraryId={library?._id}
              selectedCategories={category}
              setSelectedCategories={setCategory}
              isMultiple={false}
              label={"Category"}
              profileId={profileId}
              type={"Task"}
              isCreateNew={true}
              placeholder="Select Category"
            />
          </FormBox>
          <FormBox label="Labels">
            <CategoryAutocomplete
              libraryId={library?._id}
              selectedCategories={labels}
              setSelectedCategories={setLabels}
              isMultiple={true}
              label={"Label"}
              profileId={profileId}
              type={"Task"}
              isCreateNew={true}
              placeholder="Select Label"
            />
          </FormBox>
          <FormBox label="Assignees">
            <Box sx={{ display: "flex", justifyContent: "start", my: 2 }}>
              <AvatarGroup max={10}>
                {assigneesByCreater?.map(({ parent }) => (
                  <BootstrapTooltip placement="top" title={parent?.displayName}>
                    <Avatar
                      alt={parent?.displayName}
                      src={
                        parent?.displayPicture?.thumbUrl ||
                        parent?.displayPicture?.url
                      }
                    />
                  </BootstrapTooltip>
                ))}
              </AvatarGroup>
            </Box>
            {AssignButton} {AssigneDrawer}
            <div>{AssignView}</div>
          </FormBox>
          <FormBox label="Attachments">
            <DocumentUploader
              documents={filesArr}
              onUpload={() => {
                setFileIds(createdFileIds);

                let data = [];
                for (let i = 0; i < createdFileIds?.length; i++) {
                  data.push(fileDictionary[createdFileIds[i]]);
                }
                let setValues = new Set([...filesArr, ...data]);
                setFilesArr(Array.from(setValues));
              }}
              onDelete={(dataId) => {
                setRemoveFile({
                  taskId: taskToUpdate?._id,
                  removeFilesIds: [...removeFile.removeFilesIds, dataId],
                });
                setFileIds(fileIds.filter((m) => m !== dataId));
                setFilesArr(filesArr.filter((m) => m._id !== dataId));
              }}
            />
            <Box>
              {filesArr?.length > 0 ? (
                <Box>
                  <DocViewer
                    data={filesArr}
                    isDeletable={true}
                    disableEditMetaData={true}
                    disableShowMetaData={true}
                    onMetadataChange={() => { }}
                    deleteFunction={(delId) => {
                      setRemoveFile({
                        taskId: taskToUpdate?._id,
                        removeFilesIds: [...removeFile.removeFilesIds, delId],
                      });
                      setFileIds(fileIds.filter((m) => m !== delId));
                      setFilesArr(filesArr.filter((m) => m._id !== delId));
                    }}
                  />
                </Box>
              ) : (
                <Box>No documents found</Box>
              )}
            </Box>
          </FormBox>
        </div>
        <div className={classes.drawerBottomBtn} >
          <DuoButtonGroup
            primaryButtonText={taskToUpdate?._id ? "Update Task" : "Create Task"}
            primaryButtonListener={() => {
              taskToUpdate?._id ? updateTask() : createTask();
            }}
            secondaryButtonText="Delete"
            secondaryButtonListener={() => deleteTask()}
            secondaryButtonColor="error"
            hideSecondary={!taskToUpdate?._id}
            loadingPrimary={loadingPrimary}
            loadingSecondary={loadingSecondary}
          />
        </div>
      </>}
    />
  );
};

export default AddTaskDialog;
