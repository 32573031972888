import React from "react";
import DocumentViewer from "./DocumentViewer";
import { Box, Grid, Stack, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DocViewerWithMetaData = ({
  file,
  isDeletable,
  deleteFunction,
  onMetadataChange,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid #F3F3F3",
        background: "#F5F5F5",
      }}
    >
      {/* <DocumentViewer
        file={file}
        isDeletable={isDeletable}
        removeFun={deleteFunction}
      /> */}
      <Box sx={{ ml: 2 }} flex="1">
        <Stack spacing={1}>
          <TextField
            size="small"
            placeholder="Title"
            fullWidth
            sx={{ backgroundColor: "#fff" }}
            defaultValue={file?.title || ""}
            onChange={(evt) =>
              onMetadataChange(file?._id, "title", evt.target.value)
            }
          />
          <TextField
            size="small"
            placeholder="Tags"
            fullWidth
            sx={{ backgroundColor: "#fff" }}
            defaultValue={file?.title || ""}
            onChange={(evt) =>
              onMetadataChange(file?._id, "tag", evt.target.value)
            }
          />
          <TextField
            size="small"
            placeholder="Caption"
            fullWidth
            multiline
            rows={3}
            sx={{ backgroundColor: "#fff" }}
            defaultValue={file?.title || ""}
            onChange={(evt) =>
              onMetadataChange(file?._id, "description", evt.target.value)
            }
          />
        </Stack>
      </Box>
    </Box>
  );
};

const DocViewer = ({
  data,
  isDeletable,
  closeIcon = true,
  deleteFunction,
  disableShowMetaData,
  disableEditMetaData,
  onMetadataChange,
}) => {
  //console.log("docccccccccccc", data);
  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  return (
    <Box
      sx={{
        width: "100%",

        display: "grid",
        mt: 2,
        gridTemplateColumns: "repeat(auto-fit, minmax(10rem, 10rem)) ",
        gap: "1rem",
        justifyContent: "center",
      }}
    >
      {data?.map((file, i) =>
        file?.type === "image/png" ? (
          <div
            style={{
              border: "4px solid #1976d2",
              cursor: "pointer",
              position: "relative",
              width: "10rem",
              height: "8.5rem",
              borderRadius: "1rem",
            }}
          >
            <img
              onClick={() => openImageInNewTab(file?.url)}
              style={{
                width: "10rem",
                height: "8rem",
                borderRadius: "0.8rem",
              }}
              src={file?.url}
              alt=""
            />
            {closeIcon && (
              <span
                onClick={() => deleteFunction(file?._id)}
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  borderRadius: "50%",
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "0.5rem",
                  zIndex: 20,
                  right: "0.5rem",

                  cursor: "pointer",
                }}
              >
                <CloseIcon
                  style={{
                    color: "white",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                />
              </span>
            )}
          </div>
        ) : (
          <DocumentViewer
            closeIcon={closeIcon ? closeIcon : false}
            file={file}
            isDeletable={isDeletable}
            removeFun={deleteFunction}
          />
        )
      )}
    </Box>
    // <Grid container spacing={2}>
    //   {data?.map((file, i) => (
    //     //xs={disableShowMetaData ? "auto" : 6}
    //     <Grid
    //       xs={{
    //         borderRadius: "1rem",
    //         border: "2px",
    //         borderColor: "red",
    //       }}
    //       item
    //       key={i}
    //     >
    //       {file?.type === "image/png" ? (
    //         // <DocumentViewer
    //         //   file={file}
    //         //   isDeletable={isDeletable}
    //         //   removeFun={deleteFunction}
    //         // />
    //         <div
    //           style={{
    //             width: "11rem",
    //             height: "9rem",
    //             borderRadius: "1rem",

    //             border: "2px",
    //             borderColor: "red",
    //           }}
    //         >
    //           <img
    //             style={{
    //               width: "10",
    //               height: "8rem",
    //             }}
    //             src={file?.url}
    //             alt=""
    //           />
    //         </div>
    //       ) : (
    //         // <DocViewerWithMetaData
    //         //   file={file}
    //         //   isDeletable={isDeletable}
    //         //   deleteFunction={deleteFunction}
    //         //   onMetadataChange={onMetadataChange}
    //         // />
    //         <></>
    //       )}
    //     </Grid>
    //   ))}
    // </Grid>
  );
};

export default DocViewer;
