import React, { useEffect, useState } from "react";
import LoadingBox from "../../styled/generic/LoadingHandler";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import TableContainer from "../../styled/generic/TableContainer";
import { format } from "timeago.js";
import { Avatar, AvatarGroup } from "@mui/material";
import PopupMenuList from "../../styled/generic/PopupMenuList";
import MoreButton from "../../styled/generic/MoreButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BugsView = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [bugs, setBugs] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAssignedBugs = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/bugs/assigned", {
        profileId: user?.profile,
      });
      if (data) {
        setBugs(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching bugs",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching bugs",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssignedBugs();
  }, []);

  return (
    <LoadingBox loading={loading} fullHeight={true}>
      <Box sx={{ mt: 2 }}>
        {bugs?.length <= 0 ? (
          <Box>
            <Typography variant="subtitle1" color="textSecondary">
              No bugs found
            </Typography>
          </Box>
        ) : (
          <Box>
            <TableContainer
              columns={["TITLE", "LABELS", "PRIORITY", "ASSIGNEES"]}
              data={bugs}
              loading={loading}
            >
              {bugs.map((bug, index) => (
                <tr
                  onClick={() => {
                    history.push(`/bugs/bug/${bug?._id}`);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <td>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {bug?.title} #{bug?.count}{" "}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        #{bug?.count}{" "}
                        {bug?.type === "Open" ? "opened" : "closed"}{" "}
                        {bug?.type === "Open"
                          ? format(bug?.openDate)
                          : format(bug?.closeDate)}{" "}
                        by {bug?.user?.displayName}
                      </Typography>
                    </Box>
                  </td>
                  <td>
                    {bug?.labels?.length > 0 ? (
                      <Box>
                        {bug?.labels?.slice(0, 3)?.map((label) => (
                          <div
                            style={{
                              backgroundColor: label?.color + "33",
                              color: label?.color,
                              padding: "5px",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "5px",
                              fontSize: "0.8rem",
                              borderRadius: "8px",
                              margin: "5px",
                            }}
                          >
                            {label?.title || "Untitled"}
                          </div>
                        ))}
                      </Box>
                    ) : (
                      <Typography variant="subtitle2" color="textSecondary">
                        No labels
                      </Typography>
                    )}
                  </td>
                  <td>{bug?.priority}</td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <AvatarGroup max={4}>
                        {bug?.assigned?.map((item) => (
                          <Avatar
                            alt={item?.parent?.displayName}
                            src={item?.parent?.displayPicture?.url}
                            sx={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        ))}
                      </AvatarGroup>
                    </Box>
                  </td>
                  
                </tr>
              ))}
            </TableContainer>
          </Box>
        )}
      </Box>
    </LoadingBox>
  );
};

export default BugsView;
