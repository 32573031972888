import React from "react";
import { allStatusOptions } from "./allStatuses";
const approvalTypesDefaultFieldsMap = {
    "HRM": "defaultHRMApproval",
    "Material Request": "defaultMaterialRequestApproval",
    "Bill Of Quantities": "defaultBillOfQuantitiesApproval",
    "Purchase Order": "defaultPurchaseOrderApproval",
    "Work Order": "defaultWorkOrderApproval",
    "GRN": "defaultGRNApproval",
    "Estimation": "defaultEstimationApproval",
    "Transaction": "defaultTransactionApproval",
    "Transaction Template": "defaultTransactionTemplateApproval",
    "Financial Certificate": "defaultFinancialCertificateApproval",
    "Debit/Credit Note": "defaultDebitCreditNoteApproval",
}
const approvalTypesModuleMap = {
    "HRM": "defaultHRMApproval",
    "Material Request": "OpsOrder",
    "Bill Of Quantities": "OpsOrder",
    "Purchase Order": "OpsOrder",
    "Work Order": "OpsOrder",
    "GRN": "OpsOrder",
    "Estimation": "BudgetCost",
    "Transaction": "Transaction",
    "Transaction Template": "TxTemplate",
    "Financial Certificate": "FinancialCertificate",
    "Debit/Credit Note": "Transaction",
}
const getLinerStatuses = (type) => {
    const allStatesSet = new Set();
    if (allStatusOptions[type]) {
        Object.keys(allStatusOptions[type]).forEach((state) => {
            if ((type === "Transaction" && state !== "Paid") || type !== "Transaction") {
                allStatesSet.add(state)
            }
        });
        Object.values(allStatusOptions[type]).forEach(statesArray => {
            statesArray.forEach(state => {
                if ((type === "Transaction" && state !== "Paid") || type !== "Transaction") {
                    allStatesSet.add(state)
                }
            })
        });
    }
    const allStates = Array.from(allStatesSet);
    return allStates;
}

export {
    approvalTypesDefaultFieldsMap,
    approvalTypesModuleMap,
    getLinerStatuses
}