import { Avatar, Box } from "@mui/material";
import React from "react";

const SingleUserWithCaptionTableCell = ({
  label,
  caption,
  url,
  avatarSx = {},
  labelStyleProps = {},
  captionStyleProps = {},
  avatarTextGap = 2,
  sx = {},
}) => {
  return (
    <Box display="flex" alignItems="center" sx={sx}>
      <Avatar src={url} sx={{ mr: avatarTextGap, ...avatarSx }} />
      <Box display="flex" flexDirection="column">
        <span style={{ fontWeight: "bold", ...labelStyleProps }}>{label}</span>
        <span style={{ fontWeight: "normal", ...captionStyleProps }}>
          {caption}
        </span>
      </Box>
    </Box>
  );
};

export default SingleUserWithCaptionTableCell;
