import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Organizations from "../organization/organizations";
import { Button, Snackbar, useMediaQuery } from "@material-ui/core";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import AnalysisCardSvg from "../../Assets/analysisCard.svg";
import ProjectManagementCardSvg from "../../Assets/proj_mgmt_logo.svg";
import DocumentCardSvg from "../../Assets/Doc_logo.svg";
import AccountingCardSvg from "../../Assets/accounting_logo.svg";
import propertyManagement from "../../Assets/propertyManagement.svg";
import { Alert } from "@material-ui/lab";
import Api from "../../helpers/Api";
import AnalysisCard from "./cards/AnalysisCard";
import DocumentCard from "./cards/DocumentCard";
import CalendarCard from "./cards/CalendarCard";
import PaymentsCard from "./cards/PaymentsCard";
import ProjectManagementCard from "./cards/ProjectManagementCard";
import PropertyManagementCard from "./cards/PropertyManagementCard";
import ContactsCard from "./cards/ContactsCard";
import EstimationAnalyticsCard from "./cards/EstimationAnalyticsCard";
import PermitAndApprovalCard from "./cards/PermitAndApprovalCard";
import useWorkspace from "../useWorkspace";
import OfferingsAndProductsCard from "./cards/OfferingsAndProducts.Card";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import SpaceBetween from "../styled/generic/SpaceBetween";
import FormBox from "../styled/generic/FormBox";
import WorkspaceApp from "../styled/generic/WorkspaceApp";
import { Add, Sort, TuneOutlined } from "@material-ui/icons";
import StandardContainer from "../styled/generic/StandardContainer";
import DashboardInvitations from "./Dashboard.Invitations";
import OrgTable from "../organization/OrgTable";
import LabelWithSingleActionButton from "../styled/generic/LabelWithSingleActionButton";
import LabelWithSingleActionIconButton from "../styled/generic/LabelWithSingleActionIconButton";
import CreateOrgDialog from "../organization/create.org.dialog";
import ProjectContacts from "../projects/project.contacts";
import TabsWithCommonAction from "../styled/generic/TabsWithCommonAction";
import TasksView from "./activities/TasksView";
import BugsView from "./activities/BugsView";
import { setAuthCookieData } from "../../helpers/cookie.helper";
import { setPersistedState } from "../../helpers/statePersistence";



const useStyles = makeStyles((theme) => ({
  mainContForDesktop: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  left_container: {
    width: "68%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  left_top: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "10px",
    padding: "0px 0px 10px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  left_top_card: {
    width: "18%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "49%",
    },
  },
  left_top_img: {
    width: "55%",
    height: "auto",
  },
  left_top_header: {
    color: "#00345D",
    fontWeight: "500",
    textAlign: "center",
  },
  left_cards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  singleCardCont: {
    width: "49%",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  right_container: {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  orgInvtCont: {
    marginBottom: "20px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    borderRadius: "7px",
    justifyContent: "center",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const entireState = useSelector(state => state);
  const { user } = useSelector((state) => state.auth);
  let { invitations } = useSelector((state) => state.team);

  const [showCreateOrgDialog, setShowCreateOrgDialog] = useState(false);
  const [openSignSucess, setOpenSignSuccess] = useState(false);
  const [docs, setDocs] = useState([]);
  const [issues, setIssues] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [chartAccounts, setChartAccounts] = useState([]);
  const [reports, setReports] = useState([]);
  const [projectPortfolios, setProjectPortfolios] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Tasks");

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isExMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onCreate = (newTeam) => {
    let locRows = [newTeam, ...rows];
    let locFilteredRows = [newTeam, ...filteredRows];
    setRows(locRows);
    setFilteredRows(locFilteredRows);
    setShowCreateOrgDialog(false);
  };

  const handleCloseSignSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSignSuccess(false);
  };

  const getData = async () => {
    setLoader(true);
    const res = await Api.post("profile/getData", {
      userId: user?._id,
      profileId: user?.profile,
      walletId: user?.wallet,
      isAdmin: true,
    });
    if (res?.data) {
      const data = res.data;
      setProjectPortfolios(data?.projectPortfolios || []);
      setDocs(data?.docs || []);
      setIssues(data?.issues || []);
      setReports(data?.analysisReports || []);
      setAppointments(data?.appointments || []);
      setChartAccounts(data?.chartAccounts || []);
      setTransactions(data?.transactions || []);
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [invitations]);

  const { setId, setShowWorkspaceConfigDialog, workspaceConfigDialog, config } =
    useWorkspace({
      type: "User",
      title: "User Configuration",
      configTitle: "Select user apps",
    });

  useEffect(() => {
    // User id
    if (user) {
      setId(user._id);
    }
  }, [user]);
  
  const handleAppClick = (code) => {
    let profileId = user?.profile;
    const token = localStorage.getItem("token");
    if (entireState && typeof entireState === 'object') {
      setAuthCookieData({
        user,token, entireState
      });
    } else {
      console.warn("Skipped state persistence due to invalid state");
    }

    switch (code) {

      case "assets":
        window.open(`https://assets.reallist.ai/assets/home/${profileId}`,"_self");
        break;
        
      case "approval":
        window.open(`https://approval.reallist.ai/approval/${profileId}`,
          "_self"
        );
        break;
      case "booking":
        window.open(`https://bookings.reallist.ai/booking/${profileId}`,
          "_self"
        );
        break;

      case "ticketing":
        window.open("https://teams.reallist.ai/issues/profile/" + profileId,"_self");
        break;
      case "teams":
          window.open("https://teams.reallist.ai/issues/profile/" + profileId,"_self");
          break;
      case "accounts":
        window.open(`https://accounts.reallist.ai/finance/${user?.wallet}/bank-accounts`,"_self");
        break;
      case "documents":
        window.open("https://docs.reallist.ai/docs/" + profileId,"_self");
        break;
      case "events":
        window.open("https://calendar.reallist.ai/calendar/" + profileId + "?mode=open","_self");
        break;
      case "portfoliomanagement":
        history.push("/projectportfolio/" + profileId);
        break;
      case "sitereport":
        window.open(`https://sitereport.reallist.ai/site-report/${profileId}`,"_self");
        break;
      case "meeting":
        // history.push(`/meeting/${profileId}`);
        break;

      case "mywork":
        window.open(`https://mywork.reallist.ai/my-work/${profileId}`,"_self");
        break;

      case "productservices":
        history.push(`/offerings-categories/management/${profileId}`,"_self");
        break;

      case "planning":
        window.open(`https://planning.reallist.ai/planning/${profileId}`,"_self");
        break;

      case "bugs":
        window.open(`https://bugs.reallist.ai/bugs/${profileId}`,"_self");
        break;

      default:
        alert("No action found for this type of app");
        break;
    }
  };


  const { auth, team: teamReducer } = useSelector((state) => state);
  const { teamIds, teamDictionary } = teamReducer;

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const getDataFromReducer = () => {
    var tempRows = [];
    teamIds.map((teamId) => {
      var value = teamDictionary[teamId];
      if (value?.parentModelName === "Organization" && value?.parent) {
        tempRows.push(value);
      }
    });
    setRows(tempRows);
    setFilteredRows(tempRows);
  };

  useEffect(() => {
    getDataFromReducer();
  }, [teamDictionary, teamIds]);

  const addCreatedOne = (newOrg) => {
    let updatedOrgs = rows || [];
    updatedOrgs.push(newOrg);
    setRows(updatedOrgs);
    setFilteredRows(updatedOrgs);
  };

  return (
    <StandardContainer>
      <Grid container columnSpacing={2}>
        <Grid item xs={9}>
          <StandardAppContainerRounded>
            <LabelWithSingleActionButton
              label="My Apps"
              caption="Manage your workspace apps"
              actionButtonText="Apps"
              actionButtonIcon={<Add />}
              onClick={() => setShowWorkspaceConfigDialog(true)}
            />

            <Grid container spacing={2}>
              {config?.apps?.map((item) => (
                <Grid item xs={3}>
                  <WorkspaceApp
                    isSelectable={false}
                    onClick={() => handleAppClick(item.code)}
                    appData={item}
                  />
                </Grid>
              ))}
            </Grid>
          </StandardAppContainerRounded>

          <StandardAppContainerRounded>
            <FormBox label="Activities">
              <TabsWithCommonAction
                tabs={["Tasks", "Bugs"]}
                action={<Box></Box>}
                currentTabVariable={selectedTab}
                onTabChange={(tab) => setSelectedTab(tab)}
              />
              {selectedTab === "Tasks" && <TasksView />}
              {selectedTab === "Bugs" && <BugsView />}
            </FormBox>
          </StandardAppContainerRounded>

          {/* <Grid container spacing={2}>
            <Grid item xs={4}>
              <ContactsCard
                profileId={user?.profile}
                wallet={user?.wallet}
                parent={user?._id}
                parentModelName={"User"}
              />
            </Grid>
            <Grid item xs={8}>
              <Organizations />
              <DashboardInvitations addCreatedOne={addCreatedOne} />
            </Grid>
              </Grid> */}
        </Grid>
        <Grid item xs={3}>
          <StandardAppContainerRounded>
            <LabelWithSingleActionButton
              label="Organization"
              actionButtonText="Add"
              actionButtonIcon={<Add />}
              actionButtonVariant="text"
              actionButtonListener={() => setShowCreateOrgDialog(true)}
            />
            <OrgTable rows={filteredRows} />

            <CreateOrgDialog
              open={showCreateOrgDialog}
              setOpen={setShowCreateOrgDialog}
              onCreate={onCreate}
            />
          </StandardAppContainerRounded>

          <DashboardInvitations addCreatedOne={addCreatedOne} />

          <StandardAppContainerRounded>
            <ProjectContacts
              team={{
                parent: {
                  _id: user?._id,
                  profile: {
                    _id: user?.profile
                  }
                },
                parentModelName: "User"
              }}
              givenIsMdSmall={true}
              profileId={user?.profile}
              userRole={"Owner"}
              wallet={user?.wallet}
              parent={user?._id}
              parentModelName={"User"}
            />
          </StandardAppContainerRounded>
        </Grid>
      </Grid>

      {workspaceConfigDialog}
    </StandardContainer>
  );
};
export default Dashboard;
// <div className={classes.mainContForDesktop}>
//   <Snackbar
//     open={openSignSucess}
//     autoHideDuration={6000}
//     onClose={handleCloseSignSuccess}
//   >
//     <Alert
//       onClose={handleCloseSignSuccess}
//       severity="success"
//       sx={{ width: "100%" }}
//     >
//       Signature Provider Integration Success!
//     </Alert>
//   </Snackbar>
//   {workspaceConfigDialog}
//   Hi
//   {/* <div className={classes.left_container}>
//     <Paper className={classes.left_top}>
//       <div
//         className={classes.left_top_card}
//         onClick={() => history.push("/investment/analysis/new")}
//       >
//         <img className={classes.left_top_img} src={AnalysisCardSvg} />
//         <p className={classes.left_top_header}>
//           Investment <br /> Analysis
//         </p>
//       </div>
//       <div
//         className={classes.left_top_card}
//         onClick={() => history.push("/issues/profile/" + user?.profile)}
//       >
//         <img
//           className={classes.left_top_img}
//           src={ProjectManagementCardSvg}
//         />
//         <p className={classes.left_top_header}>
//           Project <br /> Management
//         </p>
//       </div>
//       <div
//         className={classes.left_top_card}
//         onClick={() => history.push("/projectportfolio/" + user?.profile)}
//       >
//         <img className={classes.left_top_img} src={propertyManagement} />
//         <p className={classes.left_top_header}>
//           Property <br /> Management
//         </p>
//       </div>
//       <div
//         className={classes.left_top_card}
//         onClick={() => {
//           if (user?.wallet) {
//             history.push(`/finance/${user?.wallet}/bank-accounts`);
//           }
//         }}
//       >
//         <img className={classes.left_top_img} src={AccountingCardSvg} />
//         <p className={classes.left_top_header}>
//           Accounting <br /> & Payments
//         </p>
//       </div>
//       <div
//         className={classes.left_top_card}
//         onClick={() => history.push("/docs/" + user?.profile)}
//       >
//         <img className={classes.left_top_img} src={DocumentCardSvg} />
//         <p className={classes.left_top_header}>
//           Document <br /> Management
//         </p>
//       </div>
//     </Paper>

//     {isExMobile && (
//       <a href="#orgInviteSection" style={{ textDecoration: "none" }}>
//         <Paper elevation={1} className={classes.orgInvtCont}>
//           <h3 className={classes.left_top_header}>
//             Organizations & Invites
//           </h3>
//         </Paper>
//       </a>
//     )}

//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "flex-end",
//         marginBottom: "10px",
//       }}
//     >
//       <Button
//         color="primary"
//         variant="contained"
//         onClick={() => setShowWorkspaceConfigDialog(true)}
//       >
//         Add Apps +
//       </Button>
//     </div>
//     <div className={classes.left_cards}>
//       {config &&
//         config.apps &&
//         config.apps.some((item) => item.code === "projectmanagement") && (
//           <div className={classes.singleCardCont}>
//             <ProjectManagementCard
//               issues={issues}
//               profileId={user?.profile}
//               loader={loader}
//             />
//           </div>
//         )}

//       {config &&
//         config.apps &&
//         config.apps.some((item) => item.code === "accountspayments") && (
//           <div className={classes.singleCardCont}>
//             <PaymentsCard
//               chartAccounts={chartAccounts}
//               transactions={transactions}
//               walletId={user?.wallet}
//               loader={loader}
//             />
//           </div>
//         )}

//       {config &&
//         config.apps &&
//         config.apps.some((item) => item.code === "documents") && (
//           <div className={classes.singleCardCont}>
//             <DocumentCard
//               docs={docs}
//               profileId={user?.profile}
//               loader={loader}
//             />
//           </div>
//         )}

//       {config &&
//         config.apps &&
//         config.apps.some((item) => item.code === "eventcalendar") && (
//           <div className={classes.singleCardCont}>
//             <CalendarCard
//               appointments={appointments}
//               profileId={user?.profile}
//               loader={loader}
//             />
//           </div>
//         )}

//       {config &&
//         config.apps &&
//         config.apps.some(
//           (item) => item.code === "investmentanalysisuser"
//         ) && (
//           <div className={classes.singleCardCont}>
//             <AnalysisCard reports={reports} loader={loader} />
//           </div>
//         )}

//       {config &&
//         config.apps &&
//         config.apps.some((item) => item.code === "portfoliomanagement") && (
//           <div className={classes.singleCardCont}>
//             <PropertyManagementCard
//               profileId={user?.profile}
//               projectPortfolios={projectPortfolios}
//               setProjectPortfolios={setProjectPortfolios}
//               loader={loader}
//             />
//           </div>
//         )}

//       <div className={classes.singleCardCont}>
//         <OfferingsAndProductsCard
//           profileId={user?.profile}
//         />
//       </div>

//       {config &&
//         config.apps &&
//         config.apps.some((item) => item.code === "permitandapproval") && (
//           <div className={classes.singleCardCont}>
//             <PermitAndApprovalCard
//               issues={issues}
//               profileId={user?.profile}
//               loader={loader}
//             />
//           </div>
//         )}
//     </div>
//   </div>

//   <section id="orgInviteSection" className={classes.right_container}>
//     <Organizations />

//     <ContactsCard
//       profileId={user?.profile}
//       wallet={user?.wallet}
//       parent={user?._id}
//       parentModelName={"User"}
//     />
//   </section> */}
// </div>
