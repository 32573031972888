import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import SelectTree from "../styled/CommonComponents/SelectTree";
import Chip from '@material-ui/core/Chip';
import { useGetAccessOptionWithStucther } from './AccessControl.Hook';
import { createAccessRole, updateAccessRole } from "./api.call";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessControlStoreHook from './AccessControlStoreHook';

const useStyles = makeStyles((theme) => ({
    mainBodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 15px 25px",
        "& .MuiFormControl-root": {
            margin: "0px"
        }
    },
    loader: {
        width: "100%",
        height: "5px"
    },
    bottomCont: {
        width: "100%",
        height: "45px",
        padding: "0px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #ececec"
    },
    lableSty: {
        fontSize: "16px",
        fontWeight: "500",
        marginBottom: "5px",
        marginTop: "15px",
    },
    roleNameCont: {
        width: "100%",
        "& p": {
            fontSize: "16px",
            fontWeight: "500",
            marginBottom: "5px"
        }
    },
    autocompleteCont: {
        width: "100%",
        marginTop: "10px",
        "& p": {
            fontSize: "16px",
            fontWeight: "500",
            marginBottom: "5px",
        },
        "& .MuiAutocomplete-endAdornment": {
            display: "none"
        },
        "& .MuiAutocomplete-inputRoot": {
            minHeight: "100px",
            alignContent: "flex-start",
            padding: "10px"
        }
    },
    mainTreeCont: {
        width: "100%",
        maxHeight: "calc(100% - 150px)",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: "#f9f9f9",
        border: "1px solid #dfdfdf",
        padding: "10px 15px",
        borderRadius: "5px"
    },
    selectedItemsCont: {
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        maxHeight: "calc(100% - 300px)",
        border: "1px solid #dfdfdf",
        padding: "10px 15px",
        borderRadius: "5px",
        marginTop: "10px",
        display: "flex",
        flexWrap: "wrap",
        "& .MuiChip-sizeSmall": {
            margin: "5px 0px",
            marginRight: "5px"
        }
    },
    selectedItemsContForView: {
        width: "100%",
        maxHeight: "calc(100% - 115px)",
        overflowX: "hidden",
        overflowY: "auto",
        border: "1px solid #dfdfdf",
        padding: "10px 15px",
        borderRadius: "5px",
        marginTop: "10px",
        display: "flex",
        flexWrap: "wrap",
        "& .MuiChip-sizeSmall": {
            margin: "5px 0px",
            marginRight: "5px"
        }
    }
}));

const RoleAddDrawer = ({
    addRoleDrawer, setAddRoleDrawer, team, editRoleObj,
    addNewFunction, roleDrawerView, setRoleDrawerView
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { checkAccess } = AccessControlStoreHook()

    const { user } = useSelector((state) => state.auth);
    const { accessOptions, setAccessOptions, idObjectMap, setIdObjectMap } = useGetAccessOptionWithStucther(team?.parent?.profile?._id);

    const [selectedOptionIds, setSelectedOptionIds] = useState([])
    const [oldSelectedOptionIds, setOldSelectedOptionIds] = useState([])
    const [roleName, setRoleName] = useState("")
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (editRoleObj) {
            let locPermissions = editRoleObj?.permissions || [];
            let locSelectedOptionIds = [];
            locPermissions.map((item) => {
                locSelectedOptionIds.push(item.identifier)
                if (item?.fields && item.fields.length > 0) {
                    let arr = []
                    item.fields.map((fItem) => {
                        arr.push(`${item.identifier}-${fItem}`);
                    })
                    locSelectedOptionIds = [...locSelectedOptionIds, ...arr]
                }
            })
            setOldSelectedOptionIds(locSelectedOptionIds)
            setSelectedOptionIds(locSelectedOptionIds)
            setRoleName(editRoleObj?.role || "")
        }
    }, [editRoleObj, addRoleDrawer])

    useEffect(() => {
        if (!addRoleDrawer) {
            setSelectedOptionIds([])
            setRoleName("")
            setLoader(false)
        }
    }, [addRoleDrawer])

    const addNewRole = async () => {
        if (roleName && roleName.length > 0) {
            setLoader(true)
            if (editRoleObj?._id) {
                let newAccessArr = []
                const oldAccessIds = oldSelectedOptionIds || [];
                const oldPermissions = editRoleObj?.permissions || [];
                //newly added identifier
                let toBeAdded = selectedOptionIds.filter(id => !oldAccessIds.includes(id))
                //removed identifier
                let toBeDeleted = oldAccessIds.filter(id => !selectedOptionIds.includes(id))

                let exisitingIds = []
                let exisitingIdentifier = []
                let toBeDeletedIds = []
                let existingIdentifierIdMap = {}
                let updatedObjectArr = []
                oldPermissions.map((permission) => {
                    if (!toBeDeleted.includes(permission?.identifier)) {
                        exisitingIds.push(permission?._id)
                        exisitingIdentifier.push(permission?.identifier)
                        existingIdentifierIdMap[permission?.identifier] = permission?._id;
                    } else {
                        toBeDeletedIds.push(permission?._id)
                    }
                })

                exisitingIdentifier.map((_id) => {
                    const curObj = idObjectMap[_id]
                    if (curObj?.action && !curObj?.field) {
                        let children = curObj?.children;
                        let fArr = []
                        children.map((it) => {
                            if (selectedOptionIds.includes(it?._id)) {
                                fArr.push(it?.title)
                            }
                        })

                        updatedObjectArr.push({
                            updateOne: {
                                filter: { _id: existingIdentifierIdMap[curObj?._id] },
                                update: {
                                    $set: {
                                        fields: fArr
                                    }
                                }
                            }
                        })
                    }
                })

                toBeAdded.map((_id) => {
                    if (!idObjectMap[_id]?.field) {
                        const curObj = idObjectMap[_id]
                        let newObj = {
                            module: curObj?.module,
                            team: team?._id,
                            createdBy: user?.profile,
                            createdAt: new Date()
                        }
                        if (curObj?.resource) {
                            newObj.resource = curObj?.resource
                        }
                        if (curObj?.action) {
                            newObj.action = curObj?.action
                        }
                        if (curObj?._id) {
                            newObj.identifier = curObj?._id
                        }
                        if (curObj?.action && !curObj?.field) {
                            let children = curObj?.children;
                            let fArr = []
                            children.map((it) => {
                                if (selectedOptionIds.includes(it?._id)) {
                                    fArr.push(it?.title)
                                }
                            })
                            newObj.fields = fArr
                        }
                        if (!curObj?.field) {
                            newAccessArr.push(newObj)
                        }
                    }
                })

                const reqObj = {
                    newAccess: newAccessArr,
                    toBeDeletedIds,
                    _id: editRoleObj?._id,
                    updatedObjectArr: updatedObjectArr,
                    roleName,
                    exisitingIds
                }

                await updateAccessRole(reqObj)
                    .then((data) => {
                        if (addNewFunction) {
                            addNewFunction(data.updatedRolePermission, true)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                let newAccessArr = []
                let actionField = {}
                selectedOptionIds.map((_id) => {
                    if (idObjectMap[_id] != null && idObjectMap[_id] != undefined) {
                        const curObj = idObjectMap[_id]
                        let newObj = {
                            module: curObj?.module,
                            team: team?._id,
                            createdBy: user?.profile,
                            createdAt: new Date()
                        }
                        if (curObj?.resource) {
                            newObj.resource = curObj?.resource
                        }
                        if (curObj?.action) {
                            newObj.action = curObj?.action
                        }
                        if (curObj?._id) {
                            newObj.identifier = curObj?._id
                        }
                        if (curObj?.action && !curObj?.field) {
                            let children = curObj?.children;
                            let fArr = []
                            children.map((it) => {
                                if (selectedOptionIds.includes(it?._id)) {
                                    fArr.push(it?.title)
                                }
                            })
                            newObj.fields = fArr
                        }
                        if (!curObj?.field) {
                            newAccessArr.push(newObj)
                        }
                    }
                })

                const reqObj = {
                    newAccess: newAccessArr,
                    roleName,
                    team: team?._id,
                    createdBy: user?.profile
                }


                await createAccessRole(reqObj)
                    .then((data) => {
                        if (addNewFunction) {
                            addNewFunction(data, false)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            setLoader(false)
            setAddRoleDrawer(false)
        }
    }

    return (
        <NormalDrawer
            openDrawer={addRoleDrawer}
            setOpenDrawer={setAddRoleDrawer}
            anchor={"right"}
            width={"40vw"}
            title={roleDrawerView === "Add" ? "Add Role" : roleDrawerView === "Update" ? "Edit Role" : "Role Details"}
            content={<div className={classes.mainBodyCont} >
                <div className={classes.bodyCont} >
                    <p className={classes.lableSty} >Role</p>
                    {roleDrawerView === "Add" || roleDrawerView === "Update" ? (
                        <TextField
                            value={roleName}
                            onChange={(e) => {
                                setRoleName(e.target.value)
                            }}
                            placeholder="Enter Role Name"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    ) : (
                        <p>{roleName}</p>
                    )}

                    <p className={classes.lableSty} >AccessRoles</p>
                    {(roleDrawerView === "Add" || roleDrawerView === "Update") && (
                        <div className={classes.mainTreeCont} >
                            <SelectTree
                                options={accessOptions}
                                selectedOptionIds={selectedOptionIds}
                                setSelectedOptionIds={setSelectedOptionIds}
                            />
                        </div>
                    )}
                    {selectedOptionIds && selectedOptionIds.length > 0 && (
                        <div className={roleDrawerView === "View" ? classes.selectedItemsContForView : classes.selectedItemsCont} >
                            {selectedOptionIds.map((lableId) => (
                                <Chip
                                    size="small"
                                    label={lableId}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className={classes.loader} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bottomCont} >
                    {roleDrawerView === "View" ? (<>
                        {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Delete-Role") ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                startIcon={<DeleteIcon />}
                                onClick={() => {

                                }}
                            >
                                Delete
                            </Button>
                        ) : (<div></div>)}

                        {checkAccess(team?.parent?.profile?._id, "basicSettings-Access-Update-Role") ? (
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => {
                                    setRoleDrawerView("Update")
                                }}
                            >
                                Edit
                            </Button>
                        ) : (<div></div>)}
                    </>) : (<>
                        <div></div>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={roleName && roleName.length > 2 ? false : true}
                            onClick={addNewRole}
                        >
                            Save
                        </Button>
                    </>)}
                </div>
            </div>}
        />
    );
};

export default RoleAddDrawer;