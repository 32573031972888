import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingBox = ({
  loading,
  children,
  loadingContainerSx = {},
  fullHeight = false,
}) => {
  return (
    <Box
      sx={{
        minHeight: fullHeight ? "60vh" : "auto",
      }}
    >
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "150px", ...loadingContainerSx }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>{children}</Box>
      )}
    </Box>
  );
};

export default LoadingBox;
