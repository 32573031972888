import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import AddIcon from "@material-ui/icons/Add";
import addPublicDoc from "../../Assets/addPublicDoc.svg";
import addTemplateDoc from "../../Assets/addTemplateDoc.svg";
import folderAdd from "../../Assets/folderAdd.svg";
import envolapAdd from "../../Assets/docuESign.svg";
import privateDocAdd from "../../Assets/envolapAdd.svg";
import CustomBtn from "../styled/CommonComponents/CustomBtn";

const useStyles = makeStyles((theme) => ({
  allCradCont: {
    width: "555px",
    display: "flex",
    paddingTop: "10px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  addCard: {
    width: "270px",
    height: "85px",
    display: "flex",
    padding: "3px 10px",
    alignItems: "center",
    border: "1px solid #d8d5d5",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    marginBottom: "15px",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "& img": {
      width: "75px",
      height: "auto",
      marginRight: "10px",
    },
    "& div": {
      padding: "5px 0px",
      "& p": {
        fontSize: "12px",
      },
    },
  },
}));

export default function AddNewDialog({ profileId, defaultOpen, setOuterOpen }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openAddNew, setOpenAddNew] = useState(false);

  const handleClose = () => {
    setOpenAddNew(false);
    if (setOuterOpen) {
      setOuterOpen(false);
    }
  };

  useEffect(() => {
    if (defaultOpen) {
      setOpenAddNew(true);
    }
  }, []);

  return (
    <>
      {!defaultOpen ? (
        <>
          {isMobile ? (
            <CustomBtn
              startPart={<AddIcon />}
              text={"Add"}
              onClick={() => {
                setOpenAddNew(true);
              }}
              style={{ marginRight: "10px" }}
            />
          ) : (
            <CustomBtn
              startPart={<AddIcon />}
              text={"Add New"}
              onClick={() => {
                setOpenAddNew(true);
              }}
              style={{ marginRight: "15px" }}
            />
          )}
        </>
      ) : null}

      <NormalDialog
        openDialog={openAddNew}
        handleCloseShare={handleClose}
        pageTitle={"Create New"}
        content={
          <div className={classes.allCradCont}>
            <div
              className={classes.addCard}
              onClick={() => {
                var path = "/doc/create/" + profileId + "/new";
                history.push(path);
              }}
            >
              <img src={privateDocAdd} />
              <div>
                <h3>Private</h3>
                <p>Create notes, upload files or attach a link.</p>
              </div>
            </div>
            <div
              className={classes.addCard}
              onClick={() => {
                var path = "/publicdoc/create/" + profileId + "/new";
                history.push(path);
              }}
            >
              <img src={addPublicDoc} />
              <div>
                <h3>Public</h3>
                <p>Create Public notes, upload files or attach a link.</p>
              </div>
            </div>
            <div
              className={classes.addCard}
              onClick={() => {
                var path = "/doc/envelope/" + profileId + "/new";
                history.push(path);
              }}
            >
              <img
                src={envolapAdd}
                style={{
                  width: "55px",
                  marginLeft: "10px",
                  marginRight: "15px",
                }}
              />
              <div>
                <h3>DocuSign</h3>
                <p>Add Documents & get them signed.</p>
              </div>
            </div>
            <div
              className={classes.addCard}
              onClick={() => {
                var path = "/doc/template/" + profileId + "/new";
                history.push(path);
              }}
            >
              <img src={addTemplateDoc} />
              <div>
                <h3>Template</h3>
                <p>Upload your document as templates.</p>
              </div>
            </div>
            <div
              className={classes.addCard}
              onClick={() => {
                var path = "/doc/folder/create/" + profileId + "/new";
                history.push(path);
              }}
            >
              <img src={folderAdd} />
              <div>
                <h3>Folder</h3>
                <p>
                  Organize docs in <b>Folders</b> and <b>share accross teams</b>
                  .
                </p>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
