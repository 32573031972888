import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { createCertificateApiCall, createJournalEntryandLine, createTx, createTxTemplate, getProfileByWallet } from './api';
import ChartAccountCreate from '../../financialStatements/chartOfAccounts/ChartAccount.Create';
import NormalDialog from '../../../styled/CommonComponents/NormalDialog';
import InvoiceSvg from "../../../../Assets/invoice.svg";
import emptyPage from "../../../../Assets/EmptyPageSVG.svg"
import { getProfileDataPopulate } from '../../../team/procurements/api.call';
import { createDefaultTemplateHook } from '../../../approval/approvalHelper';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  optionsCont: {
    width: "850px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  loaderCont: {
    width: "850px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      marginTop: "5px"
    }
  },
  emptyLibrarayCont: {
    width: "850px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "120px",
      height: "auto"
    },
    "& p": {
      width: "100%",
      textAlign: "center",
      marginTop: "5px"
    },
    "& a": {
      textDecoration: "none",
      fontSize: "14px",
      marginLeft: "5px",
      cursor: "pointer",
      color: "blue"
    }
  },
  paddingCont: {
    minWidth: "33.33%",
    width: "33.33%",
    padding: "5px",
  },
  singleOption: {
    width: "100%",
    padding: "5px",
    display: "flex",
    border: "1px solid #ececec",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
    cursor: "pointer",
    "& img": {
      width: "40px",
      height: "40px",
    },
    "& div": {
      width: "100%",
      paddingLeft: "10px"
    },
    "& h3": {
      fontSize: "15px",
      fontWeight: "600"
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400"
    },
    "&:hover": {
      transform: "scale(1.01)",
    },
  }
}));

export default function AddNewTxDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { walletId, btnComp } = props;
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [openChartAcc, setOpenChartAcc] = useState(false)
  const [profileData, setProfileData] = useState(null)
  const [createOptions, setCreateOptions] = useState([
    {
      _id: 5,
      svg: InvoiceSvg,
      title: "Chart Account",
      subTitle: "Organized list categorizing financial transactions for reporting and analysis.",
      onClick: () => {
        setOpenChartAcc(true)
        setOpen(false)
      }
    },
    {
      _id: 6,
      svg: InvoiceSvg,
      title: "Journal Entry",
      subTitle: "Recording financial transactions into the accounting system's general ledger.",
      onClick: createJournalEntry
    }
  ])

  const getProjectData = async (profileId) => {
    await getProfileDataPopulate({ profileId })
      .then((locProjectData) => {
        console.log(locProjectData);
        setProfileData(locProjectData)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (walletId) {
      getProfileByWallet({ walletId })
        .then((locProfileId) => {
          console.log(locProfileId);
          getProjectData(locProfileId)
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [walletId])

  async function onCreateTx(type, status, isAccountingNeed) {
    setLoader(true)
    let createData = {
      type,
      status,
      firstPartyWallet: walletId,
      firstParty: profileData?._id,
      user: user?._id,
      creatorUser: user?._id,
      creatorProfile: user?.profile,
      profile: profileData?._id,
      libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
      defaultTemplate: type === "Invoice" && type === "Bill" ? profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionApproval?._id : profileData?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?._id,
      isAccountingNeed
    }
    if (profileData?.parentModelName === "Project") {
      createData.projectParent = profileData?.parent?.ownerProfile?.parent;
      createData.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
      createData.project = profileData?.parent?._id;
    } else {
      createData.projectParent = profileData?.parent?._id;
      createData.projectParentModel = profileData?.parentModelName;
    }
    if (profileData?.parentModelName === "Organization") {
      createData.orgId = profileData?.parent?._id
    } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
      createData.orgId = profileData?.parent?.ownerProfile?.parent
    }
    const data = await createTx(createData)
    setLoader(false)
    if (data?._id) {
      setOpen(false);
      let path = "";
      if (type === "Invoice") {
        path = "/finance/" + walletId + "/invoice/edit/" + data?._id;
      } else if (type === "Bill") {
        path = "/finance/" + walletId + "/bill/edit/" + data?._id;
      } else if (type === "DebitNote") {
        path = "/finance/" + walletId + "/debit-note/edit/" + data?._id;
      } else if (type === "CreditNote") {
        path = "/finance/" + walletId + "/credit-note/edit/" + data?._id;
      }
      history.push(path);
    }
  };

  async function onTmCreate(type, status) {
    setLoader(true)
    let createData = {
      type,
      status,
      firstPartyWallet: walletId,
      firstParty: profileData?._id,
      user: user?._id,
      creatorProfile: user?.profile,
      profile: profileData?._id,
      libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
      defaultTemplate: profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionTemplateApproval?._id
    }
    if (profileData?.parentModelName === "Project") {
      createData.projectParent = profileData?.parent?.ownerProfile?.parent;
      createData.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
      createData.project = profileData?.parent?._id;
    } else {
      createData.projectParent = profileData?.parent?._id;
      createData.projectParentModel = profileData?.parentModelName;
    }
    if (profileData?.parentModelName === "Organization") {
      createData.orgId = profileData?.parent?._id
    } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
      createData.orgId = profileData?.parent?.ownerProfile?.parent
    }
    const data = await createTxTemplate(createData);
    setLoader(false)
    if (data?._id) {
      setOpen(false);
      let path = "";
      if (type === "Invoice") {
        path = "/finance/" + walletId + "/invoice-generator/edit/" + data?._id;
      } else if (type === "Bill") {
        path = "/finance/" + walletId + "/bill-generator/edit/" + data?._id;
      }
      history.push(path);
    }
  };

  async function createJournalEntry() {
    setLoader(true)
    dispatch({
      type: 'AddWallet',
      payload: {
        financeLoading: true
      }
    })
    await createJournalEntryandLine({
      walletId: walletId,
      user: user._id
    })
      .then((data) => {
        setLoader(false)
        setOpen(false);
        if (data.savedJournal._id) {
          const path = '/admin/' + walletId + '/journalentry/' + data.savedJournal._id + `/edit`;
          history.push(path);
        }
      })
      .catch((err) => {
        setLoader(false)
        console.log(err)
      })
  };

  async function createCertificate() {
    setLoader(true)
    let createData = {
      user: user?._id,
      walletId: walletId,
      profile: profileData?._id,
      userProfile: user?.profile,
      libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
      defaultTemplate: profileData?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?._id,
    }
    if (profileData?.parentModelName === "Project") {
      createData.projectParent = profileData?.parent?.ownerProfile?.parent;
      createData.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
      createData.project = profileData?.parent?._id;
    } else {
      createData.projectParent = profileData?.parent?._id;
      createData.projectParentModel = profileData?.parentModelName;
    }
    if (profileData?.parentModelName === "Organization") {
      createData.orgId = profileData?.parent?._id
    } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
      createData.orgId = profileData?.parent?.ownerProfile?.parent
    }
    await createCertificateApiCall(createData)
      .then((data) => {
        setLoader(false)
        setOpen(false)
        history.push(`/finance/${walletId}/workorder-certificate/edit/${data?._id}`);
      })
      .catch((err) => {
        setLoader(false)
        console.log(err)
      })
  }

  useEffect(() => {
    let arr = [...createOptions]
    if (profileData?._id) {
      if (profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionApproval?.stages && profileData.parent.wallet.defaultLibrary.defaultTransactionApproval.stages.length > 0) {
        const initialStage = profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionApproval?.stages[0];
        const approversArr = initialStage.approvers.map((approver) => approver?.profile)
        if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
          arr.push({
            _id: 1,
            svg: InvoiceSvg,
            title: "Invoice Transaction",
            subTitle: "Recording sale or purchase transactions in the accounting system for billing purposes.",
            onClick: () => {
              onCreateTx('Invoice', 'Draft', true);
            }
          })
          arr.push({
            _id: 2,
            svg: InvoiceSvg,
            title: "Bill Transaction",
            subTitle: "Logging expenses incurred for goods or services received in accounting records.",
            onClick: () => {
              onCreateTx('Bill', 'Draft', true);
            }
          })
          arr.push({
            _id: 8,
            svg: InvoiceSvg,
            title: "Standalone Debit Note",
            subTitle: "Independent document decreasing accounts payable without associated invoice.",
            onClick: () => {
              onCreateTx('DebitNote', 'Draft', true);
            }
          })
          arr.push({
            _id: 10,
            svg: InvoiceSvg,
            title: "Standalone Credit Note",
            subTitle: "Independent document reducing accounts receivable without associated invoice.",
            onClick: () => {
              onCreateTx('CreditNote', 'Draft', true);
            }
          })
        }
      }
      if (profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionTemplateApproval?.stages && profileData.parent.wallet.defaultLibrary.defaultTransactionTemplateApproval.stages.length > 0) {
        const initialStage = profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionTemplateApproval?.stages[0];
        const approversArr = initialStage.approvers.map((approver) => approver?.profile)
        if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
          arr.push({
            _id: 3,
            svg: InvoiceSvg,
            title: "Invoice Generator",
            subTitle: "Automated tool for creating invoices detailing products or services provided.",
            onClick: () => { onTmCreate('Invoice', 'Draft'); }
          })
          arr.push({
            _id: 4,
            svg: InvoiceSvg,
            title: "Bill Generator",
            subTitle: "Automated tool to generate bills outlining expenses accrued for goods or services.",
            onClick: () => { onTmCreate('Bill', 'Draft'); }
          })
        }
      }
      if (profileData?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?.stages && profileData.parent.wallet.defaultLibrary.defaultFinancialCertificateApproval.stages.length > 0) {
        const initialStage = profileData?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?.stages[0];
        const approversArr = initialStage.approvers.map((approver) => approver?.profile)
        if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
          arr.push({
            _id: 11,
            svg: InvoiceSvg,
            title: "Workorder Certificates",
            subTitle: "Documentation certifying completion or status of work orders in projects.",
            onClick: () => {
              createCertificate();
            }
          })
        }
      }
      if (profileData?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?.stages && profileData.parent.wallet.defaultLibrary.defaultDebitCreditNoteApproval.stages.length > 0) {
        const initialStage = profileData?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?.stages[0];
        const approversArr = initialStage.approvers.map((approver) => approver?.profile)
        if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
          arr.push({
            _id: 7,
            svg: InvoiceSvg,
            title: "Debit Note",
            subTitle: "Document issued to decrease accounts payable due to returns or corrections.",
            onClick: () => {
              onCreateTx('DebitNote', 'Draft', false);
            }
          })
          arr.push({
            _id: 9,
            svg: InvoiceSvg,
            title: "Credit Note",
            subTitle: "Document issued to reduce accounts receivable due to refunds or adjustments.",
            onClick: () => {
              onCreateTx('CreditNote', 'Draft', false);
            }
          })
        }
      }
      arr.sort((a, b) => a._id - b._id);
      setCreateOptions(arr)
    }
  }, [profileData])

  const createDefaultsTemplates = async () => {
    setLoader(true)
    const entity = profileData?.parent?._id;
    const entityModelName = profileData?.parentModelName;
    const entityParent = profileData?.parent?.ownerProfile?.parent;
    const entityParentModelName = profileData?.parent?.ownerProfile?.parentModelName;
    await createDefaultTemplateHook({
      profileId: profileData?._id,
      entity,
      entityModelName,
      entityParent,
      userId: user?._id,
      entityParentModelName,
      ownerProfileId: profileData?.parent?.ownerProfile?._id,
      types: ["Transaction", "Transaction Template", "Financial Certificate", "Debit/Credit Note"],
      libraryId: profileData?.parent?.wallet?.defaultLibrary?._id
    })
      .then(async (data) => {
        console.log(data)
        await getProjectData(profileData?._id);
        setLoader(false)
      })
      .catch(async (error) => {
        console.log(error)
        await getProjectData(profileData?._id);
        setLoader(false)
      })
  }

  return (
    <>
      <span onClick={() => { setOpen(!open) }} >
        {btnComp}
      </span>
      <NormalDialog
        openDialog={open}
        handleCloseShare={() => { setOpen(!open) }}
        pageTitle={"Add New"}
        content={<>
          {!loader ? (<>
            {profileData?.parent?.wallet?.defaultLibrary?._id ? (<>
              {(
                !profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionApproval ||
                !profileData?.parent?.wallet?.defaultLibrary?.defaultTransactionTemplateApproval ||
                !profileData?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval
              ) ? (
                <div className={classes.emptyLibrarayCont} >
                  <img src={emptyPage} />
                  <p>
                    Template is missing in the current directory or no template is selected as default.<br />
                    Click <a onClick={createDefaultsTemplates} >here</a> to create default template(s)
                  </p>
                </div>
              ) : (
                <div className={classes.optionsCont} >
                  {createOptions.map((optionData) => (
                    <div className={classes.paddingCont} >
                      <div
                        className={classes.singleOption}
                        onClick={optionData.onClick}
                      >
                        <img src={optionData.svg} />
                        <div>
                          <h3>{optionData?.title}</h3>
                          <p>{optionData?.subTitle}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>) : (
              <div className={classes.emptyLibrarayCont} >
                <img src={emptyPage} />
                <p>
                  No Library exists in the current directory or any library still didn't selected as default library.<br />
                  Click <a
                    onClick={() => {
                      history.push(`/offerings-categories/management/${profileData?._id}`)
                    }}
                  >here</a> to create and manage your library(s), category(s), product(s) and service(s)
                </p>
              </div>
            )}
          </>) : (<div className={classes.loaderCont} >
            <CircularProgress />
            <p>Please wait!!!, Loading...</p>
          </div>)}
        </>}
      />
      <ChartAccountCreate
        walletId={walletId}
        openDialog={openChartAcc}
        setOpenChart={setOpenChartAcc}
      />
    </>
  );
}