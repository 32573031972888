import { Divider, ImageList, Paper } from "@material-ui/core";
import { alpha } from "@mui/material/styles";
import {
  Add,
  CallMade,
  Check,
  KeyboardArrowDown,
  TvOffOutlined,
} from "@material-ui/icons";
import { AirOutlined, Close, WashOutlined } from "@mui/icons-material";
import HouseIcon from "@mui/icons-material/House";
import AddIcon from "@mui/icons-material/Add";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined";
import HvacOutlinedIcon from "@mui/icons-material/HvacOutlined";
import KitchenOutlinedIcon from "@mui/icons-material/KitchenOutlined";
import YardOutlinedIcon from "@mui/icons-material/YardOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import PoolOutlinedIcon from "@mui/icons-material/PoolOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import DepartureBoardOutlinedIcon from "@mui/icons-material/DepartureBoardOutlined";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Table,
  TableContainer,
  TableCell,
  Typography,
  TableBody,
  styled,
  Menu,
  MenuItem,
} from "@mui/material";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect, useState } from "react";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import CreateProjectBlockDrawer from "./CreateProjectBlockDrawer";
import Api from "../../../../helpers/Api";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Chip from "../../../styled/generic/Chip";
import { BsJustify } from "react-icons/bs";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";

const SelectProjectBlockDrawer = ({
  open,
  setOpen,
  projectId,
  setProjectBlocks,
  projectBlocks,
  allRentalUnits,
  setRentalUnitCount,
}) => {
  const dispatch = useDispatch();

  const [showCreateProjectBlockDrawer, setShowCreateProjectBlockDrawer] =
    useState(false);

  // const [projectBlocks, setProjectBlocks] = useState([]);
  let [collapseDict, setCollapseDict] = useState(null);
  let [blocks, setBlocks] = useState([]);
  let [units, setUnits] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [blockUnits, setBlockUnits] = useState({});
  const [openBlockDrawer, setOpenBlockDrawer] = useState(false);
  const [clickBlockDetail, setClickBlockDetail] = useState({});
  const [clickUnuit, setClickUnit] = useState({});
  const [openUnitDrawer, setOpenUnitDrawer] = useState(false);
  const [unitClickType, setUnitClickType] = useState("about");
  const [isLodaingUnit, setIsLoadingUnit] = useState("");
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FBFBFC",
      color: "#2D76E0",
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
      cursor: "pointer",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {},

    "&:last-child td, &:last-child th": {},
  }));

  const getClickUnit = async (unitId) => {
    try {
      setIsLoadingUnit(unitId);
      const { data } = await Api.post("/property/getUnitsByUnitId", {
        unitId,
      });
      console.log("alllahhhhh", data);
      if (data) {
        setClickUnit(data);
        setOpenUnitDrawer(true);
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to Create Project Block",
        },
      });
    } finally {
      setIsLoadingUnit("");
    }
  };

  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProjectBlocks = async () => {
    try {
      const { data } = await Api.post("/project/unit/getUnitsByProject", {
        projectId: projectId,
      });

      let resBlocks = data?.blocks || [];
      let resUnits = data?.units || [];
      let blockUnits = _.groupBy(resUnits, "projectBlock");
      setBlocks(resBlocks);
      setUnits(resUnits);
      setBlockUnits(blockUnits);
      // console.log("blocks ", resBlocks);
      // console.log("units ", resUnits);
      // console.log("blockUnits ", blockUnits);
      let tempDict = {};

      setCollapseDict(tempDict);
      //setProjectBlocks(blocks);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: { success: false, message: "Unable to fetch blocks" },
      });
    }
  };
  //console.log("kolllllllllllllllllllll", blocks, units);

  useEffect(() => {
    getProjectBlocks();
  }, [open]);

  const [selectedUnits, setSelectedUnits] = useState([]);

  const buildUnitCode = (unit) => {
    let code = ["Unit "];

    code.push(String(unit.floor));

    if (unit?.floorType === "2BHK") {
      code.push("A");
    } else if (unit?.floorType === "3BHK") {
      code.push("B");
    } else if (unit?.floorType === "4BHK") {
      code.push("C");
    } else {
      code.push("D");
    }

    code.push(String(unit?.floorUnits));
    let unitCode = code.join("");
    return unitCode;
  };

  const convertDate = (date) => {
    const dateUTC = new Date(date);
    const dateIST = new Date(dateUTC.getTime() + 5.5 * 60 * 60 * 1000);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Kolkata",
    };
    return dateIST.toLocaleString("en-IN", options);
  };

  const styles = {
    card: {
      border: "1px solid #0000001A",
      width: "18rem",
      height: "14rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.7rem",
      cursor: "pointer",
    },
    blockContainer: {
      width: "90vw",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingBottom: "2rem",
    },
    blockHeader: {
      width: "95%",
      display: "flex",
      justifyContent: "space-between",

      height: "4rem",
      alignItems: "center",
    },
    blockName: {
      fontSize: "1.5rem",
      fontWight: "bold",
    },
    blockDetailContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    blockDetailBox: {
      border: "1px solid #F3F3F3",
      width: "95%",
      display: "flex",
      height: "14rem",
      justifyContent: "start",
      alignItems: "center",
      borderRadius: "1rem",
      gap: "1.5rem",
    },
    blockImg: {
      height: "12rem",
      width: "18rem",
      borderRadius: "0.7rem",
    },
    blockInfoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      height: "100%",
      fontSize: "1rem",
    },
    blockOptIcon: {
      position: "absolute",
      right: "3rem",
      top: "0.8rem",
    },
    unitDetailBox: {
      border: "1px solid #F3F3F3",
      width: "95%",
      display: "flex",

      alignItems: "center",
      borderRadius: "1rem",
      gap: "1.5rem",
    },
    unitInfoContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "4rem",
    },
    unitName: {
      fontSize: "1.5rem",
      fontWight: "bold",
      paddingBottom: "1rem",
      display: "nline-block",
    },
    unitInfoBox: {
      width: "95%",
    },
    unitContainer: {
      width: "90vw",
      display: "flex",
      paddingBottom: "2rem",
      justifyContent: "center",
    },
    unitSection: {
      width: "95%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "2rem",
    },
    unitHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "4rem",
    },
    unitHeaderText: {
      fontSize: "1.5rem",
      fontWight: "bold",
    },
    unitContainer1: {
      width: "100%",
      border: "1px solid #EAEAEA",
      height: "27rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    unitContainer1Box: {
      width: "97%",
      height: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    unitImgesSec: {
      width: "100%",
      display: "flex",
      gap: "1rem",
    },
    uniContainer1BoxUp: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flex: {
      display: "flex",
      alignItems: "center",
      alignItems: "center",
      gap: "1rem",
    },
    fontMid: {
      fontSize: "1.4rem",
      fontWight: "bold",
    },
    flexBetween: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    settingBtn: {
      color: "#2D76E0",
      border: "1px solid #0000001A",
      display: "flex",
      alignItems: "center",
      fontSize: "1rem",
      fontWight: 800,
      padding: "0.4rem 0.8rem",
      gap: "0.5rem",
    },
    unitContainer2: {
      width: "100%",
      border: "1px solid #EAEAEA",
      height: "35rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    unitContainer2Box: {
      width: "97%",
      height: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    flexStart: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      gap: "1.5rem",
    },
    unitContainer3: {
      width: "100%",
      border: "1px solid #EAEAEA",
      height: "20rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    unitContainer3Box: {
      width: "97%",
      height: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    unitContainer4: {
      width: "100%",
      border: "1px solid #EAEAEA",
      height: "25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    unitContainer4Box: {
      width: "97%",
      height: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  };
  //console.log(allRentalUnits[`${clickBlockDetail?._id}`]);

  return (
    <>
      <DrawerContainer title="Select Units" open={open} setOpen={setOpen}>
        <Drawer anchor="right" open={openUnitDrawer}>
          <div style={{ ...styles.unitContainer }}>
            <div style={{ ...styles.unitSection }}>
              <div style={{ ...styles.unitHeader }}>
                <span style={{ ...styles.unitHeaderText }}>Unit details</span>
                <span>
                  <IconButton onClick={() => setOpenUnitDrawer(false)}>
                    <Close />
                  </IconButton>
                </span>
              </div>
              <div style={{ ...styles.unitContainer1 }}>
                <div style={{ ...styles.unitContainer1Box }}>
                  <div style={{ ...styles.uniContainer1BoxUp }}>
                    <span style={{ ...styles.fontMid }}>
                      {clickUnuit?.name}
                    </span>
                    <div style={{ ...styles.flex }}>
                      <div style={{ ...styles.flex }}>
                        <span
                          style={{
                            background: "#03CA1733",
                            padding: "0.5rem 1rem",
                            display: "inline-block",
                            borderRadius: "0.5rem",
                            fontSize: "0.8rem",
                          }}
                        >
                          Construction : Finished
                        </span>
                        <span
                          style={{
                            background: "#E48900",
                            padding: "0.5rem 1rem",
                            display: "inline-block",
                            borderRadius: "0.5rem",
                            fontSize: "0.8rem",
                          }}
                        >
                          Listing : Unlisted
                        </span>
                        <span
                          style={{
                            background: "#2D76E0",
                            padding: "0.5rem 1rem",
                            display: "inline-block",
                            borderRadius: "0.5rem",
                            fontSize: "0.8rem",
                          }}
                        >
                          Rent : Vacant
                        </span>
                      </div>
                      <div>
                        <IconButton onClick={() => setOpenUnitDrawer(false)}>
                          <MoreVertIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div style={{ ...styles.unitImgesSec }}>
                    {[
                      {
                        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
                        title: "Bed",
                      },
                      {
                        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
                        title: "Kitchen",
                      },
                      {
                        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
                        title: "Sink",
                      },
                      {
                        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
                        title: "Books",
                      },
                    ].map(({ img }) => (
                      <img
                        style={{ width: "10rem", height: "18rem", flex: 1 }}
                        src={img}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ ...styles.flexBetween }}>
                <div style={{ ...styles.flex }}>
                  <span
                    onClick={() => setUnitClickType("about")}
                    style={{
                      fontSize: "1.2rem",
                      cursor: "pointer",
                      color: unitClickType === "about" && "#0671E0",
                      fontWeight: unitClickType === "about" && 800,
                    }}
                  >
                    About
                  </span>
                  <span
                    onClick={() => setUnitClickType("bookings")}
                    style={{
                      fontSize: "1.2rem",
                      cursor: "pointer",
                      color: unitClickType === "bookings" && "#0671E0",
                      fontWeight: unitClickType === "bookings" && 800,
                    }}
                  >
                    Bookings
                  </span>
                  <span
                    onClick={() => setUnitClickType("transaction")}
                    style={{
                      fontSize: "1.2rem",
                      cursor: "pointer",
                      color: unitClickType === "transaction" && "#0671E0",
                      fontWeight: unitClickType === "transaction" && 800,
                    }}
                  >
                    {" "}
                    Transaction
                  </span>
                  <span
                    onClick={() => setUnitClickType("tickets")}
                    style={{
                      fontSize: "1.2rem",
                      color: unitClickType === "tickets" && "#0671E0",
                      fontWeight: unitClickType === "tickets" && 800,
                    }}
                  >
                    {" "}
                    Tickets
                  </span>
                </div>
                <button style={{ ...styles.settingBtn }}>
                  <span style={{ fontSize: "0.8rem" }}>
                    <SettingsIcon />{" "}
                  </span>
                  Settings
                </button>
              </div>
              {unitClickType === "about" && (
                <>
                  <div style={{ ...styles.unitContainer2 }}>
                    <div style={{ ...styles.unitContainer2Box }}>
                      <div style={{ ...styles.flexBetween }}>
                        <span style={{ ...styles.fontMid }}>Unit Details</span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.2rem",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.7rem", cursor: "pointer" }}
                          >
                            <AddIcon />
                          </span>
                          <span
                            style={{ fontSize: "1.5rem", cursor: "pointer" }}
                          >
                            <ModeOutlinedIcon />
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(4,1fr)",
                          gap: "1rem",
                          fontSize: "1rem",
                        }}
                      >
                        <span>
                          Unit Type:{" "}
                          {clickUnuit?.unitType
                            ? clickUnuit?.unitType
                            : "Undifined"}
                        </span>
                        <span>Block Area: 1200 sq.ft</span>
                        <span>Age: 1 Year</span>
                        <span>Furnishing: Fully Furnished</span>
                        <span> Floors: Ground Floor</span>
                        <span>Bathroom: 2 Bathroom</span>
                        <span>Balcony: 2 Balcony</span>
                        <span>Additional room: Study Room</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <span style={{ ...styles.fontMid }}>Interior</span>
                        <div style={{ ...styles.flexStart, fontSize: "1rem" }}>
                          <span>Flooring: Tile flooring</span>
                          <span>Additional Room: Puja Room</span>
                          <span>Celling Height: 123 Sq.ft</span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <span style={{ ...styles.fontMid }}>Exterior</span>
                        <div style={{ ...styles.flexStart, fontSize: "1rem" }}>
                          <span>View: City sky </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <span style={{ ...styles.fontMid }}>Description</span>
                        <div style={{ fontSize: "1rem" }}>
                          This spacious 4 bhk, 2bhk, 5 bhk Apartment is
                          available for sale and is located in the heart of
                          Patia.It is an unfurnished property. It is a
                          ready-to-move-in property. It is made in way to
                          provide a comfortable living for the residents. It is
                          a ready-to-move-in property. It is made in way to
                          provide a comfortable living for the residents. This
                          spacious 4 bhk villa is available for sale and is
                          located in the heart of Patia.It is an unfurnished
                          property. It is a ready-to-move-in property. It is
                          made in way to provide a comfortable living for the
                          residents. It is a ready-to-move-in property. It is
                          made in way to provide a comfortable living for the
                          residents.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ ...styles.unitContainer3 }}>
                    <div style={{ ...styles.unitContainer3Box }}>
                      <div style={{ ...styles.flexBetween }}>
                        <span style={{ ...styles.fontMid }}>Amenities</span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.2rem",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.7rem", cursor: "pointer" }}
                          >
                            <AddIcon />
                          </span>
                          <span
                            style={{ fontSize: "1.5rem", cursor: "pointer" }}
                          >
                            <ModeOutlinedIcon />
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          gap: "6rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            <YardOutlinedIcon style={{ fontSize: "2rem" }} />
                            Garden view
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <WifiOutlinedIcon
                              style={{ fontSize: "2rem" }}
                            />{" "}
                            Wifi
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <WashOutlined /> Free washer - in building
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <AirOutlined style={{ fontSize: "2rem" }} /> Air
                            conditioning
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <KitchenOutlinedIcon style={{ fontSize: "2rem" }} />
                            Refrigerator
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <span>
                            {" "}
                            <TvOffOutlined /> TV
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <PetsOutlinedIcon
                              style={{ fontSize: "2rem" }}
                            />{" "}
                            Pets allowed
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <PoolOutlinedIcon style={{ fontSize: "2rem" }} />
                            Private Pool
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            <ideocamOutlinedIcon style={{ fontSize: "2rem" }} />{" "}
                            Security cameras on property
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.8rem",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <DepartureBoardOutlinedIcon
                              style={{ fontSize: "2rem" }}
                            />{" "}
                            Free parking on premises
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ ...styles.unitContainer4 }}>
                    <div style={{ ...styles.unitContainer4Box }}>
                      <div style={{ ...styles.flexBetween }}>
                        <span style={{ ...styles.fontMid }}>Price Details</span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.2rem",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.7rem", cursor: "pointer" }}
                          >
                            <AddIcon />
                          </span>
                          <span
                            style={{ fontSize: "1.5rem", cursor: "pointer" }}
                          >
                            <ModeOutlinedIcon />
                          </span>
                        </div>
                      </div>
                      <span style={{ fontSize: "1rem" }}>Total : ₹30L.</span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <span style={{ fontSize: "1.3rem" }}>
                          Additional costs
                        </span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            fontSize: "1rem",
                          }}
                        >
                          <span> Lorium Ipsm: 6000</span>
                          <span> Lorium Ipsm: 6000</span>
                          <span> Lorium Ipsm: 6000</span>
                          <span> Lorium Ipsm: 6000</span>
                          <span> Lorium Ipsm: 6000</span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          gap: "0.5rem",
                        }}
                      >
                        <span style={{ fontSize: "1.3rem" }}>
                          Payment Terms
                        </span>
                        <div style={{ fontSize: "1rem" }}>
                          <span style={{ display: "block" }}>
                            {" "}
                            1. his spacious 4 bhk villa is available for sale
                            and is located in the heart of Patia.It is an
                            unfurnished property. It is a ready-to-move-in
                            property.
                          </span>
                          <span style={{ display: "block" }}>
                            {" "}
                            2. his spacious 4 bhk villa is available for sale
                            and is located in the heart of Patia.It is an
                            unfurnished property. It is a ready-to-move-in
                            property.
                          </span>
                          <span style={{ display: "block" }}>
                            {" "}
                            3. his spacious 4 bhk villa is available for sale
                            and is located in the heart of Patia.It is an
                            unfurnished property. It is a ready-to-move-in
                            property.
                          </span>
                          <span style={{ display: "block" }}>
                            {" "}
                            4. his spacious 4 bhk villa is available for sale
                            and is located in the heart of Patia.It is an
                            unfurnished property. It is a ready-to-move-in
                            property.
                          </span>
                          <span style={{ display: "block" }}>
                            {" "}
                            5. his spacious 4 bhk villa is available for sale
                            and is located in the heart of Patia.It is an
                            unfurnished property. It is a ready-to-move-in
                            property.
                          </span>
                          <span style={{ display: "block" }}>
                            {" "}
                            6. his spacious 4 bhk villa is available for sale
                            and is located in the heart of Patia.It is an
                            unfurnished property. It is a ready-to-move-in
                            property.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Drawer>

        <Drawer anchor="right" sx={{ width: "80vw" }} open={openBlockDrawer}>
          <div style={{ ...styles.blockContainer }}>
            <div style={{ ...styles.blockHeader }}>
              <span style={{ ...styles.blockName }}>Block</span>
              <span>
                <IconButton onClick={() => setOpenBlockDrawer(false)}>
                  <Close />
                </IconButton>
              </span>
            </div>
            <div style={{ ...styles.blockDetailContainer }}>
              <span style={{ ...styles.blockOptIcon }}>
                <IconButton>
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open1 ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </Button>

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                  </Menu>
                </IconButton>
              </span>
              <div style={{ ...styles.blockDetailBox }}>
                <div style={{ paddingLeft: "1rem" }}>
                  <img
                    src={clickBlockDetail?.image}
                    alt={clickBlockDetail?.name}
                    style={{ ...styles.blockImg }}
                  />
                </div>
                <div style={{ ...styles.blockInfoContainer }}>
                  <span>{clickBlockDetail?.name}</span>
                  <span>
                    {clickBlockDetail?.unitCount}{" "}
                    {clickBlockDetail?.unitCount > 1 ? "Unit" : "Units"}
                  </span>
                  <span>
                    Created on {convertDate(clickBlockDetail?.createdAt)}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ ...styles.unitInfoContainer }}>
              <div style={{ ...styles.unitInfoBox }}>
                <div style={{ paddingBottom: "1rem" }}>
                  <span style={{ ...styles.unitName }}>Units</span>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>UNIT NAME</StyledTableCell>
                        <StyledTableCell align="center">
                          UNIT TYPE
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          CREADTED AT
                        </StyledTableCell>
                        <StyledTableCell align="center">VACANT</StyledTableCell>
                        <StyledTableCell align="center">ACTION</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allRentalUnits[`${clickBlockDetail?._id}`]?.map(
                        (unit) => (
                          <>
                            {isLodaingUnit === unit?._id ? (
                              <div
                                style={{
                                  height: "4rem",
                                  width: "85vw",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "1.1rem",
                                    color: "#0671E0",
                                  }}
                                >
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <StyledTableRow
                                onClick={() => getClickUnit(unit._id)}
                                key={unit._id}
                              >
                                <StyledTableCell component="th" scope="row">
                                  {unit?.name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {unit?.unitType ? unit.unitType : "Undifined"}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {convertDate(unit?.createdAt)?.split(",")[0]}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {unit?.vacant ? "Yes" : "No"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <IconButton>
                                    <MoreVertIcon />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                          </>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <div style={{ ...styles.unitDetailBox }}>
                {allRentalUnits[`${clickBlockDetail?._id}`]?.map(({ name }) => (
                  <span>{name}</span>
                ))}
              </div> */}
              </div>
            </div>
          </div>
        </Drawer>

        <Box>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gap: "1rem",
              justifyContent: "center",
              gridTemplateColumns: "repeat(auto-fit, minmax(18rem, 18rem))",
            }}
          >
            {projectBlocks?.map(({ name, unitCount, createdAt }, i) => {
              return (
                <div
                  onClick={() => {
                    setOpenBlockDrawer(true);
                    setClickBlockDetail({
                      ...projectBlocks[i],

                      image:
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShVtVCppmEQr5JQA_pd-oAto8oJuw6uUrP3g&s",
                    });
                  }}
                  style={{ ...styles.card }}
                >
                  <Box
                    sx={{
                      width: "92%",
                      height: "90%",
                      display: "flex",

                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShVtVCppmEQr5JQA_pd-oAto8oJuw6uUrP3g&s"
                      alt=""
                      style={{
                        borderRadius: "0.6rem",
                        height: "8rem",
                        width: "100%",
                        // paddingBottom: "0.8rem",
                      }}
                    />
                    <div
                      style={{
                        height: "3.5rem",

                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "1.1rem" }}
                        variant="h6"
                        component="body2"
                      >
                        {name}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: " #787486",
                          fontSize: "0.8rem",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "0.3rem",
                          }}
                        >
                          <HouseIcon /> {unitCount}{" "}
                          {unitCount > 1 ? "Unit" : "Units"}
                        </span>
                        <span style={{ fontSize: "0.7rem" }}>
                          Created: {convertDate(createdAt).split(",")[0]}
                        </span>
                      </Box>
                    </div>
                  </Box>
                </div>
              );
            })}
          </Box>

          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              startIcon={<Add />}
              onClick={() => setShowCreateProjectBlockDrawer(true)}
            >
              Block
            </Button>
          </Box>
        </Box>
      </DrawerContainer>

      <CreateProjectBlockDrawer
        open={showCreateProjectBlockDrawer}
        setOpen={setShowCreateProjectBlockDrawer}
        projectId={projectId}
        setProjectBlocks={setProjectBlocks}
        setRentalUnitCount={setRentalUnitCount}
        // onCreate={(newBlock) => {
        //   setProjectBlocks([...projectBlocks, newBlock]);
        // }}
      />
    </>
  );
};

export default SelectProjectBlockDrawer;
