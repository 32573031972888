import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Modal,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";

const VerifyEmailDialog = ({ businessEmail, setIsOpen, isOpen }) => {
  const regex = /@([a-zA-Z0-9.-]+)\./;
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSave = async () => {
    setLoading(true);
    const data = await Api.patch(`user/verifybusinessemail`, {
      password,
      businessEmailId: businessEmail._id,
      userId: businessEmail.userId,
    });
    if (data) {
      setIsOpen(false);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Email verified successfully",
        },
      });
    } else {
      setError(true);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while verifing Email",
        },
      });
    }
    setLoading(false);
    setPassword("");
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "white",
            padding: "20px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box>
            <Typography variant="h6" gutterBottom>
              Verify Email
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please enter your password to verify your email.{" "}
            </Typography>

            {businessEmail?.email?.match(regex)[1] === "gmail" && (
              <Typography variant="body1" gutterBottom>
                You can generate your password using the link below:{" "}
                <a
                  href="https://myaccount.google.com/apppasswords"
                  target="_blank"
                  style={{
                    color: "primary",
                    textDecoration: "underline",
                  }}
                >
                  click here
                </a>
              </Typography>
            )}
            <TextField
              error={error}
              helperText={error ? "Invalid password" : ""}
              fullWidth
              variant="outlined"
              type={showPassword ? "text" : "password"}
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={3} display="flex" justifyContent="flex-end" gap="15px">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default VerifyEmailDialog;
