import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { deleteTemplateDeeply, getApprovalTemplatesPagination, updateLibraryById } from './Api.call';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import AddIcon from '@material-ui/icons/Add';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import { Button, IconButton, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import DeleteConfirmBox from '../styled/CommonComponents/DeleteConfirmBox';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useDebounce } from 'react-use';
import ApprovalTempleteCreateDrawer from '../approval/Approval.Templete.Create.Drawer';
import { getApprovalTemplateById } from '../approval/api.call';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        "& table": {
            tableLayout: "auto"
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 10px"
        }
    },
    searchAndAddBtnCont: {
        width: "100%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        justifyContent: "space-between"
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 106px)",
        "& tr": {
            height: "45px"
        },
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #ececec",
    },
    singleOption: {
        padding: "4px 15px",
        fontSize: "15px",
        fontWeight: "500",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    iconBtn: {
        "& .MuiSvgIcon-root": {
            color: "black"
        }
    },
    deleteConfirm: {
        width: "350px",
        padding: "25px 0px 35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .MuiSvgIcon-root": {
            fontSize: "75px",
            color: "red"
        },
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            fontSize: "14px"
        }
    }
}));

export default function ApprovalTemplatesByLibrary({
    libraryId, setResponseStatusAndMsg, setSanckBarBool, profileData,
    afterCreateOrEditNewLibrary, currentLibrary
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [deleteConfirmBox, setDeleteConfirmBox] = useState(false)
    const [changeState, setChangeState] = useState(false)
    const [searchStr, setSearchStr] = useState("")
    const [createTemplateDrawer, setCreateTemplateDrawer] = useState(false)
    const [approvalTemplates, setApprovalTemplates] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [curEditDeleteTemplate, setCurEditDeleteTemplate] = useState(null)

    const getTamplatesPagination = async () => {
        setLoader(true)
        await getApprovalTemplatesPagination({
            libraryId,
            limit: pageLimit,
            curPage: curPage - 1,
            nameSearch: searchStr
        })
            .then((data) => {
                console.log(data)
                const dataArr = data?.data || [];
                const dataCount = data?.count || 0;
                setApprovalTemplates(dataArr)
                let locTotalPage = Math.ceil(dataCount / pageLimit);
                setTotalPages(locTotalPage)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    useDebounce(() => {
        getTamplatesPagination()
    }, 1000, [curPage, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [searchStr])

    const approvalTemplateAction = async (type, approvalTemplate) => {
        setCreateTemplateDrawer(false)
        setCurEditDeleteTemplate(null)
        setDeleteConfirmBox(false)
        switch (type) {
            case "create":
                setCreateTemplateDrawer(true);
                break;
            case "edit":
                setLoader(true)
                setCreateTemplateDrawer(true);
                await getApprovalTemplateById({ templateId: approvalTemplate?._id })
                    .then((data) => {
                        console.log(data)
                        setCurEditDeleteTemplate(data)
                        setLoader(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoader(false)
                    })
                break;
            case "delete":
                setCurEditDeleteTemplate(approvalTemplate);
                setDeleteConfirmBox(true);
                break
            case "setAsDefault":
                updateDefault(approvalTemplate?.type, approvalTemplate?._id)
                break
        }
    }

    const updateDefault = async (type, templateId) => {
        let updateObj = {
            _id: libraryId
        }
        switch (type) {
            case "HRM":
                updateObj.defaultHRMApproval = templateId;
                break;
            case "Material Request":
                updateObj.defaultMaterialRequestApproval = templateId;
                break;
            case "Bill Of Quantities":
                updateObj.defaultBillOfQuantitiesApproval = templateId;
                break;
            case "Purchase Order":
                updateObj.defaultPurchaseOrderApproval = templateId;
                break;
            case "Work Order":
                updateObj.defaultWorkOrderApproval = templateId;
                break;
            case "GRN":
                updateObj.defaultGRNApproval = templateId;
                break;
        }
        setLoader(true)
        await updateLibraryById(updateObj)
            .then((data) => {
                console.log(data)
                afterCreateOrEditNewLibrary(data, true)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    console.log(currentLibrary)

    const afterTemplateCreateOrEdit = async () => {
        if (curEditDeleteTemplate?._id) {
            setChangeState(!changeState)
            setResponseStatusAndMsg({
                status: "success",
                message: `Approval successfully modified`
            })
            setSanckBarBool(true)
        } else {
            setChangeState(!changeState)
            setCurPage(1)
            setResponseStatusAndMsg({
                status: "success",
                message: `Approval successfully added`
            })
            setSanckBarBool(true)
        }
        setCurEditDeleteTemplate(null)
    }

    const getLableAgainstType = (type) => {
        switch (type) {
            case "HRM": return "Human resource management";
            case "Material Request": return "Material Request";
            case "Bill Of Quantities": return "Bill Of Quantities";
            case "Purchase Order": return "Purchase Order";
            case "Work Order": return "Work Order";
            case "GRN": return "Goods Received Note";
            default: return "Unknown";
        }
    }

    const deleteTemplate = async () => {
        if (curEditDeleteTemplate?._id) {
            setLoader(true)
            let stageIds = []
            let approverIds = []
            let locStage = curEditDeleteTemplate?.stages || []
            locStage.map((s) => {
                stageIds.push(s?._id)
                s?.feApprovers && s.feApprovers.map((sp) => {
                    approverIds.push(sp?._id)
                })
            })

            await deleteTemplateDeeply({ _id: curEditDeleteTemplate?._id, stageIds, approverIds })
                .then((data) => {
                    setChangeState(!changeState)
                    setLoader(false)
                    setDeleteConfirmBox(false)
                    setResponseStatusAndMsg({
                        status: "success",
                        message: `${curEditDeleteTemplate?.title} successfully deleted`
                    })
                    setSanckBarBool(true)
                    setCurEditDeleteTemplate(null)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }


    return (
        <div className={classes.mainCont} >
            <div className={classes.searchAndAddBtnCont} >
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder={"Enter template name"}
                    autoComplete='off'
                    value={searchStr}
                    style={{ width: "350px" }}
                    onChange={(e) => {
                        setSearchStr(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setCurEditDeleteTemplate(null)
                        approvalTemplateAction("create", null)
                    }}
                >
                    New
                </Button>
            </div>
            <div style={{ width: "100%", height: "5px" }} >
                {loader && (<LinearProgress />)}
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" >
                                Title
                            </TableCell>
                            <TableCell align="left" >
                                Code
                            </TableCell>
                            <TableCell align="center" >
                                Module
                            </TableCell>
                            <TableCell align="center" >
                                Stages
                            </TableCell>
                            <TableCell align="left" >
                                Description
                            </TableCell>
                            <TableCell align="center" >
                                Add By
                            </TableCell>
                            <TableCell align="center" >
                                Last Modified By
                            </TableCell>
                            <TableCell align="center" >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {approvalTemplates && approvalTemplates.length > 0 && approvalTemplates.map((data) => {
                            return (
                                <TableRow>
                                    <TableCell align="left" >
                                        {data?.title}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {data?.code}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {getLableAgainstType(data?.type)}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {parseInt(data?.stages ? data?.stages?.length || 0 : 0)}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {data?.description}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.author?.displayName}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.lastModifiedBy?.parent?.displayName}
                                    </TableCell>
                                    <TableCell align="center" >
                                        <MyPopOver
                                            closeOnClick={true}
                                            appearContent={<span className={classes.iconBtn} >
                                                <IconButton>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </span>}
                                            showContent={<div style={{ padding: "5px 0px" }} >
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => { approvalTemplateAction("edit", data) }}
                                                >
                                                    Edit
                                                </div>
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => { approvalTemplateAction("delete", data) }}
                                                >
                                                    Delete
                                                </div>
                                                {((data?.type === "HRM" && currentLibrary?.defaultHRMApproval == data?._id) ||
                                                    (data?.type === "Material Request" && currentLibrary?.defaultMaterialRequestApproval == data?._id) ||
                                                    (data?.type === "Bill Of Quantities" && currentLibrary?.defaultBillOfQuantitiesApproval == data?._id) ||
                                                    (data?.type === "Purchase Order" && currentLibrary?.defaultPurchaseOrderApproval == data?._id) ||
                                                    (data?.type === "Work Order" && currentLibrary?.defaultWorkOrderApproval == data?._id) ||
                                                    (data?.type === "GRN" && currentLibrary?.defaultGRNApproval == data?._id)) ? null : (
                                                    <div
                                                        className={classes.singleOption}
                                                        onClick={() => { approvalTemplateAction("setAsDefault", data) }}
                                                    >
                                                        Set As Default
                                                    </div>
                                                )}
                                            </div>}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paginationCont} >
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value) }}
                />
            </div>

            {/* after create  disccount relation drawer */}
            <ApprovalTempleteCreateDrawer
                createTempleteDawer={createTemplateDrawer}
                setCreateTempleteDrawer={setCreateTemplateDrawer}
                afterTempleteCreateOrEdit={() => {
                    afterTemplateCreateOrEdit()
                }}
                curEditDeleteTemplete={curEditDeleteTemplate}
                profileData={profileData}
                loader={loader}
                setLoader={setLoader}
            />
            {/* after create  disccount relation drawer */}

            {/* confirm box to delete approval template */}
            <DeleteConfirmBox
                open={deleteConfirmBox}
                setOpen={setDeleteConfirmBox}
                loading={loader}
                pageTitle={`Delete ${curEditDeleteTemplate?.title}`}
                cantent={
                    <div className={classes.deleteConfirm} >
                        <ReportProblemIcon />
                        <h3>Are you sure?</h3>
                        <p>You won't be able to revert this!</p>
                    </div>
                }
                cancleConfirmDelete={() => {
                    setDeleteConfirmBox(false)
                }}
                confirmDelete={deleteTemplate}
            />
            {/* confirm box to delete approval template */}
        </div>
    );
}