import { Typography } from "@mui/material";
import React from "react";

const TabItem = ({
  active,
  children,
  disableUnderline,
  activeTabTextColor = "#2D76E0",
  inactiveTabTextColor = "#89939E",
  activeTabUnderlineColor = "#2D76E0",
  inactiveTabUnderlineColor = "transparent",
  fontWeight = "bold",
  tabStyleProps = {},
  ...props
}) => {
  return (
    <Typography
      {...props}
      style={{
        padding: "1rem 1rem 0.5rem 1rem",
        cursor: "pointer",
        borderBottom: disableUnderline
          ? "none"
          : active
          ? "3px solid " + activeTabUnderlineColor
          : "3px solid " + inactiveTabUnderlineColor,
        color: active ? activeTabTextColor : inactiveTabTextColor,
        whiteSpace: "nowrap",
        fontWeight: active ? fontWeight : "normal",
        ...tabStyleProps,
      }}
    >
      {children}
    </Typography>
  );
};

export default TabItem;
