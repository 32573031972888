const { default: Api } = require("../../helpers/Api");

export const getInitialEntities = async (obj) => {
  try {
    const res = await Api.post("get-initial/search", obj);
    const data = res || [];
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchUsers = async (obj) => {
  try {
    const res = await Api.post("/searchUsers", obj);
    const data = res || [];
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchEntities = async (obj) => {
  try {
    const res = await Api.post("searchProfile/for-all-entities", obj);
    const data = res || [];
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const addNewFinancialRelation = async (obj) => {
  try {
    const res = await Api.post("wallet/relation/find-or-add/populated", obj);
    const result = res?.data;
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPaginatedContact = async (obj) => {
  try {
    const res = await Api.post("wallet/relation/get/pagination", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};
